import clsx from "clsx"
import React from "react"

import { Duration } from "../Duration.component"

export type TimelineCursorProps = {
	variant: "active" | "default"
	currentTimeSeconds: number
	totalDurationSeconds: number
	containerRef: React.RefObject<HTMLDivElement>
	tooltipContent?: React.ReactNode
}

export function TimelineCursor({
	variant,
	currentTimeSeconds,
	totalDurationSeconds,
	containerRef,
	tooltipContent,
}: TimelineCursorProps) {
	const relativePositionPx =
		(containerRef.current?.getBoundingClientRect()?.width ?? 0) * (currentTimeSeconds / totalDurationSeconds)
	const [mouseY, setMouseY] = React.useState(0)
	React.useEffect(() => {
		const onMouseMove = (event: MouseEvent) => {
			setMouseY(event.clientY)
		}
		document.addEventListener("mousemove", onMouseMove)
		return () => {
			document.removeEventListener("mousemove", onMouseMove)
		}
	})

	const relativeMouseY = mouseY - (containerRef.current?.getBoundingClientRect()?.top ?? 0)
	const clampedMouseY = Math.min(
		containerRef.current?.getBoundingClientRect()?.height ?? 0,
		Math.max(0, relativeMouseY - 12),
	)

	return (
		<div
			className="absolute pointer-events-none z-20"
			style={{
				height: containerRef.current?.getBoundingClientRect()?.height,
			}}
		>
			<div
				className={clsx("absolute w-[2px] h-full z-10", variant === "active" ? "bg-blue-500" : "bg-gray-400")}
				style={{
					left: `${relativePositionPx}px`,
				}}
			/>
			{tooltipContent && (
				<div
					className="absolute overflow-hidden bg-gray-600 bg-opacity-60 text-white text-xs px-1.5 py-0.5 rounded-md z-20 pointer-events-none whitespace-nowrap"
					style={{
						left: `${relativePositionPx + 16}px`,
						top: `${clampedMouseY}px`,
					}}
				>
					{tooltipContent}
				</div>
			)}

			<Duration
				className={clsx(
					variant === "active" ? "bg-blue-500" : "bg-gray-400",
					"absolute text-white rounded-full px-1.5 text-sm py-1 top-[-12px] -translate-x-1/2 z-[22]",
				)}
				style={{
					left: `${relativePositionPx}px`,
				}}
				seconds={currentTimeSeconds}
			/>
		</div>
	)
}
