import {
	type CreateWorkspaceWithOwnerParams,
	type EnrichAccountResponse,
	enrichAccountResponseSchema,
	GetWorkspaceUsersTrainingStatisticsSchema,
	type IEnrichAccountParams,
	type IPlatformAdminGateway,
	type IRequestCallReprocessingParams,
	type ListAllWorkspacesRouteResponse,
	ListAllWorkspacesRouteResponseSchema,
	type WorkspaceUsersTrainingStatistics,
} from "../../application/gateways/platform-admin.gateway"

export class HttpPlatformAdminGateway implements IPlatformAdminGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async createWorkspaceWithOwner(params: CreateWorkspaceWithOwnerParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/admin/create-workspace-with-owner`
		await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
	}

	public async requestCallReprocessing({ callId }: IRequestCallReprocessingParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/admin/request-call-reprocessing/${callId}`
		await fetch(endpointUrl, {
			method: "POST",

			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
	}

	public async enrichAccount({
		email,
		lastName,
		firstName,
	}: IEnrichAccountParams): Promise<EnrichAccountResponse | null> {
		const endpointUrl = `${this.baseApiUrl}/admin/enrichments/account-from-email`
		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify({
				email,
				firstName,
				lastName,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
		const json = await res.json()
		if (json === null) {
			return null
		}
		return enrichAccountResponseSchema.parse(json)
	}

	public async listWorkspaces(): Promise<ListAllWorkspacesRouteResponse> {
		const endpointUrl = `${this.baseApiUrl}/admin/workspaces`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		return ListAllWorkspacesRouteResponseSchema.parse(await res.json())
	}

	public async getWorkspaceUsersTrainingStatistics(workspaceId: string): Promise<WorkspaceUsersTrainingStatistics> {
		const endpointUrl = `${this.baseApiUrl}/admin/workspace/${workspaceId}/get-workspace-users-training-statistics`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		return GetWorkspaceUsersTrainingStatisticsSchema.parse(await res.json())
	}
}
