import { TableCellsIcon, ViewColumnsIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"
import { Navigate, useParams } from "react-router-dom"

import { TrackingLink } from "../../components/design-system/TrackingLink.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { type DealPathParams, makeDealsPath } from "../../router/Router"
import { DealsBoardView } from "./pages/DealsBoardView.page"
import { DealsListView } from "./pages/DealsListView.page"

export type DealsDemoState = "upgrade-required" | "no-crm" | "fake-data"

export function DealsPage() {
	const { t } = useLanguage()
	const { workspace, user } = useAuthenticatedSession()
	const { viewType = "list" } = useParams<DealPathParams>()

	const [demoState] = React.useState<DealsDemoState | null>(() => {
		if (!workspace.isPlanFeatureEnabled("crm-integration")) {
			return "upgrade-required"
		}

		if (!workspace.crmIntegration) {
			return "no-crm"
		}

		const isDemoAccount = user.isRippletideDemoUser()
		if (isDemoAccount) {
			return "fake-data"
		}

		return null
	})

	if (!["board", "list"].includes(viewType)) {
		return <Navigate to="/deals/board" />
	}

	return (
		<Layout
			pageName={t("Deals")}
			className={clsx(viewType === "board" && "!px-2")}
			isFluid
			PageTitle={
				<div className="flex items-center justify-between">
					<div className={clsx("flex", viewType === "board" && "md:ml-6 sm:ml-4")}>
						<Layout.PageTitle title={t("Deals")} />
						<div className={clsx("border rounded-md border-gray-100 p-1 flex gap-0.5 ml-2")}>
							<TrackingLink
								to={makeDealsPath({ viewType: "list" })}
								eventName="Deals: change view"
								eventProperties={{ viewType: "list" }}
								title="Switch to list view"
							>
								<TableCellsIcon
									className={clsx(
										"h-7 w-7 text-gray-400",
										viewType === "list" && "border-2 rounded-md border-indigo-600",
									)}
								/>
							</TrackingLink>
							<TrackingLink
								eventName="Deals: change view"
								eventProperties={{ viewType: "board" }}
								title="Switch to board view"
								to={makeDealsPath({ viewType: "board" })}
							>
								<ViewColumnsIcon
									className={clsx(
										"h-7 w-7 text-gray-400",
										viewType === "board" && "border-2 rounded-md border-indigo-600",
									)}
								/>
							</TrackingLink>
						</div>
					</div>
				</div>
			}
		>
			{viewType === "board" && <DealsBoardView demoState={demoState ?? undefined} />}
			{viewType === "list" && <DealsListView />}
		</Layout>
	)
}
