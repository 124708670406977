import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import type {
	CalendarPlatform,
	DisconnectedCalendar,
	NotFoundCalendar,
} from "../../../core/application/gateways/calendar-events.gateway"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { Tooltip } from "../design-system/Tooltip.component"
import { TrackingButton } from "../design-system/TrackingButton.component"

function IconWrapper({ children }: { children: React.ReactNode }) {
	return <div className="bg-white p-1 w-8 h-8 rounded-full">{children}</div>
}

function MicrosoftOutlookSvg() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24" fill="none" className="w-6 h-6">
			<g clipPath="url(#outlook_svg__a)">
				<path
					d="M27.33 12.6c0-.2-.1-.39-.28-.5h-.02L17.8 6.8a1.26 1.26 0 0 0-1.38 0l-9.24 5.3h-.01a.57.57 0 0 0 .01 1l9.24 5.3.13.07a1.28 1.28 0 0 0 1.26-.08l9.24-5.29c.18-.1.3-.3.3-.5Z"
					fill="#0A2767"
				></path>
				<path
					d="M8.25 9.07h6.06v5.37H8.25zM26.09 3.6V1.14A1.13 1.13 0 0 0 24.97 0H9.23C8.6.01 8.1.52 8.11 1.14V3.6L17.4 6l8.68-2.4Z"
					fill="#0364B8"
				></path>
				<path d="M8.1 3.6h6.21V9h-6.2V3.6Z" fill="#0078D4"></path>
				<path d="M20.51 3.6h-6.2V9l6.2 5.4h5.58V9z" fill="#28A8EA"></path>
				<path d="M14.31 9h6.2v5.4h-6.2z" fill="#0078D4"></path>
				<path d="M14.31 14.4h6.2v5.4h-6.2z" fill="#0364B8"></path>
				<path d="M8.25 14.44h6.06v4.9H8.25z" fill="#14447D"></path>
				<path d="M20.51 14.4h5.58v5.4h-5.58z" fill="#0078D4"></path>
				<path
					d="M27.05 13.07h-.01L17.8 18.1l-.13.07c-.15.07-.32.11-.5.12l-.5-.28a1 1 0 0 1-.13-.07l-9.36-5.17-.31-.17v10.18c0 .68.58 1.22 1.28 1.22H26.1a1.3 1.3 0 0 0 .6-.18l.13-.07c.31-.23.5-.59.5-.97V12.6c0 .2-.1.37-.28.47"
					fill="url(#outlook_svg__b)"
				></path>
				<path
					opacity="0.5"
					d="M26.84 12.56v.62l-9.66 6.44-10-6.85h-.01l-.92-.54v-.45h.38l.8.44h.01l.07.05 9.43 5.2.36.2.1-.03 9.33-5.09.1.01Z"
					fill="#0A2767"
				></path>
				<path
					d="M27.05 13.07h-.01L17.8 18.1l-.13.07a1.33 1.33 0 0 1-1.26-.07l-9.24-5.02-.01-.01a.54.54 0 0 1-.3-.47v10.18c.01.68.59 1.22 1.29 1.22h17.9c.7 0 1.28-.54 1.28-1.22V12.6c0 .2-.1.37-.28.47"
					fill="#1490DF"
				></path>
				<path
					opacity="0.1"
					d="m17.93 18.03-.14.07a1.4 1.4 0 0 1-.6.2l3.5 4.02 6.14 1.43c.17-.12.3-.28.39-.47z"
					fill="#000"
				></path>
				<path
					opacity="0.05"
					d="m18.56 17.69-.77.41a1.4 1.4 0 0 1-.6.2l1.64 4.4 8 1.05c.32-.23.5-.59.5-.97v-.13z"
					fill="#000"
				></path>
				<path
					d="M8.16 24h17.9c.27 0 .54-.08.76-.24l-10.15-5.75a1 1 0 0 1-.13-.07l-9.36-5.17-.31-.17v10.14c0 .7.58 1.26 1.3 1.26Z"
					fill="#28A8EA"
				></path>
				<path
					opacity="0.1"
					d="M15.55 5.9v12.8c0 .45-.28.85-.71 1.02a1.1 1.1 0 0 1-.42.08H6.87V5.4H8.1v-.6h6.3c.63 0 1.14.5 1.14 1.1Z"
					fill="#000"
				></path>
				<path
					opacity="0.2"
					d="M14.93 6.5v12.8c0 .14-.03.29-.1.42-.17.4-.58.68-1.03.68H6.87v-15h6.93c.18 0 .35.04.51.13.38.18.62.56.62.97"
					fill="#000"
				></path>
				<path
					opacity="0.2"
					d="M14.93 6.5v11.6c0 .6-.5 1.1-1.13 1.1H6.87V5.4h6.93c.18 0 .35.04.51.13.38.18.62.56.62.97"
					fill="#000"
				></path>
				<path
					opacity="0.2"
					d="M14.31 6.5v11.6c0 .6-.5 1.1-1.13 1.1H6.87V5.4h6.3c.63 0 1.14.5 1.14 1.1"
					fill="#000"
				></path>
				<path
					d="M1.8 5.4h11.37c.63 0 1.14.5 1.14 1.1v11c0 .6-.5 1.1-1.14 1.1H1.8c-.62 0-1.13-.5-1.13-1.1v-11c0-.6.5-1.1 1.13-1.1"
					fill="url(#outlook_svg__c)"
				></path>
				<path
					d="M4.22 10.01c.28-.57.73-1.06 1.3-1.38a4.03 4.03 0 0 1 2.06-.5c.67-.02 1.33.14 1.91.47.55.31.98.77 1.26 1.33.3.6.46 1.27.45 1.95.01.7-.14 1.4-.46 2.04a3.3 3.3 0 0 1-1.3 1.37c-.6.33-1.29.5-1.98.48a3.83 3.83 0 0 1-1.95-.48c-.55-.31-1-.77-1.28-1.32-.31-.6-.46-1.26-.45-1.93-.01-.7.14-1.4.44-2.03m1.38 3.26a1.95 1.95 0 0 0 1.87 1.25c.44.01.87-.1 1.22-.35a2 2 0 0 0 .71-.91 3.76 3.76 0 0 0 .01-2.56c-.12-.38-.36-.7-.68-.96a1.93 1.93 0 0 0-1.2-.36 2.02 2.02 0 0 0-1.92 1.26 3.55 3.55 0 0 0 0 2.63Z"
					fill="#fff"
				></path>
				<path d="M20.51 3.6h5.58V9h-5.58z" fill="#50D9FF"></path>
			</g>
			<defs>
				<linearGradient
					id="outlook_svg__b"
					x1="17.1"
					y1="12.6"
					x2="17.1"
					y2="24"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#35B8F1"></stop>
					<stop offset="1" stopColor="#28A8EA"></stop>
				</linearGradient>
				<linearGradient
					id="outlook_svg__c"
					x1="3.04"
					y1="4.54"
					x2="11.51"
					y2="19.7"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1784D9"></stop>
					<stop offset="0.5" stopColor="#107AD5"></stop>
					<stop offset="1" stopColor="#0A63C9"></stop>
				</linearGradient>
				<clipPath id="outlook_svg__a">
					<path fill="#fff" d="M.67 0h26.67v24H.67z"></path>
				</clipPath>
			</defs>
		</svg>
	)
}

function GoogleCalendarSvg() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="w-6 h-6">
			<path d="m17.26 6.74-4.73-.53-5.8.53L6.22 12l.53 5.26 5.26.66 5.26-.66.53-5.4-.53-5.12Z"></path>
			<path
				d="M8.9 14.9a2 2 0 0 1-.82-1.16l.91-.38c.09.32.23.56.44.73.2.18.45.26.75.26s.55-.09.77-.27a.87.87 0 0 0 .32-.7.86.86 0 0 0-.34-.7 1.32 1.32 0 0 0-.85-.27h-.53v-.9h.47c.3 0 .54-.08.74-.24s.3-.37.3-.65a.72.72 0 0 0-.27-.58 1 1 0 0 0-.68-.22.9.9 0 0 0-.64.21c-.15.15-.27.32-.34.53l-.9-.38a2 2 0 0 1 .66-.9 1.9 1.9 0 0 1 1.23-.39c.37 0 .7.08 1 .22s.53.34.7.6c.16.25.24.53.24.85s-.07.6-.23.82-.35.4-.57.51v.05c.3.13.54.32.73.58.2.25.29.56.29.92s-.1.68-.27.96a2 2 0 0 1-.75.66 2.37 2.37 0 0 1-2.36-.16m5.6-4.53-1 .73-.5-.76 1.8-1.3h.69v6.12h-.99z"
				fill="#1A73E8"
			></path>
			<path d="M17.26 22 22 17.26l-2.37-1.05-2.37 1.05-1.05 2.37z" fill="#EA4335"></path>
			<path d="M5.68 19.63 6.74 22h10.52v-4.74H6.74z" fill="#34A853"></path>
			<path
				d="M3.58 2C2.7 2 2 2.7 2 3.58v13.68l2.37 1.06 2.37-1.06V6.74h10.52l1.06-2.37L17.26 2z"
				fill="#4285F4"
			></path>
			<path d="M2 17.26v3.16C2 21.3 2.7 22 3.58 22h3.16v-4.74z" fill="#188038"></path>
			<path d="M17.26 6.74v10.52H22V6.74l-2.37-1.06z" fill="#FBBC04"></path>
			<path d="M22 6.74V3.58C22 2.7 21.3 2 20.42 2h-3.16v4.74z" fill="#1967D2"></path>
		</svg>
	)
}

type ICalendarSyncButtonsProps = {
	calendar: NotFoundCalendar | DisconnectedCalendar
	/**
	 * If set, the component will only show a button to reconnect that calendar
	 */
	reconnect?: CalendarPlatform
}

export function CalendarSyncButtons({ calendar, reconnect }: ICalendarSyncButtonsProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const isCalendarIntegrationEnabled = workspace.isPlanFeatureEnabled("calendar-integration")

	const handleConnectWorkCalendarMicrosoftOutlook = () => {
		window.location.href = calendar.microsoftOutlookOAuthUrl
	}

	const handleConnectWorkCalendarGoogle = () => {
		window.location.href = calendar.googleCalendarOAuthUrl
	}

	const buttonClassNames = clsx(
		" inline-flex text-nowrap gap-2 justify-center items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
		"bg-white text-gray-900 focus-visible:outline-gray-600 border border-gray-300 hover:bg-gray-100 w-full",
		"disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-300 disabled:cursor-not-allowed",
	)

	const UpgradeTooltip = ({ children }: { children: React.ReactNode }) => (
		<Tooltip
			content={!isCalendarIntegrationEnabled ? t("Upgrade to a Flex plan now to connect your calendar.") : false}
			wrapperClassName="flex gap-4 justify-center"
			tooltipClassName="!top-16"
		>
			{children}
		</Tooltip>
	)

	switch (reconnect) {
		case "microsoft_outlook":
			return (
				<UpgradeTooltip>
					<TrackingButton
						type="button"
						onClick={handleConnectWorkCalendarMicrosoftOutlook}
						className={buttonClassNames}
						eventName="Calendar disconnected state: work calendar reconnection attempt"
						eventProperties={{
							calendarPlatform: "Microsoft Outlook",
						}}
						disabled={!isCalendarIntegrationEnabled}
					>
						<IconWrapper>
							<MicrosoftOutlookSvg />
						</IconWrapper>
						{t("Reconnect")} Microsoft Outlook
					</TrackingButton>
				</UpgradeTooltip>
			)

		case "google_calendar":
			return (
				<UpgradeTooltip>
					<TrackingButton
						type="button"
						onClick={handleConnectWorkCalendarGoogle}
						className={buttonClassNames}
						eventName="Calendar disconnected state: work calendar reconnection attempt"
						eventProperties={{
							calendarPlatform: "Google Calendar",
						}}
						disabled={!isCalendarIntegrationEnabled}
					>
						<IconWrapper>
							<GoogleCalendarSvg />
						</IconWrapper>
						{t("Reconnect")} Google Calendar
					</TrackingButton>
				</UpgradeTooltip>
			)

		case undefined:
			return (
				<div className="my-4 flex items-center justify-center gap-6 max-w-md m-auto">
					<UpgradeTooltip>
						<TrackingButton
							type="button"
							onClick={handleConnectWorkCalendarMicrosoftOutlook}
							className={buttonClassNames}
							eventName="Calendar empty state: work calendar connection attempt"
							eventProperties={{
								calendarPlatform: "Microsoft Outlook",
							}}
							disabled={!isCalendarIntegrationEnabled}
						>
							<IconWrapper>
								<MicrosoftOutlookSvg />
							</IconWrapper>
							Microsoft Outlook
						</TrackingButton>
					</UpgradeTooltip>
					<UpgradeTooltip>
						<TrackingButton
							type="button"
							onClick={handleConnectWorkCalendarGoogle}
							className={buttonClassNames}
							eventName="Calendar empty state: work calendar connection attempt"
							eventProperties={{
								calendarPlatform: "Google Calendar",
							}}
							disabled={!isCalendarIntegrationEnabled}
						>
							<IconWrapper>
								<GoogleCalendarSvg />
							</IconWrapper>
							Google Calendar
						</TrackingButton>
					</UpgradeTooltip>
				</div>
			)

		default:
			assertNever(reconnect)
	}
}
