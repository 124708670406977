import type React from "react"
import { useState } from "react"

import { ApiError } from "../../../../../core/infra/gateways/api-errors"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { sleep } from "../../../../utils/time"

type WorkspacePreferencesFormState =
	| { status: "idle" }
	| { status: "submitting" }
	| { status: "success" }
	| { status: "error"; error: ApiError }

export function usePreferencesSettings() {
	const { workspace, refetchMe } = useAuthenticatedSession()
	const { workspacesGateway } = useDependencies()
	const [language, setLanguage] = useState(workspace.props.language)
	const [formStatus, setFormStatus] = useState<WorkspacePreferencesFormState>({ status: "idle" })

	const handleLanguageChange = (newLanguage: string) => {
		if (newLanguage !== "en" && newLanguage !== "fr" && newLanguage !== "es") {
			throw new Error(`Unknown language '${newLanguage}'`)
		}
		setLanguage(newLanguage)
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			setFormStatus({ status: "submitting" })
			await Promise.all([
				workspacesGateway.updatePreferences({ language: language }),
				sleep(1000), // Artificial delay to avoid UI glitch because of the spinner that would appear and disappear too quickly
			])
			await refetchMe()
			setFormStatus({ status: "success" })
		} catch (error) {
			if (error instanceof ApiError) {
				setFormStatus({
					status: "error",
					error,
				})
				return
			}
			console.error(error)
		}
	}

	return {
		language,
		formStatus,
		handleLanguageChange,
		handleSubmit,
	}
}
