import clsx from "clsx"
import React from "react"

import type { GetSalesRelationshipsResponse } from "../../../../core/application/gateways/relationships.gateway"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { InsightCard } from "../../../components/shared/InsightCard.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { useRelationshipsContext } from "../../../contexts/relationships.context"
import { DealFilters } from "../components/DealFilters.component"
import { DealsTable } from "../components/DealsTable.component"
import { useDealFilters } from "../useDealFilters"
import { formatCurrency } from "../utils"

export function DealsListView() {
	const { relationshipsGateway } = useDependencies()
	const { t } = useLanguage()
	const [relationshipsResponse, setRelationshipsResponse] = React.useState<GetSalesRelationshipsResponse | null>(null)
	const [isLoading, setIsLoading] = React.useState(true)
	const { user } = useAuthenticatedSession()
	const { showRelationshipDetailsByOpportunityId } = useRelationshipsContext()

	const {
		selectedClosingDate,
		selectedDeals,
		selectedOwner,
		onSelectClosingDate,
		onSelectDeals,
		onSelectOwner,
		filter,
		dealsToShow,
	} = useDealFilters()

	React.useEffect(() => {
		const fetchRelationships = async () => {
			return relationshipsGateway.getSalesRelationships({
				closingDateEnd: filter.closingDateEnd,
				closingDateStart: filter.closingDateStart,
				ownerId: filter.ownerId,
			})
		}

		setIsLoading(true)
		fetchRelationships()
			.then((r) => setRelationshipsResponse(r))
			.finally(() => setIsLoading(false))
	}, [filter.closingDateEnd, filter.closingDateStart, filter.ownerId, relationshipsGateway])

	const handleOpportunityClick = React.useCallback(
		(opportunityId: string, tabToOpen?: "risks" | "call-activity" | null) => {
			showRelationshipDetailsByOpportunityId(opportunityId, tabToOpen ?? null)
		},
		[showRelationshipDetailsByOpportunityId],
	)

	if (!relationshipsResponse) {
		return (
			<div className="flex items-center justify-center flex-1">
				<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
			</div>
		)
	}

	const totalAmountDealsAtRisk = relationshipsResponse.relationships.reduce((acc, relationship) => {
		if (relationship.risks?.length) {
			return acc + (relationship.opportunity?.amount ?? 0)
		}
		return acc
	}, 0)

	const totalAmount = relationshipsResponse.relationships.reduce((acc, relationship) => {
		return acc + (relationship.opportunity?.amount ?? 0)
	}, 0)

	const totalDealsCount = relationshipsResponse.relationships.length
	const averageDealSize = totalDealsCount ? totalAmount / totalDealsCount : 0

	const relationshipsToShow =
		dealsToShow === "at-risk"
			? relationshipsResponse.relationships.filter((relationship) => relationship.risks?.length)
			: relationshipsResponse.relationships

	return (
		<div className="relative">
			{isLoading && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
				</div>
			)}

			<div className={clsx(isLoading && "opacity-50")}>
				<div className="mt-6 flex mb-6 sm:mb-0 justify-between">
					<InsightCard
						title={t("Active deals")}
						subtitle={totalAmountDealsAtRisk ? `${formatCurrency(totalAmountDealsAtRisk)} at risk` : ""}
						subtitleClassName="text-red-500 font-medium"
						tooltip={t(
							"Only deals with call recordings in Rippletide are counted and displayed in this view",
						)}
						values={[
							{
								value: `${formatCurrency(totalAmount)} (${totalDealsCount})`,
								label: `${formatCurrency(averageDealSize)} ${t("avg")}`,
								key: "total-deals",
							},
						]}
					/>
					<DealFilters
						dealOwners={relationshipsResponse.owners.map((owner) => ({
							id: owner.crmId,
							name: `${owner.firstName} ${owner.lastName}`,
						}))}
						selectedDeals={selectedDeals}
						onSelectDeals={onSelectDeals}
						selectedOwner={selectedOwner}
						onSelectOwner={onSelectOwner}
						selectedClosingDate={selectedClosingDate}
						onSelectClosingDate={onSelectClosingDate}
						disabled={isLoading}
						canSelectOwner={user.isWorkspaceManager()}
					/>
				</div>

				<DealsTable relationships={relationshipsToShow} onOpportunityClick={handleOpportunityClick} />
			</div>
		</div>
	)
}
