import { Menu, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { BookmarkIcon, ShareIcon, XMarkIcon } from "@heroicons/react/24/outline"
import React, { Fragment } from "react"
import { useOnClickOutside } from "usehooks-ts"

import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { PlanBadge } from "../../../../components/shared/PlanBadge.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"

type ICallTimeRangeOptionsMenuProps = {
	onCancel: () => void
	onCreateCallHighlight: () => void
	onShareCall: () => void
	canShareCall: boolean
}

export function CallTimeRangeOptionsMenu({
	onCancel,
	onCreateCallHighlight,
	onShareCall,
	canShareCall,
}: ICallTimeRangeOptionsMenuProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const isLibraryEnabled = workspace.isPlanFeatureEnabled("library")
	const ref = React.useRef(null)

	useOnClickOutside(ref, onCancel)

	return (
		<Menu as="div" className="relative inline-block text-left">
			<Transition
				show
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems
					static
					className="absolute left-8 z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
					ref={ref}
				>
					<div className="py-1">
						<MenuItem>
							<div>
								<TrackingButton
									className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-100 w-full text-left group flex items-center disabled:opacity-60 disabled:cursor-not-allowed"
									eventName="create call highlight clicked"
									onClick={onCreateCallHighlight}
									disabled={!isLibraryEnabled}
								>
									<BookmarkIcon className="mr-3 h-4 w-4 text-gray-700 group-hover:text-gray-500" />
									{t("Create a call highlight")}
									{!isLibraryEnabled && (
										<PlanBadge
											source="call: create highlight"
											minimumPlanName="Scaler"
											makeTooltipMessage={(minimumPlanName) =>
												t(
													"Upgrade to a {{minimumPlanName}} plan now to save the best moments from your calls into your sales training library. Click to learn more.",
													{
														minimumPlanName,
													},
												)
											}
										/>
									)}
								</TrackingButton>
							</div>
						</MenuItem>
						{canShareCall && (
							<MenuItem>
								<div>
									<TrackingButton
										className="text-gray-700 px-4 py-2 text-sm hover:bg-gray-100 w-full text-left group flex items-center"
										eventName="share call clicked"
										onClick={onShareCall}
									>
										<ShareIcon className="mr-3 h-4 w-4 text-gray-700 group-hover:text-gray-500" />
										{t("Share call snippet")}
									</TrackingButton>
								</div>
							</MenuItem>
						)}
						<MenuItem>
							<div>
								<TrackingButton
									className="text-red-700 px-4 py-2 text-sm hover:bg-gray-100 w-full text-left group flex items-center"
									eventName="share call cancel clicked"
									onClick={onCancel}
								>
									<XMarkIcon className="mr-3 h-4 w-4 text-red-500 group-hover:text-red-400" />
									{t("Cancel")}
								</TrackingButton>
							</div>
						</MenuItem>
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	)
}
