import { z } from "zod"

import type {
	CalendarAndEvents,
	ICalendarEventListQuery,
	ICalendarEventsGateway,
	ICalendarEventUpdateBotQuery,
} from "../../application/gateways/calendar-events.gateway"
import { RecordingStatus } from "../../domain/CalendarEvent.entity"
import type { PublicCalendarEvent } from "../../domain/PublicCalendarEvent.entity"
import { ISODateTimeSchema } from "./ISODateTime"

const HttpPresentedBotStatusSchema = z.object({
	code: z.nativeEnum(RecordingStatus),
	canSendBotToMeetingNow: z.boolean(),
	botWillJoinAt: ISODateTimeSchema.optional(),
})

const HttpPresentedCalendarEventSchema = z.object({
	id: z.string(),
	startTime: ISODateTimeSchema,
	endTime: ISODateTimeSchema,
	title: z.string(),
	meetingUrl: z.string(),
	botStatus: HttpPresentedBotStatusSchema,
	briefing: z
		.object({
			version: z.number(),
			result: z.string(),
		})
		.optional(),
	source: z.enum(["linked-calendar", "coach"]),
})

const HttpPresentedPublicCalendarEventSchema = HttpPresentedCalendarEventSchema.omit({
	botStatus: true,
})

const CalendarPlatformSchema = z.enum(["google_calendar", "microsoft_outlook"])

const connectedSchema = z.object({
	status: z.literal("connected"),
	platform: CalendarPlatformSchema,
	wasEverSynced: z.boolean(),
})

const connectingSchema = z.object({
	status: z.literal("connecting"),
	platform: CalendarPlatformSchema,
})

const disconnectedSchema = z.object({
	status: z.literal("disconnected"),
	platform: CalendarPlatformSchema,
	microsoftOutlookOAuthUrl: z.string(),
	googleCalendarOAuthUrl: z.string(),
})

const notFoundSchema = z.object({
	status: z.literal("not-found"),
	microsoftOutlookOAuthUrl: z.string(),
	googleCalendarOAuthUrl: z.string(),
})

const ListCalendarEventsRouteResponseSchema = z.object({
	calendar: z.discriminatedUnion("status", [connectedSchema, connectingSchema, disconnectedSchema, notFoundSchema]),
	calendarEvents: z.array(HttpPresentedCalendarEventSchema),
})

export class HttpCalendarEventsGateway implements ICalendarEventsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async list(query: ICalendarEventListQuery): Promise<CalendarAndEvents> {
		const queryParams = new URLSearchParams()
		if (query.filter) {
			queryParams.append("filter", query.filter)
		}

		const endpointUrl = `${this.baseApiUrl}/calendar/events?${queryParams}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		const result = ListCalendarEventsRouteResponseSchema.parse(json)
		return result
	}

	public async deleteCalendar(): Promise<void> {
		const res = await fetch(`${this.baseApiUrl}/calendar`, {
			method: "DELETE",
			credentials: "include",
		})
		if (!res.ok) {
			throw new Error("Failed to delete calendar")
		}
	}

	public async updateBot(calendarEventId: string, query: ICalendarEventUpdateBotQuery): Promise<void> {
		const res = await fetch(`${this.baseApiUrl}/calendar/events/${calendarEventId}`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			credentials: "include",
			body: JSON.stringify(query),
		})
		if (!res.ok) {
			console.error("Failed to update bot")
			res.text().then(console.error)
			throw new Error("Failed to update bot")
		}
	}

	public async getPublicCalendarEvent(calendarEventId: string, inviteToken: string): Promise<PublicCalendarEvent> {
		const endpointUrl = `${this.baseApiUrl}/share/calendar-events/${calendarEventId}?inviteToken=${inviteToken}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		return HttpPresentedPublicCalendarEventSchema.parse(json)
	}
}
