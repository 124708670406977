import assertNever from "assert-never"
import { Navigate, useParams } from "react-router-dom"

import type { Workspace } from "../../../core/domain/Workspace.entity"
import { Card } from "../../components/design-system/Card.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { PlanBadge } from "../../components/shared/PlanBadge.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { type TFunction, useLanguage } from "../../contexts/language.context"
import {
	defaultSettingsScope,
	defaultUserSettingsType,
	defaultWorkspaceSettingsType,
	makeUserSettingsPath,
	makeWorkspaceSettingsPath,
	type SettingsPageParams,
	type SettingsScope,
	settingsScopes,
	type SettingsType,
	type UserSettingsType,
	userSettingsTypes,
	type WorkspaceSettingsType,
	workspaceSettingsTypes,
} from "./config"
import { SettingsSideNavigationPanel } from "./Layout/SideNavigationPanel.component"
import { UserCalendarSettings } from "./User/UserCalendarSettings"
import { UserProfileSettings } from "./User/UserProfileSettings.component"
import { BillingSettings } from "./Workspace/BillingSettings/BillingSettings.component"
import { WorkspaceCrmSettings } from "./Workspace/CrmSettings/CRMSettings.component"
import { PreferencesSettings } from "./Workspace/PreferencesSettings/PreferencesSettings.component"
import { WorkspaceUsersSettings } from "./Workspace/UsersSettings/UsersSettings.component"

function useSettingsUrl(): { settingsScope: SettingsScope; settingsType: SettingsType } | { redirectTo: string } {
	const { settingsScope: _settingsScope, settingsType } = useParams<SettingsPageParams>()
	const { user } = useAuthenticatedSession()

	const settingsScope =
		_settingsScope && settingsScopes.includes(_settingsScope) ? _settingsScope : defaultSettingsScope
	if (!settingsScope || !settingsScopes.includes(settingsScope)) {
		// invalid settings scope, example: /settings/invalid, redirect to default
		return { redirectTo: makeUserSettingsPath(defaultUserSettingsType) }
	}

	if (settingsScope === "user") {
		if (settingsType && userSettingsTypes.includes(settingsType as UserSettingsType)) {
			return {
				settingsScope,
				settingsType,
			}
		}
		// invalid settings type, example: /settings/user/invalid, redirect to default
		return { redirectTo: makeUserSettingsPath(defaultUserSettingsType) }
	}

	// user is not a workspace manager, redirect to user settings
	// only the billing settings can be accessed by non-managers
	if (!user.isWorkspaceManager() && settingsType !== "billing") {
		return {
			redirectTo: makeUserSettingsPath(defaultUserSettingsType),
		}
	}

	// user is trying to access the crm settings but is not the workspace owner, redirect to user settings
	if (settingsType === "crm" && !user.isWorkspaceOwner()) {
		return {
			redirectTo: makeUserSettingsPath(defaultUserSettingsType),
		}
	}

	if (settingsType && workspaceSettingsTypes.includes(settingsType as WorkspaceSettingsType)) {
		return {
			settingsScope,
			settingsType,
		}
	}

	// invalid settings type, example: /settings/workspace/invalid, redirect to default
	return {
		redirectTo: makeWorkspaceSettingsPath(defaultWorkspaceSettingsType),
	}
}

function getSettingsPanel(
	settingsScope: SettingsScope,
	settingsType: SettingsType,
	workspace: Workspace,
	t: TFunction,
) {
	switch (settingsScope) {
		case "user":
			switch (settingsType) {
				case "calendar":
					return (
						<Card
							title={
								<div className="flex items-center gap-x-2">
									{t("Calendar integration")}
									{!workspace.isPlanFeatureEnabled("calendar-integration") && (
										<PlanBadge
											source="settings: calendar settings"
											minimumPlanName="Flex"
											makeTooltipMessage={(minimumPlanName) =>
												t(
													"Upgrade to a {{minimumPlanName}} plan now to connect your CRM. Click to learn more.",
													{
														minimumPlanName,
													},
												)
											}
										/>
									)}
								</div>
							}
						>
							<UserCalendarSettings />
						</Card>
					)
				case "profile":
				default:
					return (
						<Card title={t("Profile")}>
							<UserProfileSettings />
						</Card>
					)
			}
		case "workspace":
			switch (settingsType) {
				case "preferences":
					return <PreferencesSettings />
				case "users":
					return <WorkspaceUsersSettings />
				case "crm":
					return <WorkspaceCrmSettings />
				case "billing":
					return <BillingSettings />
				default:
					throw new Error("Unknown setting type")
			}
		default:
			assertNever(settingsScope)
	}
}

export function SettingsPage() {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const settingsUrl = useSettingsUrl()

	if ("redirectTo" in settingsUrl) {
		return <Navigate to={settingsUrl.redirectTo} replace={true} />
	}

	const { settingsScope, settingsType } = settingsUrl

	return (
		<Layout pageName={t("Settings")} className="py-16">
			<div className="flex flex-col lg:flex-row">
				<SettingsSideNavigationPanel settingsScope={settingsScope} settingsType={settingsType} />

				<main className="sm:px-6 px-1 lg:flex-auto">
					<div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
						{getSettingsPanel(settingsScope, settingsType, workspace, t)}
					</div>
				</main>
			</div>
		</Layout>
	)
}
