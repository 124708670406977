import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { EllipsisVerticalIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline"
import { useCallback, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { DEMO_TRAINING_PROGRAM_ID } from "../../../../../config"
import type { TrainingProgramWithStats } from "../../../../../core/application/gateways/training.gateway/schemas/training-program-with-stats.schema"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { EmptyState } from "../../../../components/shared/EmptyState.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeCreateTrainingProgramPath, makeEditTrainingProgramPath } from "../../../../router/Router"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { usePrograms } from "../TrainingPrograms.page"
import { PreviewTrainingProgramModal } from "./PreviewTrainingProgramModal.component"

type EditDropdownDropdownProps = {
	onDeleteClick: () => void
	trainingProgram: TrainingProgramWithStats["trainingProgram"]
}

function EditDropdown({ onDeleteClick, trainingProgram }: EditDropdownDropdownProps) {
	const { t } = useLanguage()
	return (
		<Menu as="div" className="relative inline-block text-left">
			<MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<div>
					<EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
				</div>
			</MenuButton>

			<MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
				<div className="py-1">
					<MenuItem>
						<div>
							<TrackingLink
								to={makeEditTrainingProgramPath(trainingProgram.id, "name")}
								eventName="Training program edit button click"
								eventProperties={{
									trainingProgramId: trainingProgram.id,
								}}
								className="text-gray-900 hover:bg-gray-50 px-4 py-2 group w-full flex"
							>
								<span className="text-medium">{t("Edit…")}</span>
							</TrackingLink>
						</div>
					</MenuItem>
					{trainingProgram.id !== DEMO_TRAINING_PROGRAM_ID && (
						<MenuItem>
							<div>
								<TrackingButton
									eventName="Training program deleted"
									eventProperties={{
										trainingProgramId: trainingProgram.id,
									}}
									onClick={onDeleteClick}
									type="button"
									className="text-red-600 hover:text-red-500 hover:bg-gray-50 px-4 py-2 group w-full flex"
								>
									<TrashIcon className="h-5 w-5 mr-1" />
									<span className="text-medium">{t("Delete…")}</span>
								</TrackingButton>
							</div>
						</MenuItem>
					)}
				</div>
			</MenuItems>
		</Menu>
	)
}

export function TrainingProgramList() {
	const { t, language } = useLanguage()
	const navigate = useNavigate()
	const location = useLocation()
	const { trainingGateway } = useDependencies()
	const { state, refreshPrograms } = usePrograms()
	const [programIdToPreview, setProgramIdToPreview] = useState<string | null>(null)

	const dateFormatter = new DateFormatter(new DateProvider(), t)

	const handleDeleteTraining = useCallback(
		async (trainingId: string) => {
			try {
				if (!window.confirm(t("Are you sure you want to delete this training program?"))) {
					return
				}

				await trainingGateway.deleteTrainingProgram(trainingId)
				await refreshPrograms()
			} catch (e) {
				console.error("Failed to delete training program", e)
			}
		},
		[refreshPrograms, t, trainingGateway],
	)

	if (state.status === "loading") {
		return <SpinnerIcon className={"h-8 w-8 mx-auto"} />
	}

	if (state.status === "error") {
		return <div>{state.error}</div>
	}

	const trainingPrograms = state.data

	if (!trainingPrograms.length) {
		return (
			<div className="flex items-center justify-center">
				<EmptyState
					className="max-w-xl mt-16"
					title={t("Create your first training program for your team")}
					description={t(
						"Compile a selection of best practice highlights from your library to offer tailored training to selected members of your team.",
					)}
					cta={{
						type: "link",
						label: t("Create training program"),
						to: makeCreateTrainingProgramPath(),
						state: { from: location.pathname },
						analyticsEventName: "Create training program button clicked",
					}}
				/>
			</div>
		)
	}

	const sortedTrainingPrograms = trainingPrograms.sort((a, b) => {
		return b.trainingProgram.updatedAt.getTime() - a.trainingProgram.updatedAt.getTime()
	})

	return (
		<>
			{programIdToPreview && (
				<PreviewTrainingProgramModal
					open={true}
					handleClose={() => setProgramIdToPreview(null)}
					programId={programIdToPreview}
				/>
			)}
			<div className="flex justify-end">
				<TrackingButton
					type="button"
					onClick={() => navigate(makeCreateTrainingProgramPath(), { state: { from: location.pathname } })}
					className="flex items-center rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					eventName="Create training program button clicked"
				>
					{t("Create training program")}
				</TrackingButton>
			</div>
			<div className="px-4 sm:px-6 lg:px-8 h-full">
				<div className="mt-8 flow-root h-full">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">
						<div className="inline-block min-w-full py-2 align-middle border border-gray-200 rounded-xl">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
										>
											{t("Name")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Description")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Items")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Completion")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Team members enrolled")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Created by")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Created")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900"
										>
											{t("Updated")}
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
											<span className="sr-only">Actions</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{sortedTrainingPrograms.map(({ trainingProgram, creatorFullName, enrolments }) => {
										const completionRate = enrolments.enrolled
											? ((enrolments.completed / enrolments.enrolled) * 100).toFixed(0)
											: 0

										const createdAt = dateFormatter.formatDate(
											trainingProgram.createdAt,
											language,
											{
												day: "2-digit",
												month: "2-digit",
												year: "2-digit",
											},
										)
										const updatedAt = dateFormatter.formatDate(
											trainingProgram.updatedAt,
											language,
											{
												day: "2-digit",
												month: "2-digit",
												year: "2-digit",
											},
										)

										return (
											<tr key={trainingProgram.id}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 max-w-[256px] truncate">
													<TrackingLink
														to={makeEditTrainingProgramPath(trainingProgram.id, "name")}
														eventName="Training program name clicked"
														eventProperties={{
															trainingProgramId: trainingProgram.id,
														}}
													>
														{trainingProgram.name}
													</TrackingLink>
												</td>
												<td
													title={trainingProgram.description}
													className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-[240px] truncate"
												>
													<TrackingLink
														to={makeEditTrainingProgramPath(trainingProgram.id, "name")}
														eventName="Training program description clicked"
														eventProperties={{
															trainingProgramId: trainingProgram.id,
														}}
													>
														{trainingProgram.description}
													</TrackingLink>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<TrackingLink
														to={makeEditTrainingProgramPath(trainingProgram.id, "content")}
														eventName="Training program items clicked"
														eventProperties={{
															trainingProgramId: trainingProgram.id,
														}}
													>
														{t("{{count}} items", {
															count: trainingProgram.totalItems,
														})}
													</TrackingLink>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{completionRate}%
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<TrackingLink
														to={makeEditTrainingProgramPath(
															trainingProgram.id,
															"enrolments",
														)}
														eventName={`Training program enrolments clicked`}
														eventProperties={{
															trainingProgramId: trainingProgram.id,
														}}
														className="flex flex-row gap-2 font-semibold"
													>
														<p className="text-indigo-500 flex flex-col items-center">
															<span>{enrolments.enrolled}</span>
															<span>
																{t("enrolled", {
																	count: enrolments.enrolled,
																})}
															</span>
														</p>
														<p className="text-gray-500 flex flex-col items-center">
															<span>{enrolments.completed}</span>
															<span>
																{t("completed", {
																	count: enrolments.completed,
																})}
															</span>
														</p>
														<p className="text-red-500 flex flex-col items-center">
															<span>{enrolments.late}</span>
															<span>
																{t("late", {
																	count: enrolments.late,
																})}
															</span>
														</p>
													</TrackingLink>
												</td>
												<td className="px-3 py-4 text-sm text-gray-500 max-w-[128px]">
													{creatorFullName}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{createdAt}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{updatedAt}
												</td>
												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
													<div className="flex items-center">
														<Tooltip
															content={t("Preview training program")}
															tooltipClassName="top-12 right-10 !max-w-10 sm:!min-w-[150px]"
														>
															<TrackingButton
																eventName="Training program preview clicked"
																onClick={() =>
																	setProgramIdToPreview(trainingProgram.id)
																}
																className="text-gray-600 hover:text-gray-900 mr-2"
															>
																<EyeIcon className="h-5 w-5" />
															</TrackingButton>
														</Tooltip>
														<EditDropdown
															trainingProgram={trainingProgram}
															onDeleteClick={() =>
																handleDeleteTraining(trainingProgram.id)
															}
														/>
													</div>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
