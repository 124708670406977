import { z } from "zod"

import { WORKSPACE_ROLE_OVERRIDE_KEY } from "../../app/hooks/useLocalStorage"
import type { Call } from "./Call.entity"

const workspaceRoleEnum = z.enum(["admin", "manager", "user"])

export const userPropertiesSchema = z.object({
	id: z.string(),
	workspaceId: z.string(),
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	workspaceRole: workspaceRoleEnum,
	lastLoginAt: z.coerce.date().nullish(),
	calendarId: z.string().nullish(),
	calendarStatus: z.enum(["connected", "connecting", "disconnected"]).nullish(),
	featureFlags: z
		.object({
			isIntesciaCSM: z.boolean().optional(),
		})
		.nullish(),
	timezone: z.string().nullish(),
})

export type UserProperties = z.infer<typeof userPropertiesSchema>

export class User {
	private props: Omit<UserProperties, "workspaceRole">
	private _workspaceRole: UserProperties["workspaceRole"] // shouldn't be accessed directly, use getter instead

	private constructor(props: UserProperties) {
		this.props = props
		this._workspaceRole = props.workspaceRole
	}

	public get id() {
		return this.props.id
	}

	public get email() {
		return this.props.email
	}

	public get firstName() {
		return this.props.firstName
	}

	public get lastName() {
		return this.props.lastName
	}

	/**
	 * This code is used as a quick win for our Sales to switch workspace roles in the app for demo purposes.
	 */
	public get workspaceRole(): UserProperties["workspaceRole"] {
		const isWorkspaceRoleOveriddenWithMember = localStorage.getItem(WORKSPACE_ROLE_OVERRIDE_KEY) === "true"
		return isWorkspaceRoleOveriddenWithMember ? "user" : this._workspaceRole
	}

	public get lastLoginAt() {
		return this.props.lastLoginAt
	}

	public get calendarStatus() {
		return this.props.calendarStatus
	}

	public get workspaceId() {
		return this.props.workspaceId
	}

	public isIntesciaCSM() {
		return !!this.props.featureFlags?.isIntesciaCSM
	}

	public getFullName() {
		return `${this.props.firstName} ${this.props.lastName}`
	}

	public isWorkspaceOwner() {
		return this.workspaceRole === "admin"
	}

	public isWorkspaceManager() {
		return this.workspaceRole === "manager" || this.isWorkspaceOwner()
	}

	public isRippletideDemoUser() {
		return this.props.email === "demo@rippletide.com"
	}

	public hasCalendarIntegration() {
		return Boolean(this.props.calendarId)
	}

	public isRippletideEmployee() {
		// used to enable/disable features...
		return this.props.email.endsWith("@beasight.fr") || this.props.email.endsWith("@rippletide.com")
	}

	public isDeleted() {
		return this.props.email.startsWith("deleted+")
	}

	public isUnclaimed() {
		return !this.props.lastLoginAt && !this.props.firstName && !this.props.lastName
	}

	public canAccessReviewOf(call: Call) {
		const isAssignedToCall = call.props.assignedUserId === this.props.id
		return this.isWorkspaceManager() || isAssignedToCall
	}

	public isDisabled() {
		return this.email.endsWith(".disabled")
	}

	public static fromProperties(props: unknown) {
		return new User(userPropertiesSchema.parse(props))
	}
}
