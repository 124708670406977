import clsx from "clsx"

import type {
	SalesforceIntegrationOAuthCredentials,
	SalesforceIntegrationPasswordCredentials,
} from "../../../../../core/application/gateways/users.gateway"
import type { CrmIntegration } from "../../../../../core/domain/Workspace.entity"
import { Input } from "../../../../components/design-system/Input"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"
import { useLanguage } from "../../../../contexts/language.context"

export type CrmCredentialsFormProps = {
	crmIntegration: CrmIntegration
	credentialTypes: {
		label: string
		type: "password" | "oauth"
		select: () => void
	}[]
	onCredentialsChange: (credentials: CrmIntegration["credentials"]) => void
	disabled: boolean
}

export function CrmCredentialsForm({
	crmIntegration,
	credentialTypes,
	onCredentialsChange,
	disabled,
}: CrmCredentialsFormProps) {
	const { t } = useLanguage()
	return (
		<div className="mt-6">
			<p className="mt-2 text-sm font-medium text-gray-900">{t("How would you like to connect?")}</p>
			<nav className="mt-2 flex items-center justify-start space-x-4" aria-label="Tabs">
				{credentialTypes.map((credentialType) => (
					<TrackingButton
						key={credentialType.type}
						eventName="crm integration credentials type selected"
						eventProperties={{
							provider: crmIntegration.provider,
							credentialType: credentialType.type,
						}}
						onClick={credentialType.select}
						className={clsx(
							credentialType.type === crmIntegration.credentials.type
								? "bg-gray-200 text-gray-800"
								: "text-gray-600 hover:text-gray-800",
							"rounded-md px-3 py-2 text-sm font-medium",
							disabled && "opacity-50 cursor-not-allowed",
						)}
						type="button"
						disabled={disabled}
					>
						{credentialType.label}
					</TrackingButton>
				))}
			</nav>
			<Input.Helper>
				{t("Password is easier to set up, but OAuth offers more security and control.")}
			</Input.Helper>
			{crmIntegration.credentials.type === "password" && (
				<SalesforcePasswordCredentialsForm
					credentials={crmIntegration.credentials}
					onCredentialsChange={onCredentialsChange}
					disabled={disabled}
				/>
			)}
			{crmIntegration.credentials.type === "oauth" && (
				<SalesforceOAuthCredentialsForm
					credentials={crmIntegration.credentials}
					onCredentialsChange={onCredentialsChange}
					disabled={disabled}
				/>
			)}
		</div>
	)
}

export type SalesforcePasswordCredentialsFormProps = {
	credentials: SalesforceIntegrationPasswordCredentials
	onCredentialsChange: (credentials: CrmIntegration["credentials"]) => void
	disabled: boolean
}

export function SalesforcePasswordCredentialsForm({
	credentials,
	onCredentialsChange,
	disabled,
}: SalesforcePasswordCredentialsFormProps) {
	const { t } = useLanguage()

	const handleChange =
		(key: keyof SalesforceIntegrationPasswordCredentials) => (event: React.ChangeEvent<HTMLInputElement>) => {
			onCredentialsChange({ ...credentials, [key]: event.target.value })
		}

	return (
		<>
			<Input.Group>
				<Input.Label htmlFor="username">{t("Username")}</Input.Label>
				<Input.Input
					id="username"
					name="username"
					type="text"
					value={credentials.username}
					onChange={handleChange("username")}
					required
					disabled={disabled}
				/>
				<Input.Helper>{t("Enter the username you use to log into Salesforce.")}</Input.Helper>
			</Input.Group>
			<Input.Group>
				<Input.Label htmlFor="password">{t("Password")}</Input.Label>
				<Input.Input
					id="password"
					name="password"
					type="password"
					value={credentials.password}
					onChange={handleChange("password")}
					autoComplete="none"
					required
					disabled={disabled}
				/>
				<Input.Helper>{t("Enter your Salesforce password.")}</Input.Helper>
			</Input.Group>
			<Input.Group>
				<Input.Label htmlFor="securityToken">{t("Security token")}</Input.Label>
				<Input.Input
					id="securityToken"
					name="securityToken"
					type="password"
					value={credentials.securityToken}
					onChange={handleChange("securityToken")}
					required
					disabled={disabled}
				/>
				<Input.Helper className="flex">
					{t("Enter your Salesforce security token.")}
					<TrackingExternalHref
						eventName="salesforce security token help clicked"
						href="https://help.salesforce.com/s/articleView?id=sf.user_security_token.htm&type=5"
						className="ml-2"
					>
						{t("How to find your security token.")}
					</TrackingExternalHref>
				</Input.Helper>
			</Input.Group>
		</>
	)
}

export type SalesforceOAuthCredentialsFormProps = {
	credentials: SalesforceIntegrationOAuthCredentials
	onCredentialsChange: (credentials: CrmIntegration["credentials"]) => void
	disabled: boolean
}

export function SalesforceOAuthCredentialsForm({
	credentials,
	onCredentialsChange,
	disabled,
}: SalesforceOAuthCredentialsFormProps) {
	const { t } = useLanguage()

	const handleChange =
		(key: keyof SalesforceIntegrationOAuthCredentials) => (event: React.ChangeEvent<HTMLInputElement>) => {
			onCredentialsChange({ ...credentials, [key]: event.target.value })
		}

	return (
		<>
			<Input.Group>
				<Input.Label htmlFor="clientId">{t("Client ID")}</Input.Label>
				<Input.Input
					id="clientId"
					name="clientId"
					type="text"
					value={credentials.clientId}
					onChange={handleChange("clientId")}
					required
					disabled={disabled}
				/>
				<Input.Helper>{t("Enter your Salesforce Client ID.")}</Input.Helper>
			</Input.Group>
			<Input.Group>
				<Input.Label htmlFor="clientSecret">{t("Client secret")}</Input.Label>
				<Input.Input
					id="clientSecret"
					name="clientSecret"
					type="password"
					value={credentials.clientSecret}
					onChange={handleChange("clientSecret")}
					autoComplete="none"
					required
					disabled={disabled}
				/>
				<Input.Helper>{t("Enter your Salesforce Client Secret.")}</Input.Helper>
			</Input.Group>
		</>
	)
}
