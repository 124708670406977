import React, { useEffect } from "react"

import { CallSearchInput } from "../../components/shared/CallSearchInput.component"
import { CallsTable } from "../../components/shared/CallsTable/CallsTable.component"
import PaginationFooter from "../../components/shared/PaginationFooter/PaginationFooter.component"
import { eventBus, Events } from "../../hooks/eventBus"
import { useCalls } from "../../hooks/useCalls"
import { CallEmptyState } from "./CallEmptyState.component"

export function RecentCallsSection() {
	const {
		calls,
		totalCalls,
		pageNumber,
		pageSize,
		totalPages,
		handlePageNavigation,
		refreshCalls,
		isInitialLoading,
		handleSearchFilterChange,
		searchFilter,
	} = useCalls()

	useEffect(() => {
		const refreshHandler = () => refreshCalls(Events.CalendarEventRecordingDidBecomeAvailable)
		eventBus.addEventListener(Events.CalendarEventRecordingDidBecomeAvailable, refreshHandler)
		return () => eventBus.removeEventListener(Events.CalendarEventRecordingDidBecomeAvailable, refreshHandler)
	}, [refreshCalls])

	if (!calls.length && !isInitialLoading && searchFilter === null) {
		return <CallEmptyState />
	}

	return (
		<>
			<div className="mt-2">
				<CallSearchInput search={searchFilter ?? ""} onChange={handleSearchFilterChange} />
			</div>
			<CallsTable calls={calls} displayAssignedUser={false} refreshCalls={refreshCalls} />
			<PaginationFooter
				navigatePrev={() => handlePageNavigation((page) => Math.max(1, page - 1))}
				navigateNext={() => handlePageNavigation((page) => Math.min(totalPages, page + 1))}
				navigateToPage={(page) => handlePageNavigation(page)}
				currentPage={pageNumber}
				pageSize={pageSize}
				totalCalls={totalCalls}
			/>
		</>
	)
}
