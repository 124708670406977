import { LanguageSwitcher } from "../../../components/shared/LanguageSwitcher"
import { UserAvatar } from "../../../components/shared/User/UserAvatar.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { useDemoModeEnabled, useWorkspaceRoleOverride } from "../../../hooks/useLocalStorage"

export function UserProfileSettings() {
	const { t } = useLanguage()
	const { user, workspace, isPlatformAdmin } = useAuthenticatedSession()

	const [isWorkspaceRoleOveriddenWithMember, setWorkspaceRoleOverriddenByMember] = useWorkspaceRoleOverride()
	const [isDemoModeEnabled, setDemoModeEnabled] = useDemoModeEnabled()

	const onWorkspaceRoleOverrideChange = () => {
		setWorkspaceRoleOverriddenByMember(
			(prevIsWorkspaceRoleOveriddenWithMember) => !prevIsWorkspaceRoleOveriddenWithMember,
		)
		// To make sure the changes are applied, we need to reload the page (force rerendering)
		window.location.reload()
	}

	const onDemoModeEnabledChange = () => {
		setDemoModeEnabled((prevIsDemoModeEnabled) => !prevIsDemoModeEnabled)
		// To make sure the changes are applied, we need to reload the page (force rerendering)
		window.location.reload()
	}

	return (
		<>
			<dl className="px-6 py-4 space-y-6 divide-y divide-gray-100 text-sm leading-6">
				<div className="pt-6 sm:flex">
					<dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t("First name")}</dt>
					<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
						<div className="text-gray-900">{user.firstName}</div>
						{/* <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
							<div className="text-gray-900">Tom Cook</div>
							<button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
								Update
							</button>
						</dd> */}
					</dd>
				</div>
				<div className="pt-6 sm:flex">
					<dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t("Last name")}</dt>
					<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
						<div className="text-gray-900">{user.lastName}</div>
					</dd>
				</div>
				<div className="pt-6 sm:flex">
					<dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t("Email address")}</dt>
					<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
						<div className="text-gray-900">{user.email}</div>
					</dd>
				</div>
				<div className="pt-6 sm:flex">
					<dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t("Avatar")}</dt>
					<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
						<UserAvatar user={user} className="h-9 w-9" />
					</dd>
				</div>
				{isPlatformAdmin && (
					<>
						<div className="pt-6 sm:flex">
							<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
								<div className="relative flex items-start">
									<div className="flex h-6 items-center">
										<input
											id="demo-mode-checkboxx"
											name="demo-mode-checkbox"
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={() => onDemoModeEnabledChange()}
											checked={!!isDemoModeEnabled}
										/>
									</div>
									<div className="ml-3 text-sm leading-6">
										<label htmlFor="demo-mode-checkbox" className="font-medium text-gray-900">
											Enable demo mode (hides platform admin and upcoming features)
										</label>
									</div>
								</div>
							</dd>
						</div>
						<div className="pt-6 sm:flex">
							<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
								<div className="relative flex items-start">
									<div className="flex h-6 items-center">
										<input
											id="workspace-role-override-checkbox"
											name="workspace-role-override-checkbox"
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
											onChange={() => onWorkspaceRoleOverrideChange()}
											checked={!!isWorkspaceRoleOveriddenWithMember}
										/>
									</div>
									<div className="ml-3 text-sm leading-6">
										<label
											htmlFor="workspace-role-override-checkbox"
											className="font-medium text-gray-900"
										>
											See app as "member" (hides owner and manager features)
										</label>
									</div>
								</div>
							</dd>
						</div>
						<div className="pt-6 sm:flex">
							<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
								<div className="relative flex items-start">
									<div className="mr-3 text-sm leading-6">
										<label className="font-medium text-gray-900">
											Override workspace language for UI (={workspace.props.language})
										</label>
									</div>
									<div className="flex h-6 items-center">
										<LanguageSwitcher />
									</div>
								</div>
							</dd>
						</div>
					</>
				)}
			</dl>
		</>
	)
}
