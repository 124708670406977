import { Layout } from "../../components/shared/Layout/Layout.component"
import { useLanguage } from "../../contexts/language.context"
import { RecentCallsSection } from "./RecentCallsSection"

export function UserRecentCallsPage() {
	const { t } = useLanguage()
	return (
		<Layout
			pageName={t("Calls")}
			PageTitle={<Layout.PageTitle title={t("Calls")} />}
			PageSubtitle={
				<Layout.PageSubtitle
					subtitle={t("Your calls")}
					description={t(
						"A catalog of all your calls, complete with their analysis, transcripts and scores.",
					)}
				/>
			}
		>
			<RecentCallsSection />
		</Layout>
	)
}
