import { Dialog } from "@headlessui/react"
import React from "react"
import { Trans } from "react-i18next"

import type { NotFoundCalendar } from "../../../../core/application/gateways/calendar-events.gateway"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { Modal } from "../../design-system/Modal.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { CalendarSyncButtons } from "../CalendarSyncButtons.component"
import { useWelcomeModal } from "./useWelcomeModal"

type IWelcomeModalContentProps = {
	calendar: NotFoundCalendar
	handleRemindMeLater: () => void
}

function WelcomeModalContent({ calendar, handleRemindMeLater }: IWelcomeModalContentProps) {
	const { t } = useLanguage()
	const { refetchMe } = useAuthenticatedSession()

	React.useEffect(() => {
		refetchMe()
		const intervalId = setInterval(() => {
			refetchMe()
		}, 2500)

		return () => clearInterval(intervalId)
	}, [refetchMe])

	return (
		<div className="text-center px-4">
			<div className="mx-auto flex items-center justify-center text-6xl mb-4">🎉</div>

			<Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-700 mb-2">
				{t("Welcome to Rippletide!")}
			</Dialog.Title>

			<p className="font-semibold">{t("Sync your calendar to get started")}</p>

			<div className="my-6">
				<CalendarSyncButtons calendar={calendar} />
			</div>

			<div className="text-left">
				<p className="mb-2">{t("By syncing your calendar, you’ll unlock the full power of Rippletide:")}</p>

				<ul className="text-sm">
					<li className="py-1">
						<p>
							<Trans
								i18nKey="✍️ <1>Meeting recordings</1> with transcription and summary."
								components={{
									1: <strong />,
								}}
							/>
						</p>
					</li>
					<li className="py-1">
						<p>
							<Trans
								i18nKey="📝 <1>Tailored call briefs</1> for in-depth client insights and preparation."
								components={{
									1: <strong />,
								}}
							/>
						</p>
					</li>
					<li className="py-1">
						<p>
							<Trans
								i18nKey="🍎 <1>Personalised coaching</1> to help you continuously improve."
								components={{
									1: <strong />,
								}}
							/>
						</p>
					</li>
					<li className="py-1">
						<p>
							<Trans
								i18nKey="⚙️ <1>Recommended actions</1> so you can be efficient in your follow-up."
								components={{
									1: <strong />,
								}}
							/>
						</p>
					</li>
					<li className="py-1">
						<p>
							<Trans
								i18nKey="🔗 <1>Hands-free CRM automation</1> that keeps your records up-to-date for you."
								components={{
									1: <strong />,
								}}
							/>
						</p>
					</li>
				</ul>

				<p className="text-sm mt-2 text-gray-500">
					{t("You'll be able to keep control and choose which meetings you want Rippletide to join.")}
				</p>
			</div>

			<TrackingButton
				eventName="Welcome modal: remind me later"
				className="mt-4 text-gray-500"
				onClick={handleRemindMeLater}
			>
				{t("Remind me later")}
			</TrackingButton>
		</div>
	)
}

function ModalCoachOnboardingContent({ onGetStartedClick }: { onGetStartedClick: () => void }) {
	const { t } = useLanguage()

	return (
		<div className="px-4">
			<Dialog.Title as="h3" className="text-xl text-center font-bold leading-6 text-gray-700 mb-4">
				{t("Introducing: Rippletide’s Coach")}
			</Dialog.Title>
			<p className="font-semibold mb-4">
				<Trans
					i18nKey="Invite <1>{{email}}</1> to any meeting to access all the benefits of Rippletide."
					components={{
						1: <span className="text-sky font-semibold">coach@rippletide.com</span>,
					}}
					values={{ email: "coach@rippletide.com" }}
				/>
			</p>

			<div className="text-gray-700">
				<p>
					{t(
						"By inviting Coach, Rippletide will be scheduled to join your call, so you can access to all the benefits that will empower your sales process without having to sync your calendar.",
					)}
				</p>
				<p className="my-2">{t("All you need to do is:")}</p>

				<ul className="text-sm list-disc ml-6">
					<li className="py-1">
						<p className="font-semibold">
							<Trans
								i18nKey="Add <1>{{email}}</1> as a guest in advance of your meeting."
								components={{
									1: <span className="text-sky font-semibold">coach@rippletide.com</span>,
								}}
								values={{ email: "coach@rippletide.com" }}
							/>
						</p>
					</li>
					<li className="py-1">
						<p className="font-semibold">{t("Connect to the call")}</p>
					</li>
					<li className="py-1">
						<p className="font-semibold">{t("Accept Rippletide’s request to join the meeting")}</p>
					</li>
					<li className="py-1">
						<p className="font-semibold">
							{t("Navigate your call with confidence and let us do the rest!")}
						</p>
					</li>
				</ul>

				<div className="flex justify-center items-center mt-4">
					<TrackingButton
						onClick={onGetStartedClick}
						eventName="Welcome modal: get started clicked"
						className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						{t("Get started")}
					</TrackingButton>
				</div>
			</div>
		</div>
	)
}

export function WelcomeModal() {
	const { calendar, handleHideWelcomeModal, isOpen, modalStep, handleWelcomeStepNext } = useWelcomeModal()

	if (!calendar) {
		return null
	}

	return (
		<Modal open={isOpen} showCloseButton={false}>
			{modalStep === "1-welcome" ? (
				<WelcomeModalContent calendar={calendar} handleRemindMeLater={handleWelcomeStepNext} />
			) : (
				<ModalCoachOnboardingContent onGetStartedClick={handleHideWelcomeModal} />
			)}
		</Modal>
	)
}
