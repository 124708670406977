import React from "react"

import { TrackingLink } from "../../components/design-system/TrackingLink.component"
import { CalendarSyncButtons } from "../../components/shared/CalendarSyncButtons.component"
import { CallsTable } from "../../components/shared/CallsTable/CallsTable.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useCalendarContext } from "../../contexts/calendar.context"
import { useLanguage } from "../../contexts/language.context"
import { getDemoCall } from "../../utils/onboardingDemo/demoCall"
import { DateProvider } from "../../utils/time"
import { makeWorkspaceBillingPath } from "../Settings/config"

export function CallEmptyState() {
	const { t, language } = useLanguage()
	const { calendarAndEvents } = useCalendarContext()
	const { user, workspace } = useAuthenticatedSession()
	const isCallRecordingEnabled = workspace.isPlanFeatureEnabled("call-recording")

	const calls = React.useMemo(() => {
		const dateProvider = new DateProvider()

		return [getDemoCall(user, dateProvider, t, language)]
	}, [t, user, language])

	if (calendarAndEvents.data?.calendar.status !== "not-found") {
		return (
			<>
				<CallsTable calls={calls} displayAssignedUser={true} hideOverallScore />

				<div className="flex justify-center items-center mt-6">
					<div className="w-full rounded-lg border-2 border-dashed border-gray-300 text-center p-4 max-w-2xl">
						<h3 className="text-xl font-semibold text-gray-900">{t("No calls recorded yet")}</h3>
						{isCallRecordingEnabled ? (
							<>
								<p className="mt-1 text-sm text-gray-500 mb-2">
									{t("After your calls, check back for in-depth analysis and actionable insights.")}
								</p>
							</>
						) : (
							<>
								<p className="mt-1 text-sm text-gray-500 mb-2">
									{t("Upgrade your plan now to kickstart your efficiency 🚀")}
								</p>
								<TrackingLink
									to={makeWorkspaceBillingPath()}
									eventName="call empty state: upgrade plan"
									className="mt-2 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
								>
									{t("Upgrade my plan")}
								</TrackingLink>
							</>
						)}
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<CallsTable calls={calls} displayAssignedUser={true} hideOverallScore />
			<div className="flex justify-center items-center mt-6">
				<div className="w-full rounded-lg border-2 border-dashed border-gray-300 text-center p-4 max-w-2xl">
					<h3 className="text-xl font-semibold text-gray-900">{t("No calls recorded yet")}</h3>
					<p className="mt-1 text-sm text-gray-500 mb-2">
						{t(
							"Looks like your calendar isn't synced yet. Click below to connect your calendar and begin your journey towards sales mastery.",
						)}
					</p>

					<div className="flex gap-2 justify-center max-w-md m-auto">
						<CalendarSyncButtons calendar={calendarAndEvents.data.calendar} />
					</div>
				</div>
			</div>
		</>
	)
}
