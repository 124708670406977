import clsx from "clsx"

type InputGroupWrapperProps = {
	children: React.ReactNode
}

function InputGroupWrapper({ children }: InputGroupWrapperProps) {
	return <div className="mt-4">{children}</div>
}

type InputGroupLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

function InputGroupLabel({ children, ...htmlProps }: InputGroupLabelProps) {
	return (
		<label {...htmlProps} className="block text-sm font-medium leading-6 text-gray-900">
			{children}
		</label>
	)
}

type InputGroupInputProps = React.InputHTMLAttributes<HTMLInputElement>

function InputGroupInput({ ...htmlProps }: InputGroupInputProps) {
	return (
		<input
			{...htmlProps}
			className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
		/>
	)
}

type InputGroupHelperTextProps = {
	children: React.ReactNode
	className?: string
}

function InputGroupHelperText({ children, className }: InputGroupHelperTextProps) {
	return <p className={clsx("mt-2 text-sm text-gray-500", className)}>{children}</p>
}

export const Input = {
	Group: InputGroupWrapper,
	Label: InputGroupLabel,
	Input: InputGroupInput,
	Helper: InputGroupHelperText,
}
