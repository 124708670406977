import React from "react"

import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeDemoCalendarEvent } from "../../../../utils/onboardingDemo/demoCalendarEvent"
import { DateProvider } from "../../../../utils/time"
import { EventList } from "./EventList.component"

export function DemoEventList() {
	const { t, language } = useLanguage()
	const { user } = useAuthenticatedSession()
	const demoCalendarEvent = makeDemoCalendarEvent(user, new DateProvider(), t, language)
	const demoEventList = [demoCalendarEvent]

	return (
		<div className="flex-1 flex flex-col">
			<EventList events={demoEventList} />
		</div>
	)
}
