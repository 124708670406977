import clsx from "clsx"

import type { CrmIntegration } from "../../../../../core/domain/Workspace.entity"
import { Input } from "../../../../components/design-system/Input"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

export type CrmProviderPickerProps = {
	providers: Array<{
		label: string
		value?: CrmIntegration["provider"]
	}>
	crmIntegration: CrmIntegration
	onSelectedProviderChange: (provider: CrmIntegration["provider"]) => void
	disabled: boolean
}

export function CrmProviderPicker({
	crmIntegration,
	onSelectedProviderChange,
	providers,
	disabled,
}: CrmProviderPickerProps) {
	const { t } = useLanguage()
	return (
		<Input.Group>
			<Input.Label>{t("Pick your CRM provider")}</Input.Label>
			<nav className="mt-2 flex items-center justify-start space-x-4" aria-label="Tabs">
				{providers.map((provider) => {
					const Button = (
						<TrackingButton
							key={provider.label}
							eventName="crm integration provider selected"
							eventProperties={{ provider: provider.value }}
							onClick={() => {
								if (disabled || !provider.value) {
									return
								}
								onSelectedProviderChange(provider.value)
							}}
							type="button"
							className={clsx(
								provider.value === crmIntegration.provider
									? "bg-gray-200 text-gray-800"
									: "text-gray-600 hover:text-gray-800",
								"rounded-md px-3 py-2 text-sm font-medium",
								(!provider.value || disabled) && "opacity-50 cursor-not-allowed",
							)}
						>
							{provider.label}
						</TrackingButton>
					)
					if (!provider.value) {
						return (
							<Tooltip content={t("Coming soon")} tooltipClassName="top-12 left-4 ">
								{Button}
							</Tooltip>
						)
					}

					return Button
				})}
			</nav>
		</Input.Group>
	)
}
