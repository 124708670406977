import { Dialog } from "@headlessui/react"
import clsx from "clsx"
import React from "react"

import type { User } from "../../../../../core/domain/User.entity"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Modal } from "../../../../components/design-system/Modal.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { UserWorkspaceRoleDropdown } from "./UserWorkspaceRoleDropdown.component"

type IInviteUserModalProps = {
	open: boolean
	onClose: () => void
	onInviteSuccess: () => void
	currentWorkspaceUsers: User[]
}

type InviteState =
	| {
			status: "loading" | "done" | "idle"
	  }
	| {
			status: "error"
			error: Error
	  }

const defaultInvitedUserRole: User["workspaceRole"] = "user"

export function InviteUserModal({ open, onClose, onInviteSuccess, currentWorkspaceUsers }: IInviteUserModalProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const { usersGateway } = useDependencies()
	const [inviteState, setInviteState] = React.useState<InviteState>({ status: "idle" })
	const [email, setEmail] = React.useState("")
	const [role, setRole] = React.useState<User["workspaceRole"]>(defaultInvitedUserRole)

	const handleEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
		setInviteState({ status: "idle" })
	}, [])

	const handleRoleChange = React.useCallback((newRole: User["workspaceRole"]) => {
		setRole(newRole)
		setInviteState({ status: "idle" })
	}, [])

	const handleClose = React.useCallback(() => {
		setInviteState({ status: "idle" })
		setEmail("")
		setRole(defaultInvitedUserRole)
		onClose()
	}, [onClose])

	const handleSubmit = React.useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()
			try {
				setInviteState({
					status: "loading",
				})
				await usersGateway.inviteUserToWorkspace(email, role)

				setInviteState({
					status: "done",
				})
				setEmail("")
				setRole(defaultInvitedUserRole)
				onInviteSuccess()
			} catch (e) {
				if (e instanceof Error) {
					setInviteState({
						status: "error",
						error: e,
					})
				}
				console.error("Error:", e)
			}
		},
		[email, onInviteSuccess, role, usersGateway],
	)

	const existingUser = currentWorkspaceUsers.find((x) => x.email === email)
	const isFormDisabled = Boolean(inviteState.status === "loading" || !email || existingUser)

	return (
		<Modal open={open} onClose={handleClose} className="min-h-[440px]">
			<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
				{t("Invite a user to join your workspace")}
			</Dialog.Title>
			<Dialog.Description as="p" className="text-sm text-gray-500 mt-2">
				{t("After you invite a user, they will receive an email with a link to join your workspace.")}
			</Dialog.Description>
			{!workspace.isInTrial() && (
				<Alert
					className="mt-4"
					description={t("Note: You will be billed for the new user once they accept your invitation.")}
					variant="info"
				/>
			)}
			{inviteState.status === "done" && (
				<Alert description={t("User invited successfully!")} variant="success" className="mt-4" />
			)}
			{inviteState.status === "error" && (
				<Alert description={t("Something went wrong")} variant="error" className="mt-4" />
			)}
			{existingUser && (
				<Alert
					description={t("A user with this email already exists in your workspace")}
					variant="warning"
					className="mt-4"
				/>
			)}
			<form className="flex mt-4 flex-col gap-4" onSubmit={handleSubmit}>
				<div className="flex-1 flex gap-2 items-center">
					<div className="flex-[4]">
						<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
							{t("Email")}
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 read-only:bg-gray-50 read-only:text-gray-500 read-only:ring-gray-200 sm:text-sm sm:leading-6"
								minLength={2}
								value={email}
								onChange={handleEmailChange}
							/>
						</div>
					</div>
					<div className="flex-1">
						<label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
							{t("Role")}
						</label>
						<UserWorkspaceRoleDropdown role={role} onChange={handleRoleChange} />
					</div>
				</div>

				<div className="mt-4">
					<TrackingButton
						disabled={isFormDisabled}
						eventName="Invite user modal: invite clicked"
						type="submit"
						className={clsx(
							"flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
							isFormDisabled && "opacity-50 cursor-not-allowed hover:bg-indigo-600",
						)}
					>
						<span className="flex gap-2 items-center">
							{inviteState.status === "loading" && <SpinnerIcon className={"h-5 w-5 mx-auto"} />}
							{t("Invite user to workspace")}
						</span>
					</TrackingButton>
				</div>
			</form>
		</Modal>
	)
}
