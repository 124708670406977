import clsx from "clsx"
import { useMemo, useRef, useState } from "react"

import type { Call } from "../../../../core/domain/Call.entity"
import type { CallHighlight } from "../../../../core/domain/Library.entity"
import { HighlightsTimeline } from "../../../components/design-system/VideoPlayer/_HighlightsTimeline.component"
import { MouseDragRangeIndicator } from "../../../components/design-system/VideoPlayer/_MouseDragRangeIndicator.component"
import { TimelineCursor } from "../../../components/design-system/VideoPlayer/_TimelineCursor.component"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useSession } from "../../../contexts/authentication.context"
import { useCallVideoPlayer } from "../../../contexts/callVideoPlayer.context"
import { type TFunction, useLanguage } from "../../../contexts/language.context"
import type { CallTimeRange } from "../../../utils/callSharing"
import { CreateCallTimeRangeForm, useCreateCallTimeRangeForm } from "./_MainPanel/CreateCallTimeRangeForm.component"

type TimelineSegment = {
	attendeeId: string
	attendeeLabel: string
	segments: Array<{
		startTimeSec: number
		endTimeSec: number
		content: string
	}>
}

const splitTimelineByAttendeeId = (
	transcriptions: Call["props"]["transcription"],
	t: TFunction,
): Array<TimelineSegment> => {
	const timelineByAttendeeId: { [attendeeId: string]: TimelineSegment } = {}

	// Sort transcription turns by startTime
	const sortedTurns = [...(transcriptions?.turns ?? [])].sort((a, b) => a.startTimeSec - b.startTimeSec)

	// Group transcription turns by attendeeId and merge contiguous segments
	sortedTurns.forEach((turn) => {
		if (!timelineByAttendeeId[turn.attendeeId]) {
			timelineByAttendeeId[turn.attendeeId] = {
				attendeeId: turn.attendeeId,
				attendeeLabel: turn.attendeeLabel,
				segments: [
					{
						startTimeSec: turn.startTimeSec,
						endTimeSec: turn.endTimeSec,
						content: turn.content,
					},
				],
			}
		} else {
			const attendeeSegments = timelineByAttendeeId[turn.attendeeId].segments
			const lastSegment = attendeeSegments[attendeeSegments.length - 1]
			// If the current segment starts within 2 seconds of the last segment ending, merge them
			const isContiguous = turn.startTimeSec - lastSegment.endTimeSec <= 2
			if (isContiguous) {
				lastSegment.endTimeSec = turn.endTimeSec
				lastSegment.content += ` ${turn.content}`
			} else {
				// Otherwise, start a new segment
				attendeeSegments.push({
					startTimeSec: turn.startTimeSec,
					endTimeSec: turn.endTimeSec,
					content: turn.content,
				})
			}
		}
	})

	const timelines = Object.values(timelineByAttendeeId)
	if (timelines.length > 0) {
		return timelines
	}

	return [
		{
			attendeeId: "no_transcription",
			attendeeLabel: t("Timeline"),
			segments: [],
		},
	]
}

type SpeakerTimelinesProps = {
	callId: string | undefined
	transcription: Call["props"]["transcription"]
	totalDurationSeconds: number
	onPlayerTimeChange: (time: number) => void
	currentTimeSeconds: number
	highlights: CallHighlight[] | "loading"
	shouldHideTimelineActions: boolean
	refreshHighlights?: () => void
	verticalSpacing: 2 | 4
}

export function SpeakerTimelines({
	callId,
	transcription,
	totalDurationSeconds,
	onPlayerTimeChange,
	currentTimeSeconds,
	highlights,
	shouldHideTimelineActions,
	refreshHighlights,
	verticalSpacing,
}: SpeakerTimelinesProps) {
	const { setupEventTracking } = useAnalytics()
	const { user, workspace } = useSession()

	const { t } = useLanguage()
	const timelineSegments = splitTimelineByAttendeeId(transcription, t)
	const containerRef = useRef<HTMLDivElement>(null)
	const containerRect = containerRef.current?.getBoundingClientRect()
	const [currentHoverTimeSeconds, setCurrentHoverTimeSeconds] = useState<number | null>(null)
	const [mouseDragStartTimeSeconds, setMouseDragStartTimeSeconds] = useState<number | null>(null)
	const { allowedTimeRanges } = useCallVideoPlayer()

	const isHoverTimeInRange = allowedTimeRanges
		? allowedTimeRanges.some(
				(range) =>
					currentHoverTimeSeconds !== null &&
					currentHoverTimeSeconds >= range.startSec &&
					currentHoverTimeSeconds <= range.endSec,
		  )
		: true

	const { timeRangeState, resetTimeRangeForm, setTimeRange, confirmTimeRange, createCallHighlight } =
		useCreateCallTimeRangeForm(callId, refreshHighlights)

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!containerRect || timeRangeState.type !== "none" || shouldHideTimelineActions) return
		const xAxisPosition = e.clientX - containerRect.left
		const timeSeconds = (xAxisPosition / containerRect.width) * totalDurationSeconds
		setMouseDragStartTimeSeconds(timeSeconds)
	}

	const handleMouseUp = () => {
		if (
			!containerRect ||
			mouseDragStartTimeSeconds === null ||
			currentHoverTimeSeconds === null ||
			timeRangeState.type !== "none"
		)
			return

		const mouseDragEndTimeSeconds = currentHoverTimeSeconds

		const startTimeSeconds = Math.min(mouseDragStartTimeSeconds, mouseDragEndTimeSeconds)
		const endTimeSeconds = Math.max(mouseDragStartTimeSeconds, mouseDragEndTimeSeconds)

		// If the range is too small, don't create a range, it's probably a click
		if (endTimeSeconds - startTimeSeconds < 5) {
			setMouseDragStartTimeSeconds(null)
			onPlayerTimeChange(currentHoverTimeSeconds)
			return
		}

		setTimeRange({
			startTimeSeconds,
			endTimeSeconds,
		})

		setMouseDragStartTimeSeconds(null)
	}

	const { trackEvent, debugDOMProps } = setupEventTracking({
		eventName: "Call video interaction",
		eventProperties: {
			type: "timeline range drag",
			totalDurationSeconds,
			callId,
		},
	})

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!containerRect || timeRangeState.type !== "none" || !isHoverTimeInRange) return

		const xAxisPosition = e.clientX - containerRect.left
		const timeSeconds = (xAxisPosition / containerRect.width) * totalDurationSeconds
		onPlayerTimeChange(timeSeconds)
		trackEvent()
	}

	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		if (timeRangeState.type !== "none") return

		const container = e.currentTarget.getBoundingClientRect()
		const xAxisPosition = e.clientX - container.left
		const mouseHoverTimeSeconds = (xAxisPosition / container.width) * totalDurationSeconds
		// If the mouse is outside the element, don't update the hover time
		if (mouseHoverTimeSeconds < 0 || mouseHoverTimeSeconds > totalDurationSeconds) {
			return
		}

		setCurrentHoverTimeSeconds(mouseHoverTimeSeconds)
	}

	const handleMouseLeave = () => {
		setCurrentHoverTimeSeconds(null)
		setMouseDragStartTimeSeconds(null)
	}

	const timelineContent = useMemo(
		() => (
			<>
				{currentHoverTimeSeconds !== null && isHoverTimeInRange && (
					<TimelineCursor
						variant="active"
						currentTimeSeconds={currentHoverTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
						tooltipContent={
							mouseDragStartTimeSeconds === null && !shouldHideTimelineActions
								? t("Click and drag to create a highlight or share a snippet of the call")
								: undefined
						}
					/>
				)}
				{mouseDragStartTimeSeconds !== null && (
					<TimelineCursor
						variant="active"
						currentTimeSeconds={mouseDragStartTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
					/>
				)}
				{mouseDragStartTimeSeconds !== null && currentHoverTimeSeconds !== null && (
					<MouseDragRangeIndicator
						mouseDragStartTimeSeconds={mouseDragStartTimeSeconds}
						currentHoverTimeSeconds={currentHoverTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
					/>
				)}
			</>
		),
		[
			currentHoverTimeSeconds,
			isHoverTimeInRange,
			shouldHideTimelineActions,
			mouseDragStartTimeSeconds,
			t,
			totalDurationSeconds,
		],
	)

	const paddingClasses = {
		2: "md:p-6",
		4: "md:p-12",
	}

	return (
		<div className={clsx("mb-6 border border-gray-200 rounded-b-lg p-2 pb-20", paddingClasses[verticalSpacing])}>
			<div
				className={clsx("flex flex-col", isHoverTimeInRange ? "cursor-pointer" : "cursor-not-allowed")}
				onMouseMove={handleMouseMove}
				onMouseLeave={handleMouseLeave}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onClick={handleClick}
				ref={containerRef}
				{...debugDOMProps}
			>
				{timelineSegments.map((segment, index) => (
					<Timeline
						key={segment.attendeeId}
						segment={segment}
						index={index}
						totalDurationSeconds={totalDurationSeconds}
						verticalSpacing={verticalSpacing}
					/>
				))}
				{highlights !== "loading" && (
					<HighlightsTimeline
						currentHoverTimeSeconds={currentHoverTimeSeconds}
						currentTimeSeconds={currentTimeSeconds}
						highlights={highlights}
						totalDurationSeconds={totalDurationSeconds}
					/>
				)}
				{timeRangeState.type !== "range-confirmed" && (
					<TimelineCursor
						variant="default"
						currentTimeSeconds={currentTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
					/>
				)}
				{shouldHideTimelineActions || !user ? (
					timelineContent
				) : (
					<CreateCallTimeRangeForm
						createCallTimeRangeForm={timeRangeState}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
						currentTimeSeconds={currentTimeSeconds}
						onCreateCallTimeRangeFormReset={resetTimeRangeForm}
						onCreateCallHighlightRangeConfirm={() => {
							if (!workspace?.isPlanFeatureEnabled("library")) {
								return
							}
							if (timeRangeState.type === "range-dragged") {
								setCurrentHoverTimeSeconds(null)
								confirmTimeRange(timeRangeState, "create-call-highlight")
							}
						}}
						onShareCallRangeConfirm={() => {
							if (timeRangeState.type === "range-dragged") {
								setCurrentHoverTimeSeconds(null)
								confirmTimeRange(timeRangeState, "share-call")
							}
						}}
						onCallHighlightMetadataSubmit={createCallHighlight}
					>
						{timelineContent}
					</CreateCallTimeRangeForm>
				)}
			</div>
		</div>
	)
}

const calculateSegmentWidth = (segment: TimelineSegment["segments"][number], totalDurationSeconds: number) => {
	const width = ((segment.endTimeSec - segment.startTimeSec) / totalDurationSeconds) * 100
	return width
}

const calculateSegmentOffset = (segment: TimelineSegment["segments"][number], totalDurationSeconds: number) => {
	return (segment.startTimeSec / totalDurationSeconds) * 100
}

function isSegmentInAllowedRanges(
	segment: TimelineSegment["segments"][number],
	timeRanges: CallTimeRange[] | undefined,
) {
	if (!timeRanges) {
		return true
	}

	return timeRanges.some((range) => segment.startTimeSec >= range.startSec && segment.endTimeSec <= range.endSec)
}

function Timeline({
	segment,
	index,
	totalDurationSeconds,
	verticalSpacing,
}: {
	segment: TimelineSegment
	index: number
	totalDurationSeconds: number
	verticalSpacing: 2 | 4
}) {
	const totalTalkTime = Math.round(
		segment.segments.reduce((acc, segment) => acc + (segment.endTimeSec - segment.startTimeSec), 0),
	)

	const { allowedTimeRanges } = useCallVideoPlayer()
	const talkRatioPercentage = Math.round((totalTalkTime / totalDurationSeconds) * 100)
	const colorClasses = ["bg-blue-500", "bg-red-500", "bg-green-500", "bg-yellow-500", "bg-purple-500"]
	const colorClass = colorClasses[index % colorClasses.length]
	const segmentsToDisplay = segment.segments.filter((segment) => isSegmentInAllowedRanges(segment, allowedTimeRanges))
	const spacingClasses = {
		2: "my-2",
		4: "my-4",
	}

	return (
		<div
			key={segment.attendeeId}
			className={clsx(
				"space-y-1 select-none",
				spacingClasses[verticalSpacing],
				// Keep top padding on first timeline so that the timeline cursor duration doesn't overlap with the first speaker name
				index === 0 && "mt-4",
			)}
		>
			<div className="flex justify-between items-center text-gray-500 text-sm font-medium">
				<span>{segment.attendeeLabel}</span>
				{totalTalkTime > 0 && (
					<span>
						{formatDuration(totalTalkTime)} {isFinite(talkRatioPercentage) && `(${talkRatioPercentage}%)`}
					</span>
				)}
			</div>
			<div className="relative h-2 bg-gray-200 overflow-hidden rounded-full">
				{allowedTimeRanges?.map((range) => (
					<div
						key={`${range.startSec}-${range.endSec}`}
						className="absolute h-full bg-indigo-300 rounded-full opacity-50"
						style={{
							left: `${(range.startSec / totalDurationSeconds) * 100}%`,
							width: `${((range.endSec - range.startSec) / totalDurationSeconds) * 100}%`,
						}}
					/>
				))}
				{segmentsToDisplay.map((segment) => (
					<div
						key={`${segment.startTimeSec}-${segment.endTimeSec}`}
						className={`absolute h-full ${colorClass} rounded-full opacity-50`}
						style={{
							left: `${calculateSegmentOffset(segment, totalDurationSeconds)}%`,
							width: `calc(${calculateSegmentWidth(segment, totalDurationSeconds)}% + 1px)`, // Ensures at least 1px width
						}}
					/>
				))}
			</div>
		</div>
	)
}

function formatDuration(seconds: number) {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	let formattedDuration = ""

	if (hours > 0) {
		formattedDuration += `${hours}h`
	}
	if (minutes > 0 || hours > 0) {
		formattedDuration += `${minutes}m`
	}
	formattedDuration += `${Math.floor(seconds % 60)}s`
	return formattedDuration
}
