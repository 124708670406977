import clsx from "clsx"

import { Card } from "../../../../components/design-system/Card.component"

export function _PaddedCardWrapper({
	children,
	title,
	titleTooltip,
}: React.PropsWithChildren<{ title: string; titleTooltip?: string }>) {
	return (
		<_CardWrapper title={title} titleTooltip={titleTooltip}>
			<div className="text-center md:max-w-xl mx-auto py-4 md:py-12">{children}</div>
		</_CardWrapper>
	)
}

export function _CardWrapper({
	children,
	title,
	titleTooltip,
	className,
}: React.PropsWithChildren<{ title: string; titleTooltip?: string; className?: string }>) {
	return (
		<Card title={title} titleTooltip={titleTooltip} className={clsx("md:mx-6 my-6", className)}>
			{children}
		</Card>
	)
}
