import type { ReactNode } from "react"
import React, { createContext, useCallback, useContext, useState } from "react"
import { useInterval, useMount } from "react-use"
import { ZodError } from "zod"

import type { SignupParams } from "../../core/application/gateways/authentication.gateway"
import type { Subscription } from "../../core/domain/Subscription.entity"
import type { User } from "../../core/domain/User.entity"
import type { Workspace } from "../../core/domain/Workspace.entity"
import { PaymentRequiredError } from "../../core/infra/gateways/http.authentication.gateway/http.authentication.gateway"
import { useAnalytics } from "./analytics.context"
import { useDependencies } from "./dependencies.context"
import { useLanguage } from "./language.context"

type AuthenticationAuthenticatedState = {
	workspace: Workspace
	user: User
	subscription: Subscription | null
	isPlatformAdmin: boolean
}

type AuthenticationState = AuthenticationAuthenticatedState | "logged out" | "loading"

type IAuthenticationContextProps = {
	authenticationState: AuthenticationState
	signup: (signupParams: SignupParams) => Promise<void>
	login: (email: string, password: string) => Promise<void>
	logout: () => Promise<void>
	fetchMe: () => Promise<void>
}

const AuthenticationContext = createContext<IAuthenticationContextProps | undefined>(undefined)

type IAuthenticationProviderProps = {
	children: ReactNode
}

export const AuthenticationProvider: React.FC<IAuthenticationProviderProps> = ({ children }) => {
	const { t } = useLanguage()
	const { identifyUser: identify, logOut: triggerLogoutAnalyticsEvent } = useAnalytics()
	const { authenticationGateway } = useDependencies()
	const [authenticationState, setAuthenticationState] = useState<AuthenticationState>("loading")

	const fetchMe = useCallback(async () => {
		try {
			console.log("Fetching current session...")
			const { user, workspace, subscription } = await authenticationGateway.fetchMe()
			console.log("Session refreshed", user, workspace)

			// this condition is checked in the backend when checking for platform admin permissions
			const isPlatformAdmin = workspace.isRippletideWorkspace()
			setAuthenticationState({ user, workspace, isPlatformAdmin, subscription })
			identify(user, workspace)
		} catch (error) {
			console.debug("Error fetching current session", error)
			if (error instanceof PaymentRequiredError) {
				alert(t("Payment required, account suspended"))
			}
			if (error instanceof ZodError) {
				console.error("ZodError!", error)
			}
			setAuthenticationState("logged out")
			triggerLogoutAnalyticsEvent()
		}
	}, [authenticationGateway, identify, triggerLogoutAnalyticsEvent, t])

	const signup = useCallback(
		async (signupParams: SignupParams) => {
			await authenticationGateway.signup(signupParams)
			await fetchMe()
		},
		[authenticationGateway, fetchMe],
	)

	const login = useCallback(
		async (email: string, password: string) => {
			await authenticationGateway.login(email, password)
			await fetchMe()
		},
		[authenticationGateway, fetchMe],
	)

	const logout = useCallback(async () => {
		await authenticationGateway.logout()
		setAuthenticationState("logged out")
		triggerLogoutAnalyticsEvent()
	}, [authenticationGateway, triggerLogoutAnalyticsEvent])

	useMount(fetchMe)
	useInterval(fetchMe, 1000 * 60 * 5) // 5mins

	return (
		<AuthenticationContext.Provider value={{ authenticationState, login, logout, fetchMe, signup }}>
			{children}
		</AuthenticationContext.Provider>
	)
}

export function useAuthentication(): IAuthenticationContextProps {
	const context = useContext(AuthenticationContext)
	if (!context) {
		throw new Error("useAuthentication must be used within an AuthenticationProvider")
	}
	return context
}

export function isAuthenticationAuthenticatedState(
	authenticationState: AuthenticationState,
): authenticationState is AuthenticationAuthenticatedState {
	return typeof authenticationState !== "string"
}

export function useAuthenticatedSession() {
	const { authenticationState, login, logout, fetchMe } = useAuthentication()
	if (!isAuthenticationAuthenticatedState(authenticationState)) {
		throw new Error("useAuthenticatedSession must be used within an authenticated session context")
	}

	return {
		workspace: authenticationState.workspace,
		user: authenticationState.user,
		isPlatformAdmin: authenticationState.isPlatformAdmin,
		subscription: authenticationState.subscription,
		login: login,
		logout: logout,
		refetchMe: fetchMe,
	}
}

export function useSession() {
	const { authenticationState, login, logout, fetchMe } = useAuthentication()
	if (authenticationState === "logged out" || authenticationState === "loading") {
		return {
			workspace: undefined,
			user: undefined,
			isPlatformAdmin: false,
			subscription: undefined,
			login,
			logout,
			fetchMe,
		}
	}

	return {
		workspace: authenticationState.workspace,
		user: authenticationState.user,
		isPlatformAdmin: authenticationState.isPlatformAdmin,
		subscription: authenticationState.subscription,
		login,
		logout,
		fetchMe,
	}
}
