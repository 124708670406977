import { PlayIcon } from "@heroicons/react/24/outline"
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import Highlighter from "react-highlight-words"
import { Trans } from "react-i18next"

import type { BaseCall } from "../../../../../core/domain/BaseCall.entity"
import type { Call } from "../../../../../core/domain/Call.entity"
import { Duration } from "../../../../components/design-system/Duration.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useCallVideoPlayer } from "../../../../contexts/callVideoPlayer.context"
import { useLanguage } from "../../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../../utils/time"

export type _TranscriptionPanelProps = {
	call: BaseCall
}

export function _TranscriptionPanel({ call }: _TranscriptionPanelProps) {
	const transcription = call.props.transcription
	if (!transcription) {
		return null
	}

	return <TranscriptionRenderer transcription={transcription} />
}

type TranscriptionRendererProps = {
	transcription: NonNullable<Call["props"]["transcription"]>
}

function useTranscriptionSearch(transcriptionTurns: NonNullable<Call["props"]["transcription"]>["turns"]) {
	const [search, setSearch] = useState("")
	const [searchMode, setSearchMode] = useState<"hide-non-matching" | "show-all">("hide-non-matching")

	const filteredTurns = search
		? transcriptionTurns.filter((turn) => turn.content.toLowerCase().includes(search.trim().toLowerCase()))
		: transcriptionTurns

	const handleSearch = (newSearch: string) => {
		setSearch(newSearch)
		setSearchMode("hide-non-matching")
	}

	return {
		search,
		filteredTurns: searchMode === "hide-non-matching" ? filteredTurns : transcriptionTurns,
		setSearch: handleSearch,
		searchMode,
		setSearchMode,
		matchingResultCount: filteredTurns.length,
	}
}

/**
 * Scroll events are fired both when user scrolls and when we trigger a scroll
 * In order to know who initiated a scroll, this variable holds true when we programmatically initiated a scroll
 */
let scrollExpected = false

function TranscriptionRenderer({ transcription }: TranscriptionRendererProps) {
	const { seekPlayerTo, allowedTimeRanges, currentTimeSeconds } = useCallVideoPlayer()
	const [searchFocusedTurnIdx, setSearchFocusedTurnIdx] = useState<number | null>(null)

	const { t } = useLanguage()
	const transcriptionTurnsToDisplay = allowedTimeRanges
		? transcription.turns.filter((turn) =>
				allowedTimeRanges.some(
					(range) => range.startSec <= turn.startTimeSec && turn.endTimeSec <= range.endSec,
				),
		  )
		: transcription.turns

	const { search, filteredTurns, setSearch, searchMode, setSearchMode, matchingResultCount } =
		useTranscriptionSearch(transcriptionTurnsToDisplay)

	const [scrollMode, setScrollMode] = useState<"auto" | "manual">("auto")
	const [currentTurnDirection, setCurrentTurnDirection] = useState<"above" | "below" | undefined>()
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const currentTurnRef = useRef<HTMLDivElement>(null)

	const handleSeekToTurnClicked = (seconds: number) => {
		seekPlayerTo(seconds, "transcription")
		setScrollMode("auto")
	}

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current

		if (searchFocusedTurnIdx === null || !scrollContainer || searchMode === "hide-non-matching") return

		const currentTurn = scrollContainer.children[0]?.children[searchFocusedTurnIdx]
		const firstTurn = scrollContainer.children[0]?.children[0]

		if (!currentTurn || !firstTurn) return

		const { targetScroll } = getScrollPositions(scrollContainerRef.current, firstTurn, currentTurn)

		scrollContainerRef.current.scrollTo({
			behavior: "instant",
			top: targetScroll,
		})
		setSearchFocusedTurnIdx(null)
	}, [searchFocusedTurnIdx, searchMode])

	const handleSearchToTurnClicked = (seconds: number) => {
		if (!search) {
			return
		}

		const turnIndex = transcription.turns.findIndex(
			(turn) => turn.startTimeSec <= seconds && seconds <= turn.endTimeSec,
		)

		if (turnIndex === -1) return

		setSearchMode("show-all")
		setTimeout(() => setSearchFocusedTurnIdx(turnIndex), 50) // hack to wait for the scroll to be updated
	}

	const dateFormatter = new DateFormatter(new DateProvider(), t)

	const currentTurnIndex = getTurnIndexAtTime(transcription, currentTimeSeconds)

	// Switch to manual mode when user scrolls
	useEffect(() => {
		const handleUserScroll = () => {
			if (scrollExpected) {
				// scroll was initiated by us, not by user
				scrollExpected = false
				return
			}
			setScrollMode("manual")
		}

		const scrollContainer = scrollContainerRef.current
		scrollContainer?.addEventListener("scroll", handleUserScroll)

		return () => {
			scrollContainer?.removeEventListener("scroll", handleUserScroll)
		}
	}, [scrollMode])

	// Keep 'currentTurnDirection' up to date
	useEffect(() => {
		const handleScroll = () => {
			if (!scrollContainerRef.current) return
			if (!currentTurnRef.current) return

			const firstTurn = currentTurnRef.current.parentElement?.children[0]
			if (!firstTurn) return

			const { currentScroll, targetScroll } = getScrollPositions(
				scrollContainerRef.current,
				firstTurn,
				currentTurnRef.current,
			)

			setCurrentTurnDirection(currentScroll > targetScroll ? "above" : "below")
		}

		const scrollContainer = scrollContainerRef.current
		scrollContainer?.addEventListener("scroll", handleScroll)

		return () => {
			scrollContainer?.removeEventListener("scroll", handleScroll)
		}
	}, [scrollMode])

	// Scroll to current turn when in auto mode
	useEffect(() => {
		if (search) return
		if (scrollMode === "manual") return
		scrollToCurrentTurn()
	}, [currentTurnIndex, scrollMode, search])

	const scrollToCurrentTurn = () => {
		if (!scrollContainerRef.current) return
		if (!currentTurnRef.current) return

		const firstTurn = currentTurnRef.current.parentElement?.children[0]
		if (!firstTurn) return

		setScrollMode("auto")
		scrollExpected = true

		const { targetScroll } = getScrollPositions(scrollContainerRef.current, firstTurn, currentTurnRef.current)

		scrollContainerRef.current.scrollTo({
			/**
			 * Why "instant":
			 *   - Product decision: do like modjo does
			 *   - Tech decision:
			 *       - With instant scroll, only one scroll event is fired
			 *       - With smooth scroll, I guess many scroll events are fired, making it difficult to know who initiated the scroll (user or us)
			 */
			behavior: "instant",
			top: targetScroll,
		})
	}

	return (
		<div className="pr-4">
			<div className="mb-4">
				<input
					id="search"
					name="search"
					type="search"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder={t("Search in transcript")}
					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
				/>
				{search && (
					<div className="py-1">
						<Trans
							i18nKey="searchTranscriptResult"
							values={{ a: matchingResultCount, b: search }}
							components={{
								1: <span className="font-semibold" />,
							}}
							count={filteredTurns.length}
						/>
					</div>
				)}
			</div>
			<div className="relative mb-4">
				<div ref={scrollContainerRef} className="overflow-y-auto">
					<div className="divide-y divide-gray-200 bg-white">
						{filteredTurns.map(
							({ attendeeId, attendeeLabel, content, startTimeSec, endTimeSec }, index) => {
								const shouldHighlight = index === currentTurnIndex && !search
								const className = shouldHighlight ? "py-4 bg-gray-100" : "py-4"

								const isCurrentTurn = index === (currentTurnIndex ?? 0) && !search
								return (
									<div
										key={index + attendeeId + content.slice(1, 10)}
										className={clsx(className, search && "hover:bg-gray-50 cursor-pointer")}
										ref={isCurrentTurn ? currentTurnRef : undefined}
										onClick={() => handleSearchToTurnClicked(startTimeSec)}
									>
										<div className="text-sm font-medium text-gray-900 flex items-center">
											<TrackingButton
												onClick={() => handleSeekToTurnClicked(startTimeSec)}
												className="bg-gray-100 text-indigo-600 p-1.5 rounded-sm inline-flex text-xs mr-2 gap-1.5"
												eventName="Seek video player to transcription turn"
												eventProperties={{
													seekPlayerToSeconds: startTimeSec,
												}}
											>
												<PlayIcon className="w-4 h-4" />
												<span>
													<Duration className="cursor-pointer" seconds={startTimeSec} /> -{" "}
													<Duration seconds={endTimeSec} />
												</span>
											</TrackingButton>
											{attendeeLabel}
										</div>
										<div className="mt-2 text-sm text-gray-500">
											<Highlighter
												highlightClassName="font-bold"
												searchWords={[search]}
												autoEscape={true}
												textToHighlight={content}
											/>
										</div>
									</div>
								)
							},
						)}
					</div>
					{scrollMode === "manual" && !search && (
						<button
							className="absolute bottom-4 right-6 z-40 flex items-center gap-x-1.5 rounded-lg bg-black px-3 py-2 text-xs font-bold text-white"
							onClick={scrollToCurrentTurn}
						>
							{currentTurnDirection === "above" && <ArrowUpIcon className="h-4 w-4" aria-hidden="true" />}
							{currentTurnDirection === "below" && (
								<ArrowDownIcon className="h-4 w-4" aria-hidden="true" />
							)}
							{t("Scroll to")} {dateFormatter.formatTime(currentTimeSeconds)}
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

/**
 * Return the turn index at a given time
 *
 * The blank space between a turn and its successor is considered as belonging to the turn
 */
function getTurnIndexAtTime(
	transcription: TranscriptionRendererProps["transcription"],
	timeSec: number,
): number | undefined {
	for (let i = 0; i < transcription.turns.length; i++) {
		const current = transcription.turns[i]
		const next = transcription.turns[i + 1]

		if (current.startTimeSec > timeSec) return undefined
		if (current.startTimeSec <= timeSec && current.endTimeSec >= timeSec) return i
		if (!next) return i
		if (next.startTimeSec > timeSec) return i
	}
}

/**
 * @param container Scrollable div that contains all turns
 */
function getScrollPositions(container: Element, firstTurn: Element, currentTurn: Element) {
	const currentScroll = container.scrollTop

	const firstTurnScroll = firstTurn.getBoundingClientRect().top
	const currentTurnScroll = currentTurn.getBoundingClientRect().top
	const currentTurnOffset = currentTurnScroll - firstTurnScroll

	const currentTurnHeight = currentTurn.getBoundingClientRect().height
	const halfOfContainerHeight = container.getBoundingClientRect().height / 2

	/**
	 * | If we scroll to                                                   | Then                                                     |   |
	 * |-------------------------------------------------------------------|----------------------------------------------------------|---|
	 * | currentTurnOffset                                                 | Current turn's top is aligned with container's top       |   |
	 * | currentTurnOffset - halfOfContainerHeight                         | Current turn's top is aligned with container's center    |   |
	 * | currentTurnOffset - halfOfContainerHeight + currentTurnHeight / 2 | Current turn's center is aligned with container's center |   |
	 */
	const targetScroll = currentTurnOffset - halfOfContainerHeight + currentTurnHeight / 2

	return { currentScroll, targetScroll }
}
