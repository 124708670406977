import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ArrowRightOnRectangleIcon, EllipsisVerticalIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React, { Fragment } from "react"
import { useSearchParams } from "react-router-dom"

import type { ICalendarEventUpdateBotQuery } from "../../../../../core/application/gateways/calendar-events.gateway"
import { type CalendarEvent, getRecordingStatusDetails } from "../../../../../core/domain/CalendarEvent.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { Modal } from "../../../../components/design-system/Modal.component"
import { StatusBadge } from "../../../../components/design-system/StatusBadge.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { BriefRenderer } from "../../../../components/shared/BriefRenderer.component"
import { useLanguage } from "../../../../contexts/language.context"
import { makeDemoCallPath } from "../../../../router/Router"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { useCalendarEventBriefing } from "./useCalendarEventBriefing"

type ICalendarEventCardProps = {
	event: CalendarEvent
	handleCalendarEventAction: (calendarEventId: string, eventAction: ICalendarEventUpdateBotQuery["action"]) => void
}

const briefQueryParamName = "openBriefOfEventId"

export function CalendarEventCard({ event, handleCalendarEventAction }: ICalendarEventCardProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const { isBriefingAlreadyRead, markBriefingAsRead } = useCalendarEventBriefing(event)
	const [searchParams, setSearchParams] = useSearchParams()
	const isBriefingModalOpen = searchParams.get(briefQueryParamName) === event.id

	const now = dateProvider.now()
	const { message, isDefinitive, isSuccess, isError, errorDescription } = getRecordingStatusDetails(
		event.botStatus.code,
		{
			now,
			botWillJoinAt: event.botStatus.botWillJoinAt,
			eventStartTime: event.startTime,
			eventHasEnded: event.endTime < now,
		},
	)
	if (isError) {
		console.error("Event", event.title, "has internal error because:", errorDescription)
	}

	let badgeVariant: "error" | "neutral" | "info" | "success" = "info"
	if (isError) {
		badgeVariant = "error"
	} else if (isDefinitive) {
		badgeVariant = "neutral"
	} else if (isSuccess) {
		badgeVariant = "success"
	}

	const { timeUntilStartLabel, startDateLabel, endDateLabel } = dateFormatter.getFormattedDateRange(
		event.startTime,
		event.endTime,
		language,
	)

	const handleOpenBriefingModal = React.useCallback(() => {
		const newSearchParams = new URLSearchParams(searchParams)
		newSearchParams.set(briefQueryParamName, event.id)
		setSearchParams(newSearchParams)
	}, [event.id, searchParams, setSearchParams])

	const handleCloseBriefingModal = React.useCallback(() => {
		markBriefingAsRead()

		const newSearchParams = new URLSearchParams(searchParams)
		newSearchParams.delete(briefQueryParamName)
		setSearchParams(newSearchParams)
	}, [markBriefingAsRead, searchParams, setSearchParams])

	React.useEffect(() => {
		const eventIdOfBriefingToOpen = searchParams.get(briefQueryParamName)
		if (eventIdOfBriefingToOpen === event.id) {
			handleOpenBriefingModal()
		}
	}, [event.id, handleOpenBriefingModal, searchParams, setSearchParams])

	return (
		<>
			{event.briefing && (
				<Modal open={isBriefingModalOpen} onClose={handleCloseBriefingModal}>
					<BriefRenderer briefing={event.briefing} />
					{!isBriefingAlreadyRead && (
						<div className="flex justify-end mt-4">
							<TrackingButton
								eventName="Mark briefing as read clicked"
								className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
								onClick={handleCloseBriefingModal}
							>
								<span className="text-s text-gray-500">{t("Mark as read")}</span>
							</TrackingButton>
						</div>
					)}
				</Modal>
			)}

			<li key={event.id} className="relative py-5 pl-4 pr-6 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6">
				<div className="flex sm:flex-row flex-col sm:items-center items-start justify-between space-x-0 sm:space-x-4">
					<div className="min-w-0 space-y-3">
						<div className="flex items-center space-x-3">
							{event.isDemo ? (
								<TrackingLink
									to={makeDemoCallPath()}
									eventName="Dashboard: demo event meeting clicked"
									className="text-navy-500 hover:text-navy-700"
								>
									<span className="flex items-center justify-center">
										<Badge label={t("Example call")} className="mr-2" variant="info" />
										<h2 className="text-sm font-medium">{event.title}</h2>
									</span>
								</TrackingLink>
							) : (
								<TrackingExternalHref
									href={event.meetingUrl}
									className="text-sm"
									eventName="Dashboard: open meeting URL"
								>
									<h2 className="text-sm font-medium max-w-[250px] sm:max-w-full truncate">
										{event.title}
									</h2>
								</TrackingExternalHref>
							)}
						</div>
						<div className="group relative flex items-center space-x-2.5 text-sm text-gray-500">
							<span className="font-medium">
								{startDateLabel} - {endDateLabel}
							</span>
							<span className="hidden sm:block">&middot;</span>
							<span className="hidden sm:block">{timeUntilStartLabel}</span>

							{event.briefing && (
								<TrackingButton
									onClick={handleOpenBriefingModal}
									eventName="Briefing clicked"
									eventProperties={{
										isBriefingAlreadyRead,
									}}
								>
									<Badge
										label={
											<span className="flex items-center gap-1">
												{!isBriefingAlreadyRead && (
													<span className="w-1 h-1 bg-emerald-400 rounded-full animate-ping" />
												)}
												{t(isBriefingAlreadyRead ? "View brief" : "Brief available")}
												<ArrowRightOnRectangleIcon className="w-3 h-3" />
											</span>
										}
										className="hover:bg-emerald-100 hover:text-emerald-700"
										variant="success"
									/>
								</TrackingButton>
							)}
						</div>
					</div>
					<div className="flex-shrink-0 sm:flex-col items-end space-y-3 flex flex-1 w-full sm:w-auto flex-row-reverse justify-between">
						<p className="flex items-center space-x-4">
							<div className="flex flex-shrink-0 self-center">
								{event.botStatus.canSendBotToMeetingNow && (
									<Menu as="div" className="relative inline-block text-left">
										<div>
											<MenuButton className="relative -m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
												<span className="absolute -inset-1" />
												<span className="sr-only">Open options</span>
												<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
											</MenuButton>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
												<div className="py-1">
													<MenuItem>
														{({ active }) => (
															<TrackingButton
																type="button"
																className={clsx(
																	active
																		? "bg-gray-100 text-gray-900"
																		: "text-gray-700",
																	"flex px-4 py-2 text-sm w-full",
																)}
																onClick={() =>
																	handleCalendarEventAction(
																		event.id,
																		"send-bot-to-meeting-now",
																	)
																}
																eventName="Send bot to meeting now"
															>
																<PaperAirplaneIcon
																	className="mr-3 h-5 w-5 text-gray-400"
																	aria-hidden="true"
																/>
																<span>{t("Send bot now")}</span>
															</TrackingButton>
														)}
													</MenuItem>
												</div>
											</MenuItems>
										</Transition>
									</Menu>
								)}
							</div>
						</p>
						<p className="flex space-x-2 text-sm text-gray-500">
							<span className="flex items-center">
								<StatusBadge variant={badgeVariant} className="mr-2" />
								{t(message)}
							</span>
						</p>
					</div>
				</div>
			</li>
		</>
	)
}
