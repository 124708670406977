import { Trans } from "react-i18next"

import { TrackingButton } from "../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../components/design-system/TrackingExternalHref.component"
import { TrackingLink } from "../../components/design-system/TrackingLink.component"
import { LoginSignupLayout } from "../../components/shared/Layout/LoginSignupLayout.component"
import { useAuthentication } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { makePublicWebsiteUrl } from "../../public-website"
import { makeSignupPath } from "../../router/Router"

export function LoginPage() {
	const { login, authenticationState } = useAuthentication()
	const { t } = useLanguage()

	const handleLoginFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const email = event.currentTarget.email.value
		const password = event.currentTarget.password.value

		if (typeof email !== "string" || typeof password !== "string") {
			alert(t("Invalid email or password"))
			return
		}

		try {
			await login(email, password)
			console.log(t("Login successful"), authenticationState)
		} catch (error) {
			console.error("Error:", error)
			alert(t("Invalid email or password"))
		}
	}

	return (
		<LoginSignupLayout source="login">
			<h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-navy">
				{t("Sign in to your account")}
			</h2>
			<div className="mt-10 ">
				<LoginForm handleLoginFormSubmit={handleLoginFormSubmit} />
				<div className="mt-4 text-sm text-center text-gray-500 md:text-right flex flex-col md:flex-row justify-center">
					<Trans
						i18nKey="By signing in, you agree to our <1>Terms of Service</1>"
						components={{
							1: (
								<TrackingExternalHref
									href={makePublicWebsiteUrl("/terms-of-service")}
									target="_blank"
									className="text-center md:text-right whitespace-nowrap ml-1"
									eventName="Login: see terms of service"
								/>
							),
						}}
					/>
				</div>
				<div className="mt-4 text-sm text-center text-gray-500 md:text-right flex flex-col md:flex-row justify-center items-center">
					<Trans
						i18nKey="Don't have an account yet? <1>Create one</1>"
						components={{
							1: (
								<TrackingLink
									className="flex items-center justify-center ml-2 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-navy-500 shadow-sm ring-1 ring-inset ring-navy-500 hover:bg-navy-500 hover:ring-navy-500 hover:text-white transition-all"
									eventName={"Login: switch to signup form"}
									to={makeSignupPath()}
								/>
							),
						}}
					/>
				</div>
			</div>
		</LoginSignupLayout>
	)
}

type LoginFormProps = {
	handleLoginFormSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}

function LoginForm(props: LoginFormProps) {
	const { t } = useLanguage()

	return (
		<form className="space-y-6" onSubmit={props.handleLoginFormSubmit}>
			<div>
				<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Email address")}
				</label>
				<div className="mt-2">
					<input
						id="email"
						name="email"
						type="email"
						autoComplete="email"
						required
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
			<div>
				<div className="flex items-center justify-between">
					<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Password")}
					</label>
					{/* <div className="text-sm">
									<a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
										Forgot password?
									</a>
								</div> */}
				</div>
				<div className="mt-2">
					<input
						id="password"
						name="password"
						type="password"
						autoComplete="current-password"
						required
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<TrackingButton
					eventName="User logged in form submitted"
					type="submit"
					className="flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
				>
					{t("Sign in")}
				</TrackingButton>
			</div>
		</form>
	)
}
