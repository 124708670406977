import clsx from "clsx"
import React from "react"

import type { Relationship } from "../../../../core/application/gateways/relationships.gateway"
import type { Call } from "../../../../core/domain/Call.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { CallActivityTimeline } from "../../../pages/Deals/components/CallActivityTimeline.component"
import { formatCurrency } from "../../../pages/Deals/utils"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"
import { RelationshipCallActivityPanel } from "./panels/RelationshipCallActivityPanel.component"
import { RelationshipInfoPanel } from "./panels/RelationshipInfoPanel.component"
import { RelationshipOpportunityPanel } from "./panels/RelationshipOpportunityPanel.component"
import { RelationshipRisksPanel } from "./panels/RelationshipRisksPanel.component"
import RelationshipPanelMenu, { type RelationshipMenuTab } from "./RelationshipPanelMenu.component"

type RelationshipPanelContentProps = {
	relationship: Relationship
	defaultTabToOpen?: "risks" | "call-activity" | null
	currentCall?: Call
	shouldOpenTimelineCallInNewTab?: boolean
	originPage: "call" | "deal"
	className?: string
}

export function RelationshipPanelContent({
	relationship,
	defaultTabToOpen,
	currentCall,
	shouldOpenTimelineCallInNewTab,
	originPage,
	className,
}: RelationshipPanelContentProps) {
	const [currentTab, setCurrentTab] = React.useState<RelationshipMenuTab>(defaultTabToOpen ?? "info")
	const { workspace } = useAuthenticatedSession()
	const opportunityUrl =
		workspace.crmIntegration?.instanceUrl && relationship.opportunity?.crmId
			? `${workspace.crmIntegration.instanceUrl}${relationship.opportunity.crmId}`
			: null

	const accountUrl =
		workspace.crmIntegration?.instanceUrl && relationship.account?.crmId
			? `${workspace.crmIntegration.instanceUrl}${relationship.account.crmId}`
			: null

	const divider = (
		<div className="flex flex-col items-center justify-center">
			<span className="h-1 w-1 bg-gray-300 rounded-full" />
		</div>
	)

	return (
		<div className={clsx("max-h-full", className)}>
			{relationship.opportunity && (
				<>
					{opportunityUrl ? (
						<TrackingExternalHref
							href={opportunityUrl}
							eventName="Relationship side panel: opportunity click"
							className={clsx("!whitespace-normal", originPage === "call" ? "text-lg" : "text-xl")}
						>
							{relationship.opportunity.name}
						</TrackingExternalHref>
					) : (
						<h2 className="text-xl text-navy-700">{relationship.opportunity.name}</h2>
					)}
					<div className="flex gap-2 mt-2">
						{accountUrl ? (
							<TrackingExternalHref
								href={accountUrl}
								eventName="Relationship side panel: account click"
								className="text-md text-gray-900"
							>
								{relationship.account?.name}
							</TrackingExternalHref>
						) : (
							<h2 className="text-md font-semibold text-gray-900">{relationship.account?.name}</h2>
						)}
						{divider}
						{relationship.opportunity.amount && (
							<>
								<span className="text-md text-gray-900">
									{formatCurrency(relationship.opportunity.amount)}
								</span>
								{divider}
							</>
						)}
						{relationship.opportunity.closeDate && (
							<>
								<span className="text-md text-gray-900">
									{relationship.opportunity.closeDate?.toLocaleDateString()}
								</span>
								{divider}
							</>
						)}
						<span className="text-md text-gray-900">{relationship.opportunity.probability}%</span>
					</div>
				</>
			)}
			<div className="mt-4 flex items-center justify-center">
				<CallActivityTimeline
					activities={relationship.activities}
					heightPx={80}
					currentCall={currentCall}
					shouldOpenCallInNewTab={shouldOpenTimelineCallInNewTab}
					onSeePreviousCallsClick={() => setCurrentTab("call-activity")}
				/>
			</div>
			<RelationshipPanelMenu
				setCurrentTab={setCurrentTab}
				currentTab={currentTab}
				risksCount={relationship.risks?.length ?? 0}
			/>
			<div className={clsx("overflow-y-auto")}>
				{currentTab === "info" && <RelationshipInfoPanel relationship={relationship} />}
				{currentTab === "opportunity" && <RelationshipOpportunityPanel relationship={relationship} />}
				{currentTab === "call-activity" && (
					<RelationshipCallActivityPanel relationship={relationship} currentCall={currentCall} />
				)}
				{currentTab === "risks" && <RelationshipRisksPanel relationship={relationship} />}
			</div>
		</div>
	)
}
