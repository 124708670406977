import { CalendarIcon } from "@heroicons/react/24/outline"
import { Trans } from "react-i18next"

import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { CalendarSyncButtons } from "../../../../components/shared/CalendarSyncButtons.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useCalendarContext } from "../../../../contexts/calendar.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { makeWorkspaceBillingPath } from "../../../Settings/config"

type IEmptyProspectingScheduleProps = {
	dateRange: "today" | "upcoming-days"
}

export function EmptyProspectingSchedule({ dateRange }: IEmptyProspectingScheduleProps) {
	const { calendarAndEvents } = useCalendarContext()
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const isCalendarIntegrationEnabled = workspace.isPlanFeatureEnabled("calendar-integration")
	const isCoachEnabled = workspace.isPlanFeatureEnabled("rippletide-coach")

	if (!calendarAndEvents.data) {
		return null
	}

	const { calendar } = calendarAndEvents.data
	const title =
		dateRange === "today"
			? t("Your prospecting schedule is clear for today.")
			: t("You have no prospecting calls scheduled in the coming days.")

	if (calendar.status === "not-found") {
		return (
			<>
				<CalendarIcon className="mx-auto h-8 w-8 text-gray-500" aria-hidden="true" />
				<p className="font-medium mt-2 text-gray-500">{title}</p>
				{isCalendarIntegrationEnabled && isCoachEnabled ? (
					<>
						<p className="mt-2 text-gray-500">
							<Trans
								i18nKey="Invite <1>{{email}}</1> to any meeting to schedule Rippletide to join the call and have it appear on your dashboard."
								components={{
									1: <span className="text-sky font-semibold">coach@rippletide.com</span>,
								}}
								values={{ email: "coach@rippletide.com" }}
							/>
						</p>
						<p className="my-2 text-gray-500">
							{t("Alternatively, sync your calendar now to automate this process:")}
						</p>
						<CalendarSyncButtons calendar={calendar} />
					</>
				) : (
					<>
						<p className="mt-2 text-gray-500">
							{t(
								"Upgrade your plan now to start recording your meetings and unlocking powerful insights.",
							)}
						</p>
						<TrackingLink
							to={makeWorkspaceBillingPath()}
							eventName="empty prospecting schedule: upgrade plan"
							className="mx-auto max-w-[240px] mt-2 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
						>
							{t("Upgrade my plan")}
						</TrackingLink>
					</>
				)}
			</>
		)
	}

	const mainContentTranslationKey: TranslationKey =
		dateRange === "today"
			? "Schedule a call in your calendar today for it to appear on your dashboard."
			: "Schedule a call in your calendar in the next 2 days for it to appear on your dashboard."

	return (
		<>
			<CalendarIcon className="mx-auto h-8 w-8 text-gray-500" aria-hidden="true" />
			<p className="font-medium mt-2 text-gray-500">{title}</p>
			<p className="mt-2 text-gray-500">
				<Trans
					i18nKey={mainContentTranslationKey}
					components={{
						1: <span className="text-sky font-semibold">coach@rippletide.com</span>,
					}}
					values={{ email: "coach@rippletide.com" }}
				/>
			</p>
		</>
	)
}
