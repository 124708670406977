import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"

import { type Call } from "../../../../core/domain/Call.entity"
import { useLanguage } from "../../../contexts/language.context"
import { SpinnerIcon } from "../../design-system/SpinnerIcon.component"
import { ScoreBadge } from "../ScoreBadge.component"

export type _AnalysisCellProps = {
	call: Call
	className?: string
}

export function _AnalysisCell({ call, className }: _AnalysisCellProps) {
	const { t } = useLanguage()

	const score = call.props?.totalScore ?? null
	const maxScore = call.props?.totalMaxScore ?? null
	const scorePercentage = typeof score === "number" && typeof maxScore === "number" ? score / maxScore : null
	const badgeType =
		scorePercentage === null ? null : scorePercentage >= 0.75 ? "good" : scorePercentage >= 0.5 ? "neutral" : "bad"
	const scoreLabel = typeof score === "number" && typeof maxScore === "number" ? `${score}/${maxScore}` : null

	if (badgeType && scoreLabel) {
		return <ScoreBadge score={score} maxScore={maxScore} className={className} />
	}

	if (call.props.unprocessableReason) {
		return (
			<ExclamationTriangleIcon className="w-7 h-7 text-gray-700" title={call.getUnprocessableReasonString(t)} />
		)
	}

	if (!badgeType || !scoreLabel) {
		return <SpinnerIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
	}

	return null
}
