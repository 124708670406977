import type { IWorkspacesGateway, WorkspacePreferences } from "../../application/gateways/workspaces.gateway"

export class HttpWorkspacesGateway implements IWorkspacesGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async updatePreferences(preferences: Partial<WorkspacePreferences>): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/workspaces/manager/update-preferences`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(preferences),
		})
		if (!res.ok) {
			throw new Error("Failed to update preferences")
		}
	}
}
