import { z } from "zod"

import { subscriptionPropertiesSchema } from "../../../domain/Subscription.entity"
import { userPropertiesSchema } from "../../../domain/User.entity"
import { crmIntegrationSchema } from "../../../domain/Workspace.entity"
import { ISODateTimeSchema } from "../ISODateTime"

const workspaceSchema = z.object({
	id: z.string(),
	name: z.string().optional(),
	crmIntegration: crmIntegrationSchema.optional(),
	featureFlags: z.object({
		callScoringAnalysis: z.boolean().optional(),
		displayFeatureAlternatives: z.boolean().optional(),
		forecasting: z.boolean().optional(),
	}),
	language: z.enum(["en", "fr", "es"]),
	plan: z
		.object({
			name: z.string(),
			features: z.record(z.boolean()),
		})
		.optional(),
	couponId: z.string().optional(),
	couponRedeemedAt: ISODateTimeSchema.optional(),
})

export const fetchMyWorkspaceSchema = z.object({
	workspace: workspaceSchema,
	user: userPropertiesSchema,
	subscription: subscriptionPropertiesSchema.nullish(),
})
