import type { Dispatch, SetStateAction } from "react"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

type UseQueryParamsStateReturnType<T> = [T, Dispatch<SetStateAction<T>>]

export const useQueryParamsState = <T>(param: string, initialState: T): UseQueryParamsStateReturnType<T> => {
	const location = useLocation()

	// State for managing the value derived from the query parameter
	const [value, setValue] = useState<T>(() => {
		if (typeof window === "undefined") return initialState

		// Parse query parameter value from the URL
		const { search } = window.location
		const searchParams = new URLSearchParams(search)
		const paramValue = searchParams.get(param)

		return paramValue !== null ? (tryParseJSON(paramValue) as T) : initialState
	})

	useEffect(() => {
		const currentSearchParams = new URLSearchParams(window.location.search)

		// Determine whether to JSON.stringify based on the type of value
		if (value !== null && value !== "") {
			if (typeof value === "object") {
				currentSearchParams.set(param, JSON.stringify(value))
			} else {
				currentSearchParams.set(param, String(value))
			}
		} else {
			// Remove the query parameter if the value is null or empty
			currentSearchParams.delete(param)
		}

		// Build the new URL with the updated search parameters
		const newSearchParams = currentSearchParams.toString()
		const newUrl = `${window.location.pathname}${newSearchParams ? `?${newSearchParams}` : ""}`

		// Update the browser's history without triggering a page reload
		window.history.replaceState(window.history.state, "", newUrl)
	}, [param, value, location.pathname])

	return [value, setValue]
}

// Helper function to safely parse JSON
function tryParseJSON<T>(value: string): T | string {
	try {
		return JSON.parse(value)
	} catch {
		return value
	}
}
