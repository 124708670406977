import assertNever from "assert-never"
import React from "react"

import type { Relationship } from "../../../../../core/application/gateways/relationships.gateway"
import { useLanguage } from "../../../../contexts/language.context"
import { Alert } from "../../../design-system/Alert.component"

type RelationshipRisksPanelProps = {
	relationship: Relationship
}

export function RelationshipRisksPanel({ relationship }: RelationshipRisksPanelProps) {
	const { t } = useLanguage()
	if (!relationship.risks?.length) {
		return (
			<div className="flex flex-col gap-2 mt-4">
				<Alert description={t("No risks have been identified for this deal")} variant="success" />
			</div>
		)
	}

	return (
		<div className="flex flex-col gap-2 mt-4">
			{relationship.risks.map((risk) => {
				if (risk === "not-moved-from-stage") {
					return (
						<Alert
							key={risk}
							title={t("This deal has not moved from the previous stage in a while")}
							description={
								<ul className="list-disc list-inside">
									<li>{t("Re-engage with the prospect to move the deal forward.")}</li>
									<li>{t("Update the deal in the CRM.")}</li>
								</ul>
							}
							variant="warning"
						/>
					)
				}

				if (risk === "close-date-in-past") {
					return (
						<Alert
							key={risk}
							title="Closing date in the past"
							description={
								<ul className="list-disc list-inside">
									<li>{t("Update the closing date of the deal in the CRM.")}</li>
									<li>{t("Engage with the prospect to close the deal as soon as possible.")}</li>
								</ul>
							}
							variant="warning"
						/>
					)
				}

				assertNever(risk)
			})}
		</div>
	)
}
