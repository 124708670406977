import React from "react"

import type { IUserUpdatePayload } from "../../../../../core/application/gateways/users.gateway"
import type { User } from "../../../../../core/domain/User.entity"
import { UserList } from "../../../../components/shared/UserList.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { UserWorkspaceRoleDropdown } from "./UserWorkspaceRoleDropdown.component"

export type AdminUsersListProps = {
	users: User[]
	onUserUpdate?: (user: User, updatePayload: IUserUpdatePayload) => void
}

export function AdminUsersList({ users, onUserUpdate }: AdminUsersListProps) {
	const { t, language } = useLanguage()

	const handleUserRoleChange = React.useCallback(
		(user: User, role: User["workspaceRole"]) => {
			onUserUpdate?.(user, { role })
		},
		[onUserUpdate],
	)
	const { user: currentUser } = useAuthenticatedSession()

	const makeActionContent = React.useCallback(
		(user: User) => {
			const dateFormatter = new DateFormatter(new DateProvider(), t)

			return (
				<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
					<span className="inline-flex items-center text-xs font-medium text-gray-900 gap-x-1">
						<UserWorkspaceRoleDropdown
							role={user.workspaceRole}
							onChange={(newRole) => handleUserRoleChange(user, newRole)}
							readonly={!onUserUpdate || !currentUser.isWorkspaceOwner()}
						/>
					</span>
					<p className="mt-1 text-xs leading-5 text-gray-500">
						{t("Last login:")}{" "}
						<span className="font-semibold">
							{user.lastLoginAt ? (
								<time
									dateTime={user.lastLoginAt.toISOString()}
									title={user.lastLoginAt.toLocaleString()}
								>
									{dateFormatter.formatToRelativeTimeLabel(user.lastLoginAt, language)}
								</time>
							) : (
								t("never")
							)}
						</span>
					</p>
				</div>
			)
		},
		[currentUser, handleUserRoleChange, language, onUserUpdate, t],
	)

	return <UserList users={users} actionMenuContent={makeActionContent} className="max-h-[400px]" />
}
