import { INTESCIA_WORKSPACE_ID, RIPPLETIDE_WORKSPACE_ID } from "../../config"
import type { User } from "../../core/domain/User.entity"
import { useAuthenticatedSession } from "../contexts/authentication.context"
import { useWorkspaceUsers } from "../contexts/workspace-users.context"

type Team = {
	memberIds: string[]
}

const teamsByWorkspaceId: Record<string, Team[]> = {
	[RIPPLETIDE_WORKSPACE_ID]: [
		{
			// Sales team
			memberIds: [
				"user_082d1909-4da2-4d99-8e28-86c41623f7b3", // Patrick
				"user_78e92fd5-996b-434d-8d54-90fd8d6d493f", // Gregory
				"user_2601ce9c-35b2-4bef-8830-3740add5830e", // Victor
				"user_6", // Georges
			],
		},
	],
	[INTESCIA_WORKSPACE_ID]: [
		{
			// Doubletrade team
			memberIds: [
				"user_10",
				"user_16",
				"user_a9f9b322-ac1e-445b-9481-7f1bcfa58715",
				"user_67bb92dd-8083-4d36-9523-9002dd5311d2",
				"user_8",
				"user_e5c6a315-e444-4ef2-b9f8-187fc32c4087",
				"user_71cbd0d6-e9f1-4ab5-b8a1-a24e8d235a0f",
				"user_46de6c40-89b3-4c53-a4c5-6ececb957ede",
				"user_17",
			],
		},
		{
			// Corporama team
			memberIds: [
				"user_94e594fa-0853-46ae-8be8-5cb34ebb2fee",
				"user_0edca039-8351-4766-99cf-b825dfff7bee",
				"user_f6588c13-862e-43f9-aae0-8486466a2591",
				"user_95e205d4-8820-4a2b-b4fd-4085afc5df29",
				"user_9b2d1b9f-76c1-49aa-a6c7-8c43754c0c2f",
				"user_22f81f2d-abc6-4dec-923a-c975ce9419ab",
				"user_bde90b9c-2f48-4536-8cda-f4d73b8e672d",
				"user_c9742304-4359-4468-8fa3-6eba268bd954",
			],
		},
		{
			// Edysis team
			memberIds: [
				"user_5402adce-415d-4cf2-8798-89183113296c",
				"user_accf759d-7311-4e84-b2b1-680c2981a234",
				"user_c24fa063-412d-43ae-8a2e-f65c530c6a1d",
				"user_0d9d6883-d6bf-4708-8837-451cfa860af1",
				"user_984efca1-254d-42e5-bc06-5ee23197e96d",
				"user_83798f75-ca46-422d-b5d6-511319c18754",
			],
		},
		// Intescia/Doubletrade CSM team
		{
			memberIds: [
				"user_8", // manager
				"user_057059fe-fe8f-4805-8cb1-d3916310f238", // manager
				"user_e9810856-6993-4a2a-a63b-629db9b8e18f",
				"user_c8269003-bc3b-4f03-b589-7c0b363a2d01",
				"user_3f43829b-6b43-4bdc-9991-a44b4a0f46e3",
				"user_a554b583-a61b-4466-b3eb-3766d12b765c",
				"user_45dcdf74-b293-4b41-a80f-5c2548b11ef0",
				"user_f025bd2f-ddc4-41cd-9bcf-7b9fc91842d1",
				"user_c403eb11-440a-463a-af69-05fe2c1b84b9",
				"user_83361ac7-c178-4798-80f4-253352f8128b",
				"user_b6748454-b43e-49e1-a8c3-18fcbac421fb",
			],
		},
		// Explore CSM team - Missing one/many managers?
		{
			memberIds: [
				"user_f934e2f8-1794-4b13-adb3-ad5967bda47c",
				"user_0fd1f378-3b13-4a88-9610-f1f4d3d6642e",
				"user_4dbdba3f-73e9-4334-84f6-99401526800e",
				"user_ab6abfd9-0e36-48fd-b634-ebba09b9f569",
				"user_ca73859a-6bd0-495b-99f3-27dbafca707b",
				"user_84b3e07f-2fcc-4964-831e-4b63f737cc56",
				"user_a5214029-4210-4add-a2a7-19f759992246",
				"user_49a4a9ac-c382-40cd-9023-06d83fb7de5c",
				"user_17176d97-7e78-4c4a-995a-91b4bd8c9f9e",
				"user_d3fcdd1a-3a17-4837-b7c7-86453f85b32e",
			],
		},
	],
}

type UseTeamManagement =
	| {
			visibleUsers: User[]
			isLoading: false
			listVisibleUsers: () => Promise<void>
			hasFullAccess: boolean
	  }
	| {
			isLoading: true
	  }

export function useTeamManagement(): UseTeamManagement {
	const { user, workspace } = useAuthenticatedSession()

	const {
		workspaceUsers,
		isLoading: isInitialLoading,
		listWorkspaceUsers: listOwnWorkspaceUsers,
	} = useWorkspaceUsers()

	if (isInitialLoading || !workspaceUsers) {
		return {
			isLoading: true,
		}
	}

	// when the user is a workspace owner, we display everything
	if (user.isWorkspaceOwner()) {
		return {
			isLoading: false,
			visibleUsers: workspaceUsers,
			hasFullAccess: true,
			listVisibleUsers: listOwnWorkspaceUsers,
		}
	}

	const workspaceTeams = teamsByWorkspaceId[workspace.id] ?? []
	const { managees, canSeeAllCalls } = filterManagedUsers(workspaceTeams, workspaceUsers, user)
	return {
		isLoading: false,
		visibleUsers: managees,
		hasFullAccess: canSeeAllCalls,
		listVisibleUsers: listOwnWorkspaceUsers,
	}
}

function filterManagedUsers(
	workspaceTeams: Team[],
	workspaceUsers: User[],
	managerUser: User,
): {
	canSeeAllCalls: boolean
	managees: User[]
} {
	const managerTeams = workspaceTeams.filter((team) => team.memberIds.includes(managerUser.id))

	// if the manager is not part of a team, we display all the workspace users (same as owners)
	if (!managerTeams.length) {
		return {
			canSeeAllCalls: true,
			managees: workspaceUsers,
		}
	}

	const usersManagedByManager = workspaceUsers.filter((user) => checkUserBelongsInTeams(user, managerTeams))
	return {
		canSeeAllCalls: false,
		managees: usersManagedByManager,
	}
}

function checkUserBelongsInTeams(user: User, teams: Team[]): boolean {
	return teams.some((team) => team.memberIds.includes(user.id))
}
