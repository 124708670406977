import { InformationCircleIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

import { Skeleton } from "../design-system/Skeleton.component"
import { Tooltip } from "../design-system/Tooltip.component"

type IInsightCardProps = {
	title: string
	subtitle?: string
	subtitleClassName?: string
	values: Array<{
		key: string
		value: string
		label?: string
		valueClassName?: string
		labelClassName?: string
	}>
	tooltip?: string
	className?: string
	isLoading?: boolean
	compact?: boolean
}

export function InsightCard({
	values,
	title,
	tooltip,
	subtitle,
	subtitleClassName,
	className,
	isLoading,
	compact,
}: IInsightCardProps) {
	return (
		<div
			className={clsx(
				"group",
				"rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all w-[90%] items-center sm:w-auto",
				className,
				compact ? "py-1 xl:px-3 px-2" : "p-2 xl:p-3",
			)}
		>
			<div className="flex items-baseline gap-2 justify-center sm:justify-normal">
				<h3
					className={clsx(
						"text-sm font-semibold text-gray-900 flex items-center",
						compact ? "md:text-md" : "md:text-lg",
					)}
				>
					{title}
					{tooltip && (
						<Tooltip content={tooltip}>
							<InformationCircleIcon className="h-4 w-4 ml-1 text-gray-600" />
						</Tooltip>
					)}
				</h3>
				{subtitle && <h4 className={clsx("text-gray-900 text-sm", subtitleClassName)}>{subtitle}</h4>}
			</div>

			<div className={clsx("flex gap-4 justify-center lg:justify-normal", compact ? "mt-1" : "mt-2")}>
				{isLoading ? (
					<div className="w-[220px] group gap-4 flex flex-col p-2">
						<Skeleton size="L" />
					</div>
				) : (
					values.map((value) => (
						<div
							key={`${value.key}-${value.value}`}
							className="flex flex-col lg:flex-row gap-1.5 items-center lg:items-end"
						>
							<span
								className={clsx(
									"text-md font-bold text-gray-900",
									value.valueClassName,
									compact ? "md:text-md" : "md:text-lg",
								)}
							>
								{value.value}
							</span>
							{value.label && (
								<span
									className={clsx(
										"font-medium text-gray-900 mb-1",
										value.labelClassName,
										compact ? "text-xs" : "text-sm md:text-md",
									)}
								>
									{value.label}
								</span>
							)}
						</div>
					))
				)}
			</div>
		</div>
	)
}
