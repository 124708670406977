export type SettingsScope = "user" | "workspace"

export type UserSettingsType = "profile" | "calendar"
export type WorkspaceSettingsType = "preferences" | "users" | "crm" | "billing"
export type SettingsType = UserSettingsType | WorkspaceSettingsType

export type SettingsPageParams = {
	settingsScope: SettingsScope
	settingsType: SettingsType
}

export const defaultSettingsScope: SettingsScope = "user"
export const settingsScopes: SettingsScope[] = ["user", "workspace"]

export const userSettingsTypes: UserSettingsType[] = ["profile", "calendar"]
export const defaultUserSettingsType: UserSettingsType = "profile"
export const workspaceSettingsTypes: WorkspaceSettingsType[] = ["preferences", "users", "crm", "billing"]
export const defaultWorkspaceSettingsType: WorkspaceSettingsType = "users"

export const purchasePlanQueryParam = "purchase-confirm"
export const billingPeriodQueryParam = "billing-period"

export function makeSettingsPath() {
	return makeUserSettingsPath(defaultUserSettingsType)
}

export function makeUserSettingsPath(settingsType: UserSettingsType) {
	return `/settings/user/${settingsType}`
}

export function makeWorkspaceSettingsPath(settingsType: WorkspaceSettingsType) {
	return `/settings/workspace/${settingsType}`
}

export function makeWorkspaceBillingPath() {
	return makeWorkspaceSettingsPath("billing")
}

export function makePlanPurchaseUrl(planId: string, billingPeriod: "monthly" | "yearly") {
	const path = makeWorkspaceSettingsPath("billing")
	return `${path}?${purchasePlanQueryParam}=${planId}&${billingPeriodQueryParam}=${billingPeriod}`
}
