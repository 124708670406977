import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

function ErrorBadge({ className, label }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10",
				className,
			)}
		>
			{label}
		</span>
	)
}

function SuccessBadge({ className, label }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
				className,
			)}
		>
			{label}
		</span>
	)
}

function InfoBadge({ className, label }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-gray-500/10",
				className,
			)}
		>
			{label}
		</span>
	)
}

function NeutralBadge({ className, label }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10",
				className,
			)}
		>
			{label}
		</span>
	)
}

type SharedBadgeProps = {
	className?: string
	label: React.ReactNode
}

export type BadgeProps = SharedBadgeProps & {
	variant: "info" | "success" | "error" | "neutral"
}

export function Badge({ label, className, variant }: BadgeProps) {
	switch (variant) {
		case "success":
			return <SuccessBadge label={label} className={className} />
		case "error":
			return <ErrorBadge label={label} className={className} />
		case "info":
			return <InfoBadge label={label} className={className} />
		case "neutral":
			return <NeutralBadge label={label} className={className} />
		default:
			assertNever(variant)
	}
}
