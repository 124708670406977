import { ChevronRightIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React from "react"
import { Trans } from "react-i18next"

import type { Call } from "../../../../core/domain/Call.entity"
import { Card } from "../../../components/design-system/Card.component"
import { StatusBadge } from "../../../components/design-system/StatusBadge.component"
import { TrackingExternalHref } from "../../../components/design-system/TrackingExternalHref.component"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { CalendarSyncButtons } from "../../../components/shared/CalendarSyncButtons.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useCalendarContext } from "../../../contexts/calendar.context"
import { useLanguage } from "../../../contexts/language.context"
import { makeCallPath, makeRecentCallsPath } from "../../../router/Router"
import { DateFormatter, DateProvider } from "../../../utils/time"
import { makeWorkspaceBillingPath } from "../../Settings/config"

type IRecentProspectingCallsCardProps = {
	calls: Call[]
}

function RecentProspectingCallsEmptyState() {
	const { calendarAndEvents } = useCalendarContext()
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const isCallRecordingEnabled = workspace.isPlanFeatureEnabled("call-recording")
	const isCalendarIntegrationEnabled = workspace.isPlanFeatureEnabled("calendar-integration")
	const isCoachEnabled = workspace.isPlanFeatureEnabled("rippletide-coach")

	if (!calendarAndEvents.data) {
		return null
	}

	const { calendar } = calendarAndEvents.data
	if (calendar.status === "not-found" || calendar.status === "disconnected") {
		return (
			<>
				<div className="flex flex-col flex-1 text-center items-center justify-center m-8">
					<div className="md:w-[60%] gap-2 flex flex-col">
						<p className="font-semibold">
							{isCalendarIntegrationEnabled ? (
								<>
									{t("No calls recorded yet: Sync your calendar now to kickstart your efficiency 🚀")}
									<CalendarSyncButtons
										calendar={calendar}
										reconnect={calendar.status === "disconnected" ? calendar.platform : undefined}
									/>
								</>
							) : (
								<>
									{t("No calls recorded yet: Upgrade your plan now to kickstart your efficiency 🚀")}
									<div className="flex items-center justify-center">
										<TrackingLink
											to={makeWorkspaceBillingPath()}
											eventName="recent calls: upgrade plan"
											className="mt-2 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
										>
											{t("Upgrade my plan")}
										</TrackingLink>
									</div>
								</>
							)}
						</p>
						{isCoachEnabled && (
							<p className="mt-2 text-gray-500">
								<Trans
									i18nKey="Otherwise, you can first try out Rippletide’s meeting recording without syncing your calendar by inviting <1>{{email}}</1> to any meeting where you are the event organiser."
									components={{
										1: <span className="text-sky font-semibold">coach@rippletide.com</span>,
									}}
									values={{ email: "coach@rippletide.com" }}
								/>
							</p>
						)}
					</div>
				</div>
			</>
		)
	}

	return (
		<div className="flex justify-center items-center mt-6">
			<div className="w-full rounded-lg text-center p-4 max-w-2xl">
				<h3 className="text-xl font-semibold text-gray-900">{t("No calls recorded yet")}</h3>
				{isCallRecordingEnabled ? (
					<p className="mt-1 text-sm text-gray-500 mb-2">
						{t("After your calls, check back for in-depth analysis and actionable insights.")}
					</p>
				) : (
					<>
						<p className="mt-1 text-sm text-gray-500 mb-2">
							{t("Upgrade your plan now to kickstart your efficiency 🚀")}
						</p>
						<TrackingLink
							to={makeWorkspaceBillingPath()}
							eventName="call empty state: upgrade plan"
							className="mx-auto max-w-[240px] mt-2 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
						>
							{t("Upgrade my plan")}
						</TrackingLink>
					</>
				)}
			</div>
		</div>
	)
}

export function RecentProspectingCallsCard({ calls }: IRecentProspectingCallsCardProps) {
	const { t } = useLanguage()

	return (
		<Card
			title={t("Recent prospecting calls")}
			CTA={
				<TrackingLink
					to={makeRecentCallsPath()}
					className="text-sm font-semibold leading-6 text-indigo-400"
					eventName="Dashboard: view all calls"
				>
					{t("View all")}
				</TrackingLink>
			}
			className="sm:mx-6 my-6"
		>
			{calls.length === 0 ? (
				<RecentProspectingCallsEmptyState />
			) : (
				<ul role="list" className="divide-y divide-gray-200 border-gray-200">
					{calls.map((call) => {
						return <RecentCall key={call.props.id} call={call} />
					})}
				</ul>
			)}
		</Card>
	)
}

type RecentCallProps = {
	call: Call
}

function RecentCall({ call }: RecentCallProps) {
	const { t, language } = useLanguage()
	const dateFormatter = new DateFormatter(new DateProvider(), t)

	const externalAttendees = call.props.attendees.filter((attendee) => attendee.role === "lead")
	const [firstExternalAttendee] = externalAttendees

	const badgeVariant = call.isProcessable ? "success" : "warning"
	return (
		<li>
			<a
				href={makeCallPath(call.props.id, undefined)}
				className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-gray-50 last: rounded-b-xl cursor-pointer"
			>
				<div className="min-w-0 flex-auto truncate">
					<div
						className="flex items-center gap-x-3"
						title={!call.isProcessable ? call.getUnprocessableReasonString(t) : undefined}
					>
						<StatusBadge variant={badgeVariant} />
						<h2 className="min-w-0 text-sm font-semibold leading-6">
							<span className="truncate block">{call.props.name}</span>
						</h2>
						<span className=" text-sm leading-5 text-gray-400">
							{dateFormatter.formatToRelativeTimeLabel(call.props.createdAt, language)}
						</span>
					</div>
					{call.props.account && (
						<div className="mt-3 gap-x-2.5 text-sm leading-5 text-gray-400">
							{call.props.account.deal?.crmUrl && (
								<span className="flex">
									{call.props.durationSec && (
										<span className="mr-1">
											{dateFormatter.formatFixedDuration(call.props.durationSec, language)}
										</span>
									)}
									{t("about")}
									<TrackingExternalHref
										eventName="See deal in CRM"
										href={call.props.account.deal.crmUrl}
										className={clsx("group hover:text-indigo-400 inline-flex text-xs ml-1")}
									>
										{call.props.account.deal.name}
									</TrackingExternalHref>
								</span>
							)}
							<p>
								{t("with")}{" "}
								{firstExternalAttendee && (
									<>
										<span className="font-semibold text-indigo-600 text-xs">
											{firstExternalAttendee.fullName || firstExternalAttendee.email}
										</span>
										{externalAttendees.length > 1 ? ` (+${externalAttendees.length - 1})` : ""}{" "}
										{t("from")}{" "}
									</>
								)}
								<TrackingExternalHref
									eventName="See account in CRM"
									href={call.props.account.crmUrl}
									className={clsx("group hover:text-indigo-400 inline-flex text-xs")}
								>
									{call.props.account.name}
								</TrackingExternalHref>
							</p>
						</div>
					)}
				</div>
				{/* <div className="rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset text-gray-400 bg-gray-400/10 ring-gray-400/20">
					Preview
				</div> */}
				<ChevronRightIcon className="h-6 w-6 flex-none text-gray-400" />
			</a>
		</li>
	)
}
