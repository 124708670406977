import { assertNever } from "assert-never"

import {
	GetCallWithRelationshipSchema,
	type ICallCreateCommand,
	type ICallListQuery,
	type ICallsGateway,
} from "../../application/gateways/calls.gateway"
import { Call } from "../../domain/Call.entity"
import { PublicCall } from "../../domain/PublicCall.entity"
import { ensureNotAnApiError } from "./api-errors"

export class HttpCallsGateway implements ICallsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getWithRelationship(callId: string) {
		const endpointUrl = `${this.baseApiUrl}/calls/${callId}/with-relationship`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		ensureNotAnApiError(json)

		const parsed = GetCallWithRelationshipSchema.parse(json)

		return {
			call: Call.fromApiCall(parsed.call, this.baseApiUrl),
			relationship: parsed.relationship,
		}
	}

	public async list(query: ICallListQuery): Promise<{ calls: Call[]; totalMatchingCalls: number }> {
		const queryParams = new URLSearchParams()

		if (query.pagination) {
			queryParams.append("pageSize", query.pagination.pageSize.toString())
			queryParams.append("pageNumber", query.pagination.pageNumber.toString())
		}

		switch (query.filter.type) {
			case "any":
				// leave undefined
				break

			case "unassigned":
				queryParams.append("filter", "unassigned")
				break

			case "assigned":
				queryParams.append("assignedUserIds", JSON.stringify(query.filter.userIds))
				break

			default:
				assertNever(query.filter)
		}

		if (query.search) {
			queryParams.append("search", query.search)
		}

		const endpointUrl = `${this.baseApiUrl}/calls?${queryParams}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		if (!Array.isArray(json.calls) || typeof json.totalMatchingCalls !== "number") {
			throw new Error("Invalid response")
		}
		const calls: Call[] = json.calls.map((apiCall: unknown): Call => Call.fromApiCall(apiCall, this.baseApiUrl))
		return {
			calls,
			totalMatchingCalls: json.totalMatchingCalls,
		}
	}

	public async transcribe(call: Call): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}/transcribe`

		await fetch(endpointUrl, {
			method: "POST",
			body: null,
			credentials: "include",
		})
	}

	public async analyze(call: Call): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}/analyze`

		await fetch(endpointUrl, {
			method: "POST",
			body: null,
			credentials: "include",
		})
	}

	public async get(callId: string, inviteToken?: string): Promise<Call> {
		const searchParams = inviteToken ? `?inviteToken=${encodeURIComponent(inviteToken)}` : ""
		const endpointUrl = `${this.baseApiUrl}/calls/${callId}${searchParams}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		ensureNotAnApiError(json)

		return Call.fromApiCall(json, this.baseApiUrl)
	}

	public async getByPublicAccessToken(publicAccessToken: string): Promise<PublicCall> {
		const endpointUrl = `${this.baseApiUrl}/share/calls/${publicAccessToken}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		ensureNotAnApiError(json)

		return PublicCall.fromApiCall(json)
	}

	public async delete(call: Call) {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}`
		await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})
	}

	public async create(command: ICallCreateCommand): Promise<Call> {
		const endpointUrl = `${this.baseApiUrl}/calls/create`
		const formData = new FormData()
		formData.append("file", command.audioFile)
		formData.append("name", command.name)
		formData.append("language", command.language)
		if (command.agentId) {
			formData.append("agentId", command.agentId)
		}
		if (command.durationSec) {
			formData.append("durationSec", command.durationSec.toString())
		}
		if (command.transcription) {
			formData.append("transcription", command.transcription)
		}
		const res = await fetch(endpointUrl, {
			body: formData,
			method: "POST",
			credentials: "include",
		})
		const json = await res.json()
		return Call.fromApiCall(json, this.baseApiUrl)
	}
}
