import React from "react"
import { Trans } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { useSearchParam } from "react-use"

import type { SignupParams } from "../../../core/application/gateways/authentication.gateway"
import { Alert } from "../../components/design-system/Alert.component"
import { ClaimAccountOrSignupForm } from "../../components/shared/ClaimAccountOrSignupForm/ClaimAccountOrSignupForm.component"
import { PublicLayout } from "../../components/shared/PublicLayout/PublicLayout.component"
import { useSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"
import { useInviteToken } from "../../hooks/useInviteToken"
import { makeDashboardPath, makeLoginPath } from "../../router/Router"
import { DateProvider } from "../../utils/time"

type ClaimState =
	| {
			status: "claiming" | "claimed" | "idle"
	  }
	| {
			status: "error"
			error: Error
	  }

export function JoinPage() {
	const { user, fetchMe } = useSession()
	const navigate = useNavigate()
	const inviteToken = useInviteToken()
	const email = useSearchParam("email")
	const inviterFullName = useSearchParam("inviterFullName")
	const { t } = useLanguage()

	const { authenticationGateway } = useDependencies()
	const [claimState, setClaimState] = React.useState<ClaimState>({ status: "idle" })

	const handleSubmit = React.useCallback(
		async ({ firstName, lastName, plainPassword, coupon }: SignupParams) => {
			if (!inviteToken) {
				return
			}
			const dateProvider = new DateProvider()

			try {
				setClaimState({
					status: "claiming",
				})
				await authenticationGateway.claimUserAccount({
					inviteToken,
					firstName,
					lastName,
					plainPassword,
					coupon,
					timezone: dateProvider.getCurrentTimezone(),
				})

				await fetchMe()
				navigate(makeDashboardPath())
			} catch (e) {
				if (e instanceof Error) {
					setClaimState({
						status: "error",
						error: e,
					})
				}
				console.error("Error:", e)
			}
		},
		[authenticationGateway, fetchMe, inviteToken, navigate],
	)

	if (!inviteToken) {
		return <Navigate to={makeLoginPath()} replace={true} />
	}

	if (user) {
		return <Navigate to={makeDashboardPath()} replace={true} />
	}

	return (
		<PublicLayout pageName="Account claim" isFluid className="flex justify-center" isSignupAllowed={false}>
			<div className="flex flex-col max-w-2xl w-full">
				{claimState.status === "error" && (
					<Alert
						description={
							<Trans
								i18nKey="An error occurred while trying to setup your account. Please try again later or contact support at <1>{{email}}</1>"
								components={{
									1: (
										<a className=" text-sky font-semibold" href="mailto:support@rippletide.com">
											support@rippletide.com
										</a>
									),
								}}
								values={{ email: "support@rippletide.com" }}
							/>
						}
						variant="error"
					/>
				)}
				{inviterFullName && (
					<Alert
						description={t("{{fullName}} invited you to join their workspace.", {
							fullName: inviterFullName,
						})}
						variant="info"
						className="mt-2"
					/>
				)}

				<ClaimAccountOrSignupForm
					isBusy={claimState.status === "claiming"}
					handleSubmit={handleSubmit}
					email={email ?? undefined}
					formType="claim"
				/>
			</div>
		</PublicLayout>
	)
}
