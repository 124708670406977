import { useAnalytics } from "../../contexts/analytics.context"
import { useLanguage } from "../../contexts/language.context"

type CallSearchInputProps = {
	search: string
	onChange: (search: string) => void
}

export function CallSearchInput({ search, onChange }: CallSearchInputProps) {
	const { setupEventTracking } = useAnalytics()
	const { t } = useLanguage()

	const { trackEvent: trackSearchBarFocus } = setupEventTracking({
		eventName: "Search bar focused",
	})

	return (
		<div className="w-[384px]">
			<label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
				{t("Search")}
			</label>
			<div className="mt-2">
				<input
					id="search"
					name="search"
					type="text"
					placeholder={t("Search calls")}
					value={search}
					onChange={(e) => onChange(e.target.value)}
					onFocus={trackSearchBarFocus}
					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
				/>
			</div>
		</div>
	)
}
