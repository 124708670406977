import type { IUsersGateway, IUserUpdatePayload } from "../../application/gateways/users.gateway"
import { User, type UserProperties } from "../../domain/User.entity"
import type { CrmIntegration } from "../../domain/Workspace.entity"
import { ensureNotAnApiError } from "./api-errors"

async function tryParseJsonFromResponse(response: Response): Promise<unknown | null> {
	try {
		return await response.json()
	} catch {
		return null
	}
}

export class HttpUsersGateway implements IUsersGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async listOwnWorkspaceUsers(): Promise<User[]> {
		const endpointUrl = `${this.baseApiUrl}/workspaces/manager/list-workspace-users`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		if (!Array.isArray(json)) {
			throw new Error("Unexpected response from server")
		}

		return json.map((userProps) => User.fromProperties(userProps))
	}

	public async inviteUserToWorkspace(email: string, role: UserProperties["workspaceRole"]) {
		const endpointUrl = `${this.baseApiUrl}/workspaces/invite-user`
		const response = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				role,
			}),
		})
		ensureNotAnApiError(await tryParseJsonFromResponse(response))
	}

	public async updateWorkspaceUser(userId: string, updatePayload: IUserUpdatePayload) {
		const endpointUrl = `${this.baseApiUrl}/workspaces/update-user`
		const response = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				userId,
				role: updatePayload.role,
			}),
		})
		ensureNotAnApiError(await tryParseJsonFromResponse(response))
	}

	public async setupCrmIntegration(crmIntegration: CrmIntegration) {
		const endpointUrl = `${this.baseApiUrl}/workspaces/setup-crm-integration`
		const response = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(crmIntegration),
		})
		ensureNotAnApiError(await tryParseJsonFromResponse(response))
	}
}
