import React from "react"
import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "react-use"

import { useCalendarContext } from "../../../contexts/calendar.context"
import { makeDashboardPath } from "../../../router/Router"

export function useWelcomeModal() {
	const { calendarAndEvents } = useCalendarContext()
	const [isWelcomeModalHidden, setIsModalWelcomeModalHidden] = useLocalStorage<boolean>("welcome-modal-hidden", false)
	const [modalStep, setModalStep] = React.useState<"1-welcome" | "2-coach">("1-welcome")
	const handleWelcomeStepNext = React.useCallback(() => {
		setModalStep("2-coach")
	}, [])
	const navigate = useNavigate()

	const handleHideWelcomeModal = React.useCallback(() => {
		setIsModalWelcomeModalHidden(true)
		navigate(makeDashboardPath())
	}, [navigate, setIsModalWelcomeModalHidden])

	const notFoundCalendar =
		calendarAndEvents?.data?.calendar.status === "not-found" ? calendarAndEvents.data?.calendar : null
	const shouldOpenWelcomeModal = Boolean(notFoundCalendar && !isWelcomeModalHidden)

	return {
		isOpen: shouldOpenWelcomeModal,
		handleHideWelcomeModal,
		calendar: notFoundCalendar,
		modalStep,
		handleWelcomeStepNext,
	}
}
