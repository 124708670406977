import { LockClosedIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import { useLocation, useNavigate } from "react-router-dom"

import { ENABLE_COACHING_SESSIONS_FOR_WORKSPACE } from "../../../../../config"
import type { LibraryFolder } from "../../../../../core/domain/Library.entity"
import { isAIGeneratedFolder, type Library } from "../../../../../core/domain/Library.entity"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLinkOrButton } from "../../../../components/design-system/TrackingLinkOrButton.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeCreateTrainingProgramPath, makeLibraryFolderPath } from "../../../../router/Router"
import { getCoachingSessionFolder } from "../CoachingSession.util"
import { SideLibraryFoldersNavigationFolder } from "./SideLibraryFoldersNavigationFolder.component"

export type SideLibraryFoldersNavigationProps = {
	library: Library | "loading"
	onLibraryFolderCreationInUserLibrary?: () => void
	onLibraryFolderCreationInWorkspaceLibrary?: () => void
	onLibraryFolderNameEdition?: (folder: LibraryFolder) => void
	onLibraryFolderParentEdition?: (folder: LibraryFolder) => void
	onLibraryFolderDeletion?: (folder: LibraryFolder) => void
	onLibrarySubfolderCreation?: (parentLibraryFolder: LibraryFolder) => void
	disabled: boolean
	onFolderClick?: (folder: LibraryFolder) => void
	className?: string
	sideNavigationFolderClassName?: string
	currentFolderId?: string
	showCreateTrainingProgramButton: boolean
}

export function SideLibraryFoldersNavigation({
	library,
	onLibraryFolderCreationInUserLibrary,
	onLibraryFolderCreationInWorkspaceLibrary,
	onLibraryFolderNameEdition,
	onLibraryFolderParentEdition,
	onLibraryFolderDeletion,
	onLibrarySubfolderCreation,
	disabled,
	onFolderClick,
	className,
	sideNavigationFolderClassName,
	currentFolderId,
	showCreateTrainingProgramButton,
}: SideLibraryFoldersNavigationProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()

	if (library === "loading") {
		return (
			<SideBarLayout showCreateTrainingProgramButton={true} disabled={disabled} className={className}>
				<SpinnerIcon className="w-10 h-10 text-gray-400 animate-spin" />
			</SideBarLayout>
		)
	}

	// Disabled for all workspaces
	const trainingFolders: LibraryFolder[] = ENABLE_COACHING_SESSIONS_FOR_WORKSPACE(workspace.id)
		? [getCoachingSessionFolder(t)]
		: []

	return (
		<SideBarLayout
			showCreateTrainingProgramButton={showCreateTrainingProgramButton}
			disabled={disabled}
			className={className}
		>
			{trainingFolders.length > 0 && (
				<>
					<p className="mt-6 font-semibold">{t("Training")}</p>
					<ul role="list" className="-mx-2 space-y-1 mt-4">
						{trainingFolders.map((rootFolder, i) => (
							<div key={`TrainingFolder-${i}`}>
								<SideLibraryFoldersNavigationFolder
									key={rootFolder.id}
									folder={rootFolder}
									currentFolder={currentFolderId ? library.getFolderById(currentFolderId) : undefined}
									onLibraryFolderNameEdition={onLibraryFolderNameEdition}
									onLibraryFolderParentEdition={onLibraryFolderParentEdition}
									onLibraryFolderDeletion={onLibraryFolderDeletion}
									onLibrarySubfolderCreation={onLibrarySubfolderCreation}
									onFolderClick={onFolderClick}
									className={sideNavigationFolderClassName}
								/>
								{isAIGeneratedFolder(rootFolder) && i + 1 < library.userRoot.children.length && (
									<Divider />
								)}
							</div>
						))}
					</ul>
				</>
			)}
			<TrackingLinkOrButton
				eventName="My library click"
				className={clsx(
					"mt-6 font-semibold flex items-center space-x-4 p-2 w-full rounded-md hover:bg-gray-100 text-start",
					library.userRoot.id === currentFolderId && "bg-gray-100",
					"flex justify-between",
				)}
				{...(onFolderClick
					? {
							onClick: () => onFolderClick(library.userRoot),
					  }
					: {
							to: makeLibraryFolderPath(library.userRoot.id),
					  })}
			>
				<div className="flex items-center">
					{t("My library")}
					<LockClosedIcon className="h-4 w-4 ml-2" />
				</div>
				<div className="flex items-center">
					{library.userRoot.getRecursiveCallHighlightCount() > 0 && (
						<span className="text-sm text-gray-400 font-normal mr-1">
							{library.userRoot.getRecursiveCallHighlightCount()}
						</span>
					)}
					{onLibraryFolderCreationInUserLibrary && (
						<PlusButton
							onClick={() => onLibraryFolderCreationInUserLibrary()}
							eventName="Open user library folder creation modal"
						/>
					)}
				</div>
			</TrackingLinkOrButton>

			<ul role="list" className="-mx-2 space-y-1 mt-4">
				{library.userRoot.children.map((rootFolder, i) => (
					<div key={`UserRoot-child-${i}`}>
						<SideLibraryFoldersNavigationFolder
							key={rootFolder.id}
							folder={rootFolder}
							currentFolder={currentFolderId ? library.getFolderById(currentFolderId) : undefined}
							onLibraryFolderNameEdition={onLibraryFolderNameEdition}
							onLibraryFolderParentEdition={onLibraryFolderParentEdition}
							onLibraryFolderDeletion={onLibraryFolderDeletion}
							onLibrarySubfolderCreation={onLibrarySubfolderCreation}
							onFolderClick={onFolderClick}
							className={sideNavigationFolderClassName}
						/>
						{isAIGeneratedFolder(rootFolder) && i + 1 < library.userRoot.children.length && <Divider />}
					</div>
				))}
			</ul>
			<TrackingLinkOrButton
				eventName="Workspace library click"
				className={clsx(
					"mt-6 font-semibold flex items-center space-x-4 p-2 w-full rounded-md hover:bg-gray-100 text-start",
					library.workspaceRoot.id === currentFolderId && "bg-gray-100",
					"flex justify-between",
				)}
				{...(onFolderClick
					? {
							onClick: () => onFolderClick(library.workspaceRoot),
					  }
					: {
							to: makeLibraryFolderPath(library.workspaceRoot.id),
					  })}
			>
				{t("Workspace library")}
				<div className="flex items-center">
					{library.workspaceRoot.getRecursiveCallHighlightCount() > 0 && (
						<span className="text-sm text-gray-400 font-normal mr-1">
							{library.workspaceRoot.getRecursiveCallHighlightCount()}
						</span>
					)}
					{onLibraryFolderCreationInWorkspaceLibrary && (
						<PlusButton
							onClick={() => onLibraryFolderCreationInWorkspaceLibrary()}
							eventName="Open workspace library folder creation modal"
						/>
					)}
				</div>
			</TrackingLinkOrButton>

			<ul role="list" className="-mx-2 space-y-1 mt-4">
				{library.workspaceRoot.children.map((rootFolder, i) => (
					<div key={`WorkspaceRoot-child-${i}`}>
						<SideLibraryFoldersNavigationFolder
							key={rootFolder.id}
							folder={rootFolder}
							currentFolder={currentFolderId ? library.getFolderById(currentFolderId) : undefined}
							onLibraryFolderNameEdition={onLibraryFolderNameEdition}
							onLibraryFolderParentEdition={onLibraryFolderParentEdition}
							onLibraryFolderDeletion={onLibraryFolderDeletion}
							onLibrarySubfolderCreation={onLibrarySubfolderCreation}
							onFolderClick={onFolderClick}
							className={sideNavigationFolderClassName}
						/>
						{isAIGeneratedFolder(rootFolder) && i + 1 < library.workspaceRoot.children.length && (
							<Divider />
						)}
					</div>
				))}
			</ul>
		</SideBarLayout>
	)
}

function Divider() {
	return <div className="border-t border-gray-200 ml-5 mr-5"></div>
}

function SideBarLayout({
	children,
	showCreateTrainingProgramButton,
	disabled,
	className,
}: {
	children: React.ReactNode
	showCreateTrainingProgramButton: boolean
	disabled: boolean
	className?: string
}) {
	const { t } = useLanguage()
	const { user } = useAuthenticatedSession()
	const navigate = useNavigate()
	const location = useLocation()
	return (
		<div
			className={clsx(
				"bg-white xl:w-96 xl:flex-shrink-0 xl:border-r xl:border-gray-200",
				disabled && "pointer-events-none",
				className,
			)}
		>
			<div className="py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-4">
				<div className="flex items-center justify-between">
					<h1 className="text-xl font-semibold mb-2">{t("Library")}</h1>
					{showCreateTrainingProgramButton && user.isWorkspaceManager() && (
						<TrackingButton
							type="button"
							onClick={() =>
								navigate(makeCreateTrainingProgramPath(), { state: { from: location.pathname } })
							}
							className="flex items-center justify-center ml-2 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-navy-500 shadow-sm ring-1 ring-inset ring-navy-500 hover:bg-navy-500 hover:ring-navy-500 hover:text-white transition-all"
							eventName="Open library folder creation modal"
						>
							{t("Create training program")}
						</TrackingButton>
					)}
				</div>
				{children}
			</div>
		</div>
	)
}

type PlusButtonProps = {
	onClick: () => void
	eventName: string
}

function PlusButton({ onClick, eventName }: PlusButtonProps) {
	const { t } = useLanguage()

	return (
		<Tooltip
			content={t("Create folder")}
			tooltipClassName="!min-w-[150px] !top-[2.1rem] pointer-events-none"
			wrapperClassName="rounded-full"
		>
			<TrackingButton
				type="button"
				onClick={(e) => {
					e.preventDefault()
					onClick()
				}}
				className="flex items-center justify-center ml-2 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-navy-500 shadow-sm ring-1 ring-inset ring-navy-500 hover:bg-navy-500 hover:ring-navy-500 hover:text-white transition-all"
				eventName={eventName}
			>
				{"+"}
			</TrackingButton>
		</Tooltip>
	)
}
