import { z } from "zod"

import { leadKnowledgeSchema, leadKnowledgeV2Contact, leadKnowledgeV2OpportunitySchema } from "../../domain/Call.entity"
import { enrichedAccountSchema } from "./platform-admin.gateway"

const enrichedContactSchema = z.object({
	linkedin: z
		.object({
			companyName: z.string().optional(),
			profile: z
				.object({
					headline: z.string().nullish(),
					publicIdentifier: z.string().nullish(),
				})
				.nullish(),
		})
		.nullish(),
	email: z.string().nullish(),
})

const RelationshipAccountPropertiesSchema = z.object({
	id: z.string(),
	relationshipId: z.string(),
	crmId: z.string().nullish(),
	name: z.string().nullish(),
	enrichment: enrichedAccountSchema.nullish(),
})

const RelationshipOpportunityPropertiesSchema = z.object({
	id: z.string(),
	relationshipId: z.string(),
	crmId: z.string().nullish(),
	name: z.string().nullish(),
	amount: z.number().nullish(),
	probability: z.number().nullish(),
	stageName: z.string().nullish(),
	closeDate: z.coerce.date().nullish(),
	creationDate: z.coerce.date().nullish(),
	ownerCrmId: z.string().nullish(),
	ownerFirstName: z.string().nullish(),
	ownerLastName: z.string().nullish(),
	leadKnowledge: leadKnowledgeV2OpportunitySchema.nullish(),
})

const RelationshipContactPropertiesSchema = z.object({
	id: z.string(),
	relationshipId: z.string(),
	email: z.string(),
	crmId: z.string().nullish(),
	firstName: z.string().nullish(),
	lastName: z.string().nullish(),
	enrichment: enrichedContactSchema.nullish(),
	leadKnowledge: leadKnowledgeV2Contact.nullish(),
})

export type RelationshipContact = z.infer<typeof RelationshipContactPropertiesSchema>

export const RelationshipSchema = z.object({
	id: z.string(),
	workspaceId: z.string(),
	accountDomains: z.array(z.string()),
	personalEmails: z.array(z.string()),
	account: RelationshipAccountPropertiesSchema.nullish(),
	opportunity: RelationshipOpportunityPropertiesSchema.nullish(),
	contacts: z.array(RelationshipContactPropertiesSchema).nullish(),
	risks: z.array(z.enum(["close-date-in-past", "not-moved-from-stage"])).nullish(),
	lastActivityDate: z.coerce.date(),
	users: z
		.array(
			z.object({
				id: z.string(),
				email: z.string(),
				firstName: z.string(),
				lastName: z.string(),
			}),
		)
		.nullish(),
	activities: z
		.object({
			calls: z
				.array(
					z.object({
						id: z.string(),
						createdAt: z.coerce.date(),
						name: z.string(),
						attendees: z.array(
							z.object({
								id: z.string(),
								email: z.string().nullish(),
								fullName: z.string().nullish(),
								invitationStatus: z.string(),
							}),
						),
						keyPoints: z.array(z.string()),
						leadKnowledge: leadKnowledgeSchema,
						thumbnailUrl: z.string().nullish(),
					}),
				)
				.nullish(),
			calendarEvents: z
				.array(
					z.object({
						id: z.string(),
						startTime: z.coerce.date(),
						endTime: z.coerce.date(),
						title: z.string().nullish(),
						attendees: z.array(
							z.object({
								email: z.string(),
								firstName: z.string().nullish(),
								lastName: z.string().nullish(),
							}),
						),
					}),
				)
				.nullish(),
		})
		.nullish(),
})

export const RelationshipOwnerSchema = z.object({
	crmId: z.string(),
	firstName: z.string().nullish(),
	lastName: z.string().nullish(),
})

export type Relationship = z.infer<typeof RelationshipSchema>

export const GetRelationshipByOpportunityIdResponseSchema = RelationshipSchema

export const GetSalesRelationshipsResponseSchema = z.object({
	relationships: z.array(RelationshipSchema),
	owners: z.array(RelationshipOwnerSchema),
})

export type GetSalesRelationshipsResponse = z.infer<typeof GetSalesRelationshipsResponseSchema>

export type IGetSalesRelationshipsFilter = {
	ownerId?: string | null
	closingDateStart?: Date | null
	closingDateEnd?: Date | null
}

export type IRelationshipsGateway = {
	getSalesRelationships: (filter: IGetSalesRelationshipsFilter) => Promise<GetSalesRelationshipsResponse>
	getRelationshipByOpportunityId: (opportunityId: string) => Promise<Relationship>
}
