import React from "react"

import type { Relationship } from "../../../../../core/application/gateways/relationships.gateway"
import { useLanguage } from "../../../../contexts/language.context"
import { PanelEntry } from "./PanelEntry.component"

type RelationshipInfoPanelProps = {
	relationship: Relationship
}

export function RelationshipOpportunityPanel({ relationship }: RelationshipInfoPanelProps) {
	const { t } = useLanguage()

	return (
		<div>
			<div className="mt-4">
				<h2 className="text-xl mb-2 font-semibold text-gray-900">{t("Opportunity")}</h2>
				<div className="flex gap-4 flex-col">
					<PanelEntry
						title="Opportunity buying context"
						value={relationship.opportunity?.leadKnowledge?.buyingContext}
					/>
					<PanelEntry
						title="Opportunity specific needs"
						value={relationship.opportunity?.leadKnowledge?.specificNeeds}
					/>
					<PanelEntry
						title="Opportunity competitive consideration summary"
						value={relationship.opportunity?.leadKnowledge?.competitiveConsiderationSummary}
					/>
				</div>
			</div>
		</div>
	)
}
