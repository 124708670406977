import React from "react"

import type { Library, LibraryFolder } from "../../../core/domain/Library.entity"
import { type TFunction, useLanguage } from "../../contexts/language.context"
import { getFolderDisplayNameString } from "../../pages/Library/components/FolderDisplayName.component"

export type LibraryFolderSelectProps = {
	library: Library
	defaultValue?: string
	/** whether to show User Library, Workspace Library, or both */
	onlyRoot?: "user" | "workspace"
	htmlName: string
	showAIFolders: boolean
}

function renderFolder(folder: LibraryFolder, t: TFunction, showAIFolders: boolean, nestingLevel = 0): React.ReactNode {
	if (!showAIFolders && folder.isAIFolder()) {
		return
	}

	if (folder.hasChildren()) {
		return (
			<React.Fragment key={`frag-${folder.id}`}>
				<option key={folder.id} value={folder.id}>
					{"\u00A0".repeat(nestingLevel * 4)}
					{getFolderDisplayNameString(folder, t)}
				</option>
				{folder.children.map((child) => renderFolder(child, t, showAIFolders, nestingLevel + 1))}
			</React.Fragment>
		)
	}

	return (
		<option key={folder.id} value={folder.id}>
			{"\u00A0".repeat(nestingLevel * 4)}
			{getFolderDisplayNameString(folder, t)}
		</option>
	)
}

export function LibraryFolderSelect({
	library,
	defaultValue,
	onlyRoot,
	htmlName,
	showAIFolders,
}: LibraryFolderSelectProps) {
	const { t } = useLanguage()

	const rootFolders = (() => {
		switch (onlyRoot) {
			case "user":
				return [library.userRoot]
			case "workspace":
				return [library.workspaceRoot]
			default:
				return [library.userRoot, library.workspaceRoot]
		}
	})()

	return (
		<div className="mt-4">
			<label htmlFor={htmlName} className="block text-sm font-medium leading-6 text-gray-900">
				{t("Parent folder")}
			</label>
			<select
				id={htmlName}
				name={htmlName}
				className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
				defaultValue={defaultValue}
			>
				{rootFolders.map((folder) => renderFolder(folder, t, showAIFolders))}
			</select>
		</div>
	)
}
