import { Badge } from "../design-system/Badge.component"

export function ScoreBadge({
	score,
	maxScore,
	className,
}: {
	score: number | null
	maxScore: number | null
	className?: string
}) {
	const scorePercentage = typeof score === "number" && typeof maxScore === "number" ? score / maxScore : null
	const badgeVariant =
		scorePercentage === null
			? null
			: scorePercentage >= 0.75
			? "success"
			: scorePercentage >= 0.5
			? "neutral"
			: "error"
	const scoreLabel = typeof score === "number" && typeof maxScore === "number" ? `${score}/${maxScore}` : null

	if (!badgeVariant || !scoreLabel) {
		return null
	}

	return <Badge variant={badgeVariant} className={className} label={scoreLabel} />
}
