import { z } from "zod"

import { ApiCallResponseSchema, type Call } from "../../domain/Call.entity"
import type { PublicCall } from "../../domain/PublicCall.entity"
import { type Relationship, RelationshipSchema } from "./relationships.gateway"

export type ICallCreateCommand = {
	audioFile: File
	transcription: string | undefined
	name: string
	durationSec: number | undefined
	language: string
	agentId: string | undefined
}

export type CallAssignationFilter =
	| {
			type: "any"
	  }
	| {
			type: "unassigned"
	  }
	| {
			type: "assigned"
			userIds: string[]
	  }

export type ICallListQuery = {
	pagination?: {
		pageSize: number
		pageNumber: number
	}
	filter: CallAssignationFilter
	search?: string
}

export const GetCallWithRelationshipSchema = z.object({
	call: ApiCallResponseSchema,
	relationship: RelationshipSchema.optional(),
})

export type ICallWithRelationship = {
	call: Call
	relationship: Relationship | undefined
}

export type ICallsGateway = {
	get: (callId: string, inviteToken?: string) => Promise<Call>
	getByPublicAccessToken: (publicAccessToken: string) => Promise<PublicCall>
	list: (query: ICallListQuery) => Promise<{ calls: Call[]; totalMatchingCalls: number }>
	create(command: ICallCreateCommand): Promise<Call>
	transcribe(call: Call): Promise<void>
	analyze: (call: Call) => Promise<void>
	delete: (call: Call) => Promise<void>
	getWithRelationship: (callId: string) => Promise<ICallWithRelationship>
}
