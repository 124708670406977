import { BookmarkIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

import type { CallHighlight } from "../../../../core/domain/Library.entity"
import { useLanguage } from "../../../contexts/language.context"
import { makeLibraryFolderPath, makeLibraryPath } from "../../../router/Router"
import { Duration } from "../Duration.component"
import { TrackingLink } from "../TrackingLink.component"

export type HighlightsTimelineProps = {
	highlights: CallHighlight[]
	totalDurationSeconds: number
	currentHoverTimeSeconds: number | null
	currentTimeSeconds: number
}

export function HighlightsTimeline({
	highlights,
	totalDurationSeconds,
	currentTimeSeconds,
	currentHoverTimeSeconds,
}: HighlightsTimelineProps) {
	const { t } = useLanguage()

	if (highlights.length === 0) {
		return null
	}

	return (
		<div className="space-y-1 mt-4 mb-4">
			<div className="flex items-center text-blue-500 text-sm font-medium">
				<BookmarkIcon className="w-4 h-4 mr-1" />
				{t("Highlights")}
			</div>
			<div className="relative h-2 bg-blue-100 rounded-full">
				{highlights.map((highlight, index) => {
					const isFirst = index === 0
					const isLast = index === highlights.length - 1
					const leftOffset = isFirst ? 0 : 4
					const widthOffset = isLast ? 0 : 4
					const isHighlightPlaying =
						currentTimeSeconds >= highlight.startsAtMs / 1000 &&
						currentTimeSeconds <= highlight.endsAtMs / 1000
					const isHighlightHovered =
						typeof currentHoverTimeSeconds === "number" &&
						currentHoverTimeSeconds >= highlight.startsAtMs / 1000 &&
						currentHoverTimeSeconds <= highlight.endsAtMs / 1000

					const isActive =
						typeof currentHoverTimeSeconds === "number" ? isHighlightHovered : isHighlightPlaying

					return (
						<div key={`HighlightsTimeline-fragment-${index}`}>
							<div
								key={highlight.id + "bar" + index}
								className={clsx(
									`absolute h-full rounded-full bg-blue-600 px-1 pt-1`,
									isActive ? "opacity-100" : "opacity-50",
								)}
								style={{
									left: `calc(${calculateSegmentOffset(
										highlight,
										totalDurationSeconds,
									)}% + ${leftOffset}px)`,
									width: `calc(${calculateSegmentWidth(
										highlight,
										totalDurationSeconds,
									)}% - ${widthOffset}px)`,
								}}
							/>
							{isActive && (
								<TrackingLink
									to={
										highlight.containingLibraryFolderId
											? makeLibraryFolderPath(highlight.containingLibraryFolderId)
											: makeLibraryPath()
									}
									eventName="Call highlights: highlight clicked"
									key={highlight.id + "label" + index}
									className={clsx(
										`mt-1 pt-2 absolute flex flex-col items-center justify-center text-blue-500 text-xs font-medium bg-transparent rounded-full w-full`,
									)}
								>
									<div
										className="absolute top-2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-blue-100"
										style={{
											left: `calc(${calculateMiddleOffset(highlight, totalDurationSeconds)}% + ${
												leftOffset - 8 // 8 is half of the border width
											}px)`,
										}}
									></div>
									<div className="text-left bg-blue-100 rounded-lg p-2 mt-2 w-full">
										<span className="font-medium">
											{highlight.creatorFullName ?? t("Deleted user")} {t("at")}{" "}
											<Duration seconds={highlight.startsAtMs / 1000} />:
										</span>
										<p className="font-normal">{highlight.comment}</p>
									</div>
								</TrackingLink>
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}

const calculateSegmentWidth = (highlight: CallHighlight, totalDurationSeconds: number) => {
	const width = ((highlight.endsAtMs / 1000 - highlight.startsAtMs / 1000) / totalDurationSeconds) * 100
	return width
}

const calculateSegmentOffset = (highlight: CallHighlight, totalDurationSeconds: number) => {
	return (highlight.startsAtMs / 1000 / totalDurationSeconds) * 100
}

const calculateMiddleOffset = (highlight: CallHighlight, totalDurationSeconds: number) => {
	const middleSec = (highlight.startsAtMs + highlight.endsAtMs) / 2 / 1000
	return (middleSec / totalDurationSeconds) * 100
}
