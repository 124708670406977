import {
	type SupportedWorkspaceLanguageCode,
	supportedWorkspaceLanguageCodeSchema,
} from "../../core/domain/Workspace.entity"
import type { TFunction } from "../contexts/language.context"

type IDateProvider = {
	now(): Date
	isToday(date: Date): boolean
	addWorkingDays(date: Date, numWorkingDays: number): Date
}

export class DateProvider implements IDateProvider {
	now(): Date {
		return new Date()
	}

	public getCurrentTimezone(): string {
		return Intl.DateTimeFormat().resolvedOptions().timeZone
	}

	public getLanguageCode(): SupportedWorkspaceLanguageCode | null {
		const languageCode = Intl.DateTimeFormat().resolvedOptions().locale.split("-")[0]
		const parseResult = supportedWorkspaceLanguageCodeSchema.safeParse(languageCode)
		if (parseResult.success) {
			return parseResult.data
		}

		return null
	}

	isToday(date: Date): boolean {
		const today = this.now()
		const isToday =
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		return isToday
	}

	addWorkingDays(currentDate: Date, numWorkingDays: number): Date {
		const date = new Date(currentDate.getTime())
		date.setHours(0, 0, 0, 0) // midnight

		for (let daysAdded = 0; daysAdded <= numWorkingDays; ) {
			date.setDate(date.getDate() + 1)
			const dayOfWeek = date.getDay()
			const isWeekDay = dayOfWeek !== 0 && dayOfWeek !== 6
			if (isWeekDay) {
				daysAdded++
			}
		}

		return date
	}

	public computeDaysUntil(date: Date): number {
		const now = new Date()
		const diff = date.getTime() - now.getTime()
		const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24))
		return diffDays
	}
}

export class DateFormatter {
	constructor(
		private readonly dateProvider: IDateProvider,
		private readonly t: TFunction,
	) {}

	/**
	 * TODO: check if this could be replaced by getFormattedDateRange or formatFixedDuration
	 * Example return values
	 *   day: 01/01/1970    time: 00:00
	 *   day: Today         time: 23:59
	 *   day: Aujourd'hui   time: 23h59
	 */
	getFormattedDate(date: Date): DayAndTime {
		const today = this.t("Today")
		const day = date.getDate().toString().padStart(2, "0")
		const month = (date.getMonth() + 1).toString().padStart(2, "0")
		const year = date.getFullYear()
		const hours = date.getHours().toString().padStart(2, "0")
		const minutes = date.getMinutes().toString().padStart(2, "0")

		const formattedDay = this.dateProvider.isToday(date) ? today : `${day}/${month}/${year}`
		const formattedTime = this.t("{{hours}} : {{minutes}}", { hours, minutes })

		return { day: formattedDay, time: formattedTime }
	}

	public getFormattedDateRange(startDate: Date, endDate: Date, locale: string) {
		const now = new Date()
		const timeUntilStartInSeconds = (startDate.getTime() - now.getTime()) / 1000
		const durationInSeconds = (endDate.getTime() - startDate.getTime()) / 1000

		const { timeUnit, timeValue } = this.determineBestUnitAndValue(timeUntilStartInSeconds)

		const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" })
		const timeFormat = new Intl.DateTimeFormat(locale, { hour: "numeric", minute: "numeric" })

		return {
			timeUntilStartLabel: rtf.format(Math.round(timeValue), timeUnit),
			startDateLabel: timeFormat.format(startDate),
			endDateLabel: timeFormat.format(endDate),
			durationLabel: this.formatFixedDuration(durationInSeconds, locale),
		}
	}

	public formatToRelativeTimeLabel(date: Date, locale: string) {
		const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)
		let interval = seconds / 31536000
		const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" })
		if (interval > 1) {
			return rtf.format(-Math.floor(interval), "year")
		}
		interval = seconds / 2592000
		if (interval > 1) {
			return rtf.format(-Math.floor(interval), "month")
		}
		interval = seconds / 86400
		if (interval > 1) {
			return rtf.format(-Math.floor(interval), "day")
		}
		interval = seconds / 3600
		if (interval > 1) {
			return rtf.format(-Math.floor(interval), "hour")
		}
		interval = seconds / 60
		if (interval > 1) {
			return rtf.format(-Math.floor(interval), "minute")
		}
		return rtf.format(-Math.floor(interval), "second")
	}

	public formatFixedDuration(durationInSeconds: number, locale: string): string {
		const { timeUnit, timeValue } = this.determineBestUnitAndValue(durationInSeconds)
		const nf = new Intl.NumberFormat(locale, { style: "unit", unit: timeUnit, unitDisplay: "long" })
		return nf.format(Math.round(timeValue))
	}

	private determineBestUnitAndValue(timeInSeconds: number): {
		timeUnit: Intl.RelativeTimeFormatUnit
		timeValue: number
	} {
		let timeValue = timeInSeconds

		if (timeInSeconds < 60) {
			return { timeUnit: "second", timeValue }
		}

		timeValue /= 60 // Convert to minutes

		if (timeValue < 60) {
			return { timeUnit: "minute", timeValue }
		}

		timeValue /= 60 // Convert to hours

		if (timeValue < 24) {
			return { timeUnit: "hour", timeValue }
		}

		return { timeUnit: "day", timeValue: timeValue / 24 } // Convert to days
	}

	/**
	 * TODO: check if this could be replaced by getFormattedDateRange or formatFixedDuration
	 * Example return values
	 *      00:00
	 *      00:01
	 *      01:00
	 *      01:59
	 *   02:46:30
	 */
	formatTime(durationSec: number) {
		const hours = Math.floor(durationSec / 3600)
		const minutes = Math.floor((durationSec % 3600) / 60)
		const seconds = Math.floor(durationSec % 60)

		const hoursLabel = this.padZero(hours)
		const minutesLabel = this.padZero(minutes)
		const secondsLabel = this.padZero(seconds)

		if (hours) return `${hoursLabel}:${minutesLabel}:${secondsLabel}`
		else return `${minutesLabel}:${secondsLabel}`
	}

	formatDate(date: Date, locale: string, options: Intl.DateTimeFormatOptions): string {
		const formatter = new Intl.DateTimeFormat(locale, options)

		const formattedDate = formatter.format(date)

		return formattedDate
	}

	private padZero(num: number): string {
		return num < 10 ? `0${num}` : num.toString()
	}
}

/** Represents a translated day and time */
export type DayAndTime = {
	/**
	 * Examples:
	 *   01/01/1970
	 *   Today
	 *   Aujourd'hui
	 */
	day: string

	/**
	 * Examples:
	 *   00:00
	 *   23:59
	 *   00h00
	 *   23h59
	 */
	time: string
}

export function sleep(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms))
}
