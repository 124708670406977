import "react-medium-image-zoom/dist/styles.css"

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ArrowPathIcon, EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Fragment } from "react"
import Zoom from "react-medium-image-zoom"

import type { Call } from "../../../../core/domain/Call.entity"
import type { User } from "../../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { useCallFeaturesVisibility } from "../../../hooks/useCallFeaturesVisibility"
import { makeCallPath } from "../../../router/Router"
import { DateFormatter, DateProvider } from "../../../utils/time"
import { Badge } from "../../design-system/Badge.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingLink } from "../../design-system/TrackingLink.component"
import { _AnalysisCell } from "./_AnalysisCell.component"

export type _CallRowProps = {
	refreshCalls?: (reason: string) => Promise<void>
	call: Call
	displayAssignedUser: boolean
	users?: User[]
}

export function _CallRow({ call, displayAssignedUser, users, refreshCalls }: _CallRowProps) {
	const { t } = useLanguage()
	const { callsGateway, platformAdminGateway } = useDependencies()
	const { user, workspace, isPlatformAdmin } = useAuthenticatedSession()
	const callFeaturesVisibility = useCallFeaturesVisibility(call)
	const dateFormatter = new DateFormatter(new DateProvider(), t)
	const { language } = useLanguage()

	const durationLabel = call.getFormattedDuration()

	const assignedUser = users?.find((user) => user.id === call.props.assignedUserId)

	const { day, time } = call.getCreatedAtDayAndTime(t)
	const createdAt = `${day} ${time}`

	const handleCallDelete = async () => {
		if (
			!window.confirm(
				t(`Are you sure you want to delete "{{name}}"?`, {
					name: call.props.name,
				}),
			)
		) {
			return
		}
		await callsGateway.delete(call)
		await refreshCalls?.("call deleted")
	}

	const handleAdminCallReprocessingRequested = async () => {
		const messages = [
			`Are you sure you want to request reprocessing of "${call.props.name}"?`,
			"This admin action cannot be undone and will trigger a reprocessing of the call.",
			"The transcription, analysis, and insights will be recalculated.",
			"The creation date of the call will be updated to the current date so that it can be shared again.",
		]
		if (!window.confirm(messages.join("\n\n"))) {
			return
		}
		await platformAdminGateway.requestCallReprocessing({ callId: call.props.id })
		await refreshCalls?.("call reprocessed")
	}

	const thumbnailUrl =
		call.props.video?.thumbnail?.url ?? process.env.PUBLIC_URL + "/rippletide_logo_navy_background.svg"

	const ZoomComponent = call.props.video?.thumbnail?.url ? Zoom : Fragment

	return (
		<tr key={call.props.id}>
			<td
				className={clsx(
					"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-indigo-600 hover:text-indigo-900 sm:pl-6 cursor-pointer items-center truncate md:max-w-xs lg:max-w-sm",
					!call.isProcessable && "opacity-60",
				)}
			>
				<div className="flex items-center gap-x-2 flex-shrink-0">
					<div className="flex-shrink-0">
						<ZoomComponent>
							<img
								src={thumbnailUrl}
								alt={call.props.name}
								className="w-12 h-12 min-w-12 lg:max-w-12 rounded-md object-cover flex-shrink-0"
								width="48"
								height="48"
								loading="lazy"
							/>
						</ZoomComponent>
					</div>
					<TrackingLink
						to={makeCallPath(call.props.id, undefined)}
						eventName="View call details"
						eventProperties={{ callId: call.props.id }}
						className="flex items-center gap-x-2"
					>
						{call.isDemoCall() && <Badge label={t("Example call")} variant="info" />}
						{call.props.name}
					</TrackingLink>
				</div>
			</td>
			<td>
				<div className="flex items-center px-3 py-4 text-sm ">
					{call.props.countryCode && (
						<img
							src={`https://flagsapi.com/${call.props.countryCode}/flat/64.png`}
							alt={call.props.countryCode}
							className={clsx(
								"inline-block w-8 h-8 ml-2 min-w-[2rem]",
								!call.isProcessable && "opacity-20",
							)}
						/>
					)}
					{durationLabel && <span className="inline-block ml-2 text-gray-500 text-xs">{durationLabel}</span>}
				</div>
			</td>
			{workspace.props.featureFlags.callScoringAnalysis && !call.isDemoCall() && !user.isIntesciaCSM() && (
				<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
					{callFeaturesVisibility.scoring ? <_AnalysisCell call={call} className="mr-2" /> : null}
				</td>
			)}
			{displayAssignedUser && (
				// TODO: clicking on this icon should filter the calls by this user
				<td
					className={clsx(
						assignedUser ? "text-gray-900 font-medium" : "text-gray-700",
						"whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6",
					)}
				>
					{call.isDemoCall() ? "Rippletide" : assignedUser?.getFullName() ?? t("Unassigned")}
				</td>
			)}
			<td className="whitespace-nowrap px-3 py-4  text-gray-700">
				{createdAt}
				<br />
				<span className="text-sm text-gray-500">
					{dateFormatter.formatToRelativeTimeLabel(call.props.createdAt, language)}
				</span>
			</td>
			{user.isWorkspaceManager() && !call.isDemoCall() && (
				<td className="relative whitespace-nowrap py-2 pl-4 pr-6 text-right text-sm font-medium">
					<ManagerDropdown
						call={call}
						isPlatformAdmin={isPlatformAdmin}
						onAdminCallReprocessingRequested={handleAdminCallReprocessingRequested}
						onCallDelete={handleCallDelete}
					/>
				</td>
			)}
		</tr>
	)
}

type ManagerDropdownProps = {
	isPlatformAdmin: boolean
	call: Call
	onAdminCallReprocessingRequested: () => void
	onCallDelete: () => void
}

function ManagerDropdown({
	isPlatformAdmin,
	call,
	onAdminCallReprocessingRequested,
	onCallDelete,
}: ManagerDropdownProps) {
	const { t } = useLanguage()
	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
					<EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
				</MenuButton>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{isPlatformAdmin && (
							<MenuItem>
								<TrackingButton
									eventName="Call reprocessing requested"
									eventProperties={{
										callId: call.props.id,
									}}
									onClick={onAdminCallReprocessingRequested}
									type="button"
									className="text-yellow-600 hover:text-yellow-500 hover:bg-gray-50 px-4 py-2 group w-full flex"
									title="Admin: request reprocessing of call"
								>
									<ArrowPathIcon className="h-5 w-5 mr-1" />
									{/* Admin feature, no need to i18n */}
									<span className="text-medium">Reprocess…</span>
								</TrackingButton>
							</MenuItem>
						)}
						<MenuItem>
							<TrackingButton
								eventName="Call deleted"
								eventProperties={{
									callId: call.props.id,
								}}
								onClick={onCallDelete}
								type="button"
								className="text-red-600 hover:text-red-500 hover:bg-gray-50 px-4 py-2 group w-full flex"
							>
								<TrashIcon className="h-5 w-5 mr-1" />
								<span className="text-medium">{t("Delete…")}</span>
							</TrackingButton>
						</MenuItem>
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	)
}
