import _ from "lodash"
import { useEffect, useState } from "react"
import React from "react"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "react-use"

import type { CallAssignationFilter } from "../../core/application/gateways/calls.gateway"
import type { Call } from "../../core/domain/Call.entity"
import { useAuthenticatedSession } from "../contexts/authentication.context"
import { useDependencies } from "../contexts/dependencies.context"

type IUseCallsParams = {
	onlyListCallsFromUserIds?: string[]
}

function useSearch() {
	const [searchParams, setSearchParams] = useSearchParams()
	const [searchFilter, setSearchFilter] = React.useState<string | null>(searchParams.get("search"))

	React.useEffect(() => {
		const newUrlSearchParams = new URLSearchParams(searchParams)
		if (searchFilter) {
			newUrlSearchParams.set("search", searchFilter)
		} else {
			newUrlSearchParams.delete("search")
		}

		setSearchParams(newUrlSearchParams)
	}, [searchFilter, searchParams, setSearchParams])

	return {
		searchFilter,
		setSearchFilter,
	}
}

export function useCalls({ onlyListCallsFromUserIds }: IUseCallsParams = {}) {
	const { user } = useAuthenticatedSession()
	const { callsGateway } = useDependencies()

	const [calls, setCalls] = useState<Call[] | null>(null)
	const [totalCalls, setTotalCalls] = useState(0)
	const [pageNumber, setPageNumber] = useState(1)
	const { searchFilter, setSearchFilter } = useSearch()
	const [debouncedFilter, setDebouncedFilter] = useState<string | null>(null)

	useDebounce(
		() => {
			setDebouncedFilter(searchFilter)
			setPageNumber(1)
		},
		250,
		[searchFilter],
	)

	const [callAssignationFilter, setCallAssignationFilter] = useState<CallAssignationFilter>(() => {
		if (!user.isWorkspaceManager()) {
			console.log(`Listing calls assigned to ${user.id}`)
			return { type: "assigned", userIds: [user.id] }
		}

		if (onlyListCallsFromUserIds) {
			console.log(`Listing calls assigned to user ids: ${JSON.stringify(onlyListCallsFromUserIds)}`)
			return { type: "assigned", userIds: onlyListCallsFromUserIds }
		}

		console.log("Workspace manager, listing all calls")
		return { type: "any" }
	})
	const [pageSize] = useState(10)

	const listCalls = React.useCallback(
		async (reason: string) => {
			console.log(`Listing calls because: ${reason}`)
			const response = await callsGateway.list({
				pagination: { pageSize, pageNumber },
				filter: callAssignationFilter,
				search: debouncedFilter ?? undefined,
			})
			setCalls(response.calls)
			setTotalCalls(response.totalMatchingCalls)
		},
		[callAssignationFilter, callsGateway, pageNumber, pageSize, debouncedFilter],
	)

	const handlePageNavigation = (pageStateSetter: React.SetStateAction<number>) => {
		window.scrollTo({ top: 0, behavior: "smooth" })
		setPageNumber(pageStateSetter)
	}

	useEffect(
		function handlePaginationOrFilterChange() {
			listCalls("pagination-or-filter-change")

			if (pageNumber === 1) {
				// Only auto-refresh if we're viewing page 1
				const interval = setInterval(() => listCalls("autorefresh"), 30 * 1000) // 30 seconds
				return () => clearInterval(interval)
			}
		},
		[pageNumber, pageSize, callAssignationFilter, listCalls, debouncedFilter],
	)

	useEffect(
		function resetCurrentPageOnSettingsChange() {
			handlePageNavigation(1)
		},
		[pageSize, callAssignationFilter],
	)

	const handleCallAssignationFilterChange = React.useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			const value = e.target.value
			if (value === "#any#") {
				if (onlyListCallsFromUserIds) {
					setCallAssignationFilter({ type: "assigned", userIds: onlyListCallsFromUserIds })
				} else {
					setCallAssignationFilter({ type: "any" })
				}
			} else if (value === "#unassigned#") {
				setCallAssignationFilter({ type: "unassigned" })
			} else {
				setCallAssignationFilter({ type: "assigned", userIds: [value] })
			}
		},
		[onlyListCallsFromUserIds],
	)

	const handleSearchFilterChange = React.useCallback(
		(newFilter: string) => {
			setSearchFilter(newFilter)
		},
		[setSearchFilter],
	)

	const totalPages = Math.ceil(totalCalls / pageSize)

	return {
		calls: calls ?? [],
		totalCalls,
		pageNumber,
		pageSize,
		totalPages,
		handlePageNavigation,
		handleCallAssignationFilterChange,
		refreshCalls: listCalls,
		callAssignationFilter,
		isInitialLoading: calls === null,
		searchFilter,
		handleSearchFilterChange,
	}
}
