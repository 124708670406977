import { XMarkIcon } from "@heroicons/react/20/solid"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"

import { DEMO_TRAINING_PROGRAM_ID } from "../../../../../config"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { HorizontalStepper } from "../../../../components/shared/HorizontalStepper.component"
import { Layout } from "../../../../components/shared/Layout/Layout.component"
import { useLanguage } from "../../../../contexts/language.context"
import { makeEditTrainingProgramPath, makeTrainingProgramsPath } from "../../../../router/Router"
import { useTrainingProgram } from "../contexts/training-program.context"
import type { EditTrainingProgramsURLParams } from "../EditTrainingProgram/params"

type EditTrainingProgramLayoutProps = React.PropsWithChildren<{
	currentStep: EditTrainingProgramsURLParams["stepName"]
	onSaveClick: () => void
	isSaveDisabled?: boolean
	isLastStep?: boolean
}>

export function EditTrainingProgramLayout({
	children,
	currentStep,
	onSaveClick,
	isSaveDisabled,
	isLastStep,
}: EditTrainingProgramLayoutProps) {
	const { trainingProgram, programId } = useTrainingProgram()
	const { t } = useLanguage()
	const location = useLocation()

	const [from, setFrom] = useState<string | undefined>()

	const handleSaveClick = React.useCallback(() => {
		if (programId === DEMO_TRAINING_PROGRAM_ID) {
			if (
				confirm(
					"You are editing the demo training program. Editing this program will affect users signing up after you save. Are you sure you want to continue?",
				)
			) {
				onSaveClick()
			}
		} else {
			onSaveClick()
		}
	}, [onSaveClick, programId])

	if (location.state?.from && location.state.from !== from) {
		setFrom(location.state.from)
	}

	let title
	switch (currentStep) {
		case "name":
			title = trainingProgram
				? t('Edit "{{name}}" training program', { name: trainingProgram.trainingProgram.name })
				: t("1. Create new training program")
			break
		case "content":
			title = t("2. Choose training content")
			break
		case "enrolments":
			title = t("3. Enrol team members in training")
			break
	}

	return (
		<Layout pageName="Edit training program" isFluid className="h-full bg-gray-50">
			<div className="flex flex-1 flex-col">
				<div className="flex-1 flex flex-col">
					<div className="flex justify-between">
						<h1 className="text-xl font-semibold flex-1">{title}</h1>

						<HorizontalStepper
							steps={[
								{
									label: t("Name"),
									completed: ["name", "content", "enrolments"].includes(currentStep),
									type: "link",
									to: makeEditTrainingProgramPath(programId, "name"),
									eventName: "Training program stepper: navigate to step",
									eventProperties: {
										step: "name",
										trainingProgramId: programId,
									},
								},
								{
									label: t("Content"),
									completed: ["content", "enrolments"].includes(currentStep),
									type: "link",
									to: makeEditTrainingProgramPath(programId, "content"),
									eventName: "Training program stepper: navigate to step",
									eventProperties: {
										step: "content",
										trainingProgramId: programId,
									},
									disabled: !trainingProgram,
								},
								{
									label: t("Team members"),
									completed: currentStep === "enrolments",
									type: "link",
									to: makeEditTrainingProgramPath(programId, "enrolments"),
									eventName: "Training program stepper: navigate to step",
									eventProperties: {
										step: "enrolments",
										trainingProgramId: programId,
									},
									disabled: !trainingProgram,
								},
							]}
							className="flex-[2]"
						/>

						<div className="flex-1 flex justify-end items-center">
							<TrackingButton
								type="button"
								onClick={handleSaveClick}
								className="mr-4 flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200"
								eventName="Create or edit training program name and description"
								disabled={isSaveDisabled}
							>
								{t(isLastStep ? "Save & close" : "Save & continue")}
							</TrackingButton>
							<CloseButton to={from || makeTrainingProgramsPath()} />
						</div>
					</div>
					{programId === DEMO_TRAINING_PROGRAM_ID && (
						<Alert
							description="You are editing the demo training program. Editing this program will affect users signing up after you save."
							variant="error"
							className="my-4"
						/>
					)}
					{children}
				</div>
			</div>
		</Layout>
	)
}

type CloseButtonProps = {
	to: string
}

function CloseButton({ to }: CloseButtonProps) {
	const { t } = useLanguage()

	return (
		<TrackingLink className="text-sm" eventName="create or edit training program close" to={to}>
			<Tooltip content={t("Close without saving")} tooltipClassName="right-0">
				<XMarkIcon className="mr-3 h-8 w-8 text-gray-500" />
			</Tooltip>
		</TrackingLink>
	)
}
