import React from "react"

import type { User } from "../../../core/domain/User.entity"
import { Input } from "../../components/design-system/Input"
import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import { CallSearchInput } from "../../components/shared/CallSearchInput.component"
import { CallsTable } from "../../components/shared/CallsTable/CallsTable.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import PaginationFooter from "../../components/shared/PaginationFooter/PaginationFooter.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { useCalls } from "../../hooks/useCalls"
import { useTeamManagement } from "../../hooks/useTeamManagement"
import { getDemoCall } from "../../utils/onboardingDemo/demoCall"
import { DateProvider } from "../../utils/time"

export function ManagerRecentCallsPage() {
	const teamManagementResult = useTeamManagement()
	const { t } = useLanguage()

	if (teamManagementResult.isLoading) {
		return (
			<Layout
				pageName="Manager space: calls"
				PageTitle={<Layout.PageTitle title="Manager space: calls" />}
				PageSubtitle={
					<Layout.PageSubtitle
						subtitle={t("Your workspace's calls")}
						description={t(
							"A catalog of all the calls we've retrieved, complete with their analysis, transcripts and scores.",
						)}
					/>
				}
			>
				<div className="flex justify-center items-center h-64">
					<SpinnerIcon className="h-8 w-8 text-gray-400" />
				</div>
			</Layout>
		)
	}

	const { visibleUsers, hasFullAccess } = teamManagementResult

	return <_ManagerRecentCallsPage hasFullAccess={hasFullAccess} visibleUsers={visibleUsers} />
}

function _ManagerRecentCallsPage({ hasFullAccess, visibleUsers }: { hasFullAccess: boolean; visibleUsers: User[] }) {
	const {
		handleSearchFilterChange,
		searchFilter,
		calls,
		totalCalls,
		pageNumber,
		pageSize,
		totalPages,
		handlePageNavigation,
		handleCallAssignationFilterChange,
		refreshCalls,
		callAssignationFilter,
		isInitialLoading: areCallsLoading,
	} = useCalls({
		onlyListCallsFromUserIds: hasFullAccess ? undefined : visibleUsers.map((user) => user.id),
	})

	const { user } = useAuthenticatedSession()
	const { t, language } = useLanguage()
	const shouldDisplayDemoCall =
		totalCalls === 0 && callAssignationFilter.type === "any" && !areCallsLoading && searchFilter === null

	const demoCalls = React.useMemo(() => {
		const dateProvider = new DateProvider()

		return [getDemoCall(user, dateProvider, t, language)]
	}, [t, user, language])

	const sortedWorkspaceUsers = React.useMemo(
		() =>
			visibleUsers?.sort((a, b) => {
				return a.firstName.localeCompare(b.firstName)
			}),
		[visibleUsers],
	)

	if (!user.isWorkspaceManager) {
		console.error("This page is only accessible to workspace managers")
		// runtime check but this is supposed to be checked at the router level
		return null
	}

	if (shouldDisplayDemoCall) {
		return (
			<Layout
				pageName={t("Manager space: calls")}
				PageTitle={<Layout.PageTitle title={t("Manager space: calls")} />}
				PageSubtitle={
					<Layout.PageSubtitle
						subtitle={t("Your workspace's calls")}
						description={t(
							"A catalog of all the calls we've retrieved, complete with their analysis, transcripts and scores.",
						)}
					/>
				}
			>
				<CallsTable
					calls={demoCalls}
					displayAssignedUser={true}
					users={sortedWorkspaceUsers}
					hideOverallScore
				/>
			</Layout>
		)
	}

	return (
		<>
			<Layout
				pageName={t("Manager space: calls")}
				PageTitle={<Layout.PageTitle title={t("Manager space: calls")} />}
				PageSubtitle={
					<Layout.PageSubtitle
						subtitle={hasFullAccess ? t("Your workspace's calls") : t("Your team's calls")}
						description={t(
							"A catalog of all the calls we've retrieved, complete with their analysis, transcripts and scores.",
						)}
					/>
				}
			>
				<div className="flex items-baseline mt-4 gap-2">
					<Input.Group>
						<Input.Label htmlFor="member-filter">{t("Member")}</Input.Label>
						<div className="flex-col">
							<select
								id="member-filter"
								name="member-filter"
								className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
								onChange={handleCallAssignationFilterChange}
							>
								<option value="#any#">{t("All calls")}</option>
								{hasFullAccess && <option value="#unassigned#">{t("Unassigned calls")}</option>}
								{sortedWorkspaceUsers.map((user) => (
									<option key={user.id} value={user.id}>
										{t("Calls assigned to {{fullName}}", {
											fullName: user.isUnclaimed() ? user.email : user.getFullName(),
										})}
									</option>
								))}
							</select>
						</div>
					</Input.Group>
					<CallSearchInput search={searchFilter ?? ""} onChange={handleSearchFilterChange} />
				</div>
				<CallsTable calls={calls} displayAssignedUser={true} users={visibleUsers} refreshCalls={refreshCalls} />
				<PaginationFooter
					navigatePrev={() => handlePageNavigation((page) => Math.max(1, page - 1))}
					navigateNext={() => handlePageNavigation((page) => Math.min(totalPages, page + 1))}
					navigateToPage={(page) => handlePageNavigation(page)}
					currentPage={pageNumber}
					pageSize={pageSize}
					totalCalls={totalCalls}
				/>
			</Layout>
		</>
	)
}
