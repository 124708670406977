import { ArrowRightIcon } from "@heroicons/react/20/solid"
import _ from "lodash"

import type { MyTrainingProgramSummary } from "../../../../core/application/gateways/training.gateway/schemas/my-training-program-summary.schema"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../contexts/language.context"
import { makeMyTrainingProgramsPath } from "../../../router/Router"
import { MyTrainingProgramSummaries } from "../../Training/Programs/components/MyTrainingProgramSummary.component"
import type { MyProgramsState } from "../../Training/Programs/TrainingPrograms.page"
import { _CardWrapper } from "./TodaysProspectingScheduleCard/_CardWrapper.component"

type WeeklyTrainingReminderProps = {
	myProgramsState: MyProgramsState
}

function TrainingSummaryList({ summaries }: { summaries: MyTrainingProgramSummary[] }) {
	const { t } = useLanguage()
	const [completedPrograms, uncompletedPrograms] = _.partition(
		summaries,
		(summary) => summary.items.seen === summary.items.total,
	)

	if (uncompletedPrograms.length === 0 && completedPrograms.length !== 0) {
		return (
			<div className="items-center text-center">
				<p className="px-10 py-5 text-gray-500 ">
					{t("Congratulations, you’re up to date with your training!")}
				</p>
				<TrackingLink
					className="flex flex-row items-center justify-center gap-1 text-gray-900 font-medium"
					to={makeMyTrainingProgramsPath()}
					eventName="Dashboard: View completed programs"
				>
					{t("See my completed training programs")}
					<ArrowRightIcon className="h-4 w-4" />
				</TrackingLink>
			</div>
		)
	}

	return (
		<div className="px-2">
			<MyTrainingProgramSummaries summaries={uncompletedPrograms} />
		</div>
	)
}

export function TrainingReminder({ myProgramsState }: WeeklyTrainingReminderProps) {
	const { t } = useLanguage()

	return (
		<_CardWrapper title={t("My training")} titleTooltip={undefined} className="justify-top flex flex-col">
			{myProgramsState.status === "loading" && (
				<div className="text-gray-500 items-center text-center">
					{/* <p className="px-10 py-5">{t("Loading...")}</p> */}
				</div>
			)}
			{myProgramsState.status === "error" && (
				<div className="text-gray-500 items-center text-center">
					<p className="px-10 py-5">{t("An error occurred")}</p>
				</div>
			)}
			{myProgramsState.status === "success" && myProgramsState.data.length === 0 && (
				<div className="text-gray-500 items-center text-center">
					<p className="px-10 py-5">{t("You have not yet been enrolled in any training programs.")}</p>
				</div>
			)}
			{myProgramsState.status === "success" && myProgramsState.data.length > 0 && (
				<TrainingSummaryList summaries={myProgramsState.data} />
			)}
		</_CardWrapper>
	)
}
