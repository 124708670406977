import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React from "react"

import type {
	GetSalesRelationshipsResponse,
	Relationship,
} from "../../../../core/application/gateways/relationships.gateway"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useLanguage } from "../../../contexts/language.context"
import { DateProvider } from "../../../utils/time"
import { formatCurrency } from "../utils"
import { CallActivityTimeline } from "./CallActivityTimeline.component"

export type IDealsTableProps = {
	relationships: GetSalesRelationshipsResponse["relationships"]
	onOpportunityClick: (opportunityId: string, tabToOpen?: "risks" | "call-activity" | null) => void
}

function TableHeader({ children }: { children: React.ReactNode }) {
	return (
		<th scope="col" className="px-4 py-3.5 text-left text-sm font-normal text-gray-500">
			{children}
		</th>
	)
}

function TableRow({
	children,
	className,
	onClick,
}: {
	children: React.ReactNode
	className?: string
	onClick?: () => void
}) {
	return (
		<td onClick={onClick} className={clsx("px-4 py-4 font-medium text-sm text-navy-700 text-center", className)}>
			{children}
		</td>
	)
}

function OpportunityTableRow({
	relationship,
	children,
	onOpportunityClick,
	className,
}: React.PropsWithChildren<{
	relationship: GetSalesRelationshipsResponse["relationships"][0]
	onOpportunityClick: (opportunityId: string) => void
	className?: string
}>) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent } = setupEventTracking({
		eventName: "Deals table: opportunity click",
	})

	const handleRowClick = React.useCallback(() => {
		if (relationship.opportunity?.crmId) {
			onOpportunityClick(relationship.opportunity.crmId)
			trackEvent()
		}
	}, [onOpportunityClick, relationship.opportunity?.crmId, trackEvent])

	return (
		<TableRow className={clsx("cursor-pointer", className)} onClick={handleRowClick}>
			{children}
		</TableRow>
	)
}

export function DealsTable({ relationships, onOpportunityClick }: IDealsTableProps) {
	const dateProvider = new DateProvider()
	const now = dateProvider.now()
	const { t } = useLanguage()

	const sortedRelationships = relationships.sort((a, b) => {
		return b.lastActivityDate.getTime() - a.lastActivityDate.getTime()
	})

	const handleOnSeePreviousCallsClick = React.useCallback(
		(relationship: Relationship) => {
			if (!relationship.opportunity?.crmId) {
				return
			}

			onOpportunityClick(relationship.opportunity.crmId, "call-activity")
		},
		[onOpportunityClick],
	)

	return (
		<div className="border rounded-md border-gray-100 p-2 mt-4">
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-100">
								<thead>
									<tr className="divide-x divide-gray-100">
										<TableHeader>{t("Deals with Rippletide recordings")}</TableHeader>
										<TableHeader>{t("Call activity")}</TableHeader>
										<TableHeader>{t("Risks")}</TableHeader>
										<TableHeader>{t("Stage")}</TableHeader>
										<TableHeader>{t("Value")}</TableHeader>
										<TableHeader>{t("Closing date")}</TableHeader>
										<TableHeader>{t("Probability")}</TableHeader>
										<TableHeader>{t("Last activity")}</TableHeader>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-100 bg-white">
									{sortedRelationships.map((relationship) => (
										<tr
											key={relationship.id}
											className="divide-x divide-gray-100 hover:bg-slate-50"
										>
											<OpportunityTableRow
												className="!text-left flex flex-col"
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
											>
												<span
													className="truncate max-w-[250px]"
													title={relationship.opportunity?.name ?? ""}
												>
													{relationship.opportunity?.name}
												</span>
												<span
													className="text-sm truncate max-w-[250px]"
													title={relationship.account?.name ?? ""}
												>
													{relationship.account?.name}
												</span>
											</OpportunityTableRow>
											<OpportunityTableRow
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
												className="w-96 min-w-96"
											>
												<div className="pl-2">
													<CallActivityTimeline
														activities={relationship.activities}
														onSeePreviousCallsClick={(e) => {
															e.stopPropagation()
															handleOnSeePreviousCallsClick(relationship)
														}}
														heightPx={56}
														periodToDisplay="45d"
														className="!ml-0"
													/>
												</div>
											</OpportunityTableRow>
											<OpportunityTableRow
												onOpportunityClick={(opp) => onOpportunityClick(opp, "risks")}
												relationship={relationship}
											>
												{relationship.risks?.length ? (
													<div className="text-red-500 flex gap-1 items-center justify-center">
														<ExclamationTriangleIcon className="h-4 w-4" />
														{relationship.risks.length}
													</div>
												) : (
													"-"
												)}
											</OpportunityTableRow>

											<OpportunityTableRow
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
											>
												{relationship.opportunity?.stageName}
											</OpportunityTableRow>
											<OpportunityTableRow
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
											>
												{relationship.opportunity?.amount
													? formatCurrency(relationship.opportunity?.amount)
													: "-"}
											</OpportunityTableRow>
											<OpportunityTableRow
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
												className={clsx(
													relationship.opportunity?.closeDate &&
														relationship.opportunity.closeDate < now
														? "text-red-500"
														: "",
												)}
											>
												{relationship.opportunity?.closeDate?.toLocaleDateString()}
											</OpportunityTableRow>
											<OpportunityTableRow
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
											>
												{relationship.opportunity?.probability
													? `${relationship.opportunity.probability}%`
													: "-"}
											</OpportunityTableRow>
											<OpportunityTableRow
												onOpportunityClick={onOpportunityClick}
												relationship={relationship}
											>
												{relationship.lastActivityDate.toLocaleDateString()}
											</OpportunityTableRow>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
