import type { Call } from "../../../../../core/domain/Call.entity"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { ScoreBadge } from "../../../../components/shared/ScoreBadge.component"
import { TranscriptionQuote } from "../../../../components/shared/TranscriptionQuote.component"
import { useLanguage } from "../../../../contexts/language.context"
import { CallTooShortDisclaimer } from "./_CallTooShortDisclaimer.component"

export type _AnalysisPanelProps = {
	call: Call | null
}

export function _ReviewPanel({ call }: _AnalysisPanelProps) {
	const { t, language } = useLanguage()
	const analysis = call?.props.scoringAnalysis

	if (!analysis) {
		return <SpinnerIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
	}

	return (
		<>
			{call.isShortCall() && (
				<div className="mb-4">
					<CallTooShortDisclaimer />
				</div>
			)}
			<span className="flex items-center">
				<span className="text-lg leading-6 font-medium text-gray-900">{t("Overall score")}</span>

				<ScoreBadge
					score={call?.props.totalScore ?? null}
					maxScore={call?.props.totalMaxScore ?? null}
					className="ml-2"
				/>
			</span>

			<table className="min-w-full divide-y divide-gray-300">
				<thead>
					<tr>
						<th
							scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
						>
							{t("Score")}
						</th>
						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							{t("Phase")}
						</th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200">
					{analysis.metrics.map((metric) => (
						<tr key={metric.label}>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-0">
								<ScoreBadge score={metric.score} maxScore={metric.maxScore} />
							</td>
							<td className="px-3 py-4 text-sm text-gray-600">
								<span className="font-medium">{temporaryI18nMetricLabel(metric.label, language)}</span>
								<br />
								{metric.rationale}
								<br />
								{metric.quotes.map((quote) => (
									<TranscriptionQuote
										key={quote}
										rawQuote={quote}
										call={call}
										trackingComponent="review"
									/>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}

/**
 * TODO: remove this and use the proper i18n library
 */
const englishTranslationByFrenchMetricLabel: Record<string, string> = {
	Introduction: "Introduction",
	Présentation: "Presentation",
	Découverte: "Discovery",
	Reformulation: "Reformulation",
	"Argumentaire et démo solutions": "Argumentation and solution demonstration",
	"Sortie d'argumentaire": "Argumentation exit",
	Conclusion: "Conclusion",
}

function temporaryI18nMetricLabel(label: string, language: string) {
	if (language !== "en") return label

	const translation = englishTranslationByFrenchMetricLabel[label]
	return translation ?? label
}
