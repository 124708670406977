import React, { useRef } from "react"
import Markdown from "react-markdown"

import type { BaseCall } from "../../../../../core/domain/BaseCall.entity"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { MarkdownAnchorRenderer } from "../../../../components/shared/MarkdownLinkRenderer.component"
import { useLanguage } from "../../../../contexts/language.context"

export type _OverviewPanelProps = {
	call: BaseCall
}

export function TodoListItemRenderer({ item }: { item: string }) {
	return (
		<span className="prose-sm prose-headings:my-2 prose-a:text-sky hover:prose-a:text-blue-500">
			<Markdown
				components={{
					a: MarkdownAnchorRenderer,
					p: "span",
				}}
			>
				{item}
			</Markdown>
		</span>
	)
}

export function _OverviewPanel({ call }: _OverviewPanelProps) {
	const { t } = useLanguage()

	if (!call.props.nextBestActionSuggestion) {
		return <SpinnerIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
	}
	const { keyPoints, notes, email } = call.props.nextBestActionSuggestion

	return (
		<div className="">
			<h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t("Key points")}</h3>
			<ul className="list-disc list-inside">
				{keyPoints.map((keyPoint) => (
					<li key={keyPoint} className="mt-2 text-sm text-gray-600">
						{keyPoint}
					</li>
				))}
			</ul>
			<h3 className="text-lg leading-6 font-medium text-gray-900 mt-6">{t("To-do list")}</h3>
			<ul className="list-disc list-inside mt-3">
				{notes.map((note) => (
					<li key={note} className="mt-2 text-sm text-gray-600">
						<TodoListItemRenderer item={note} />
					</li>
				))}
			</ul>
			{email && (
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900 mt-6">{t("Suggested email")}</h3>
					<div className="text-sm text-gray-500 mt-3">
						<ReadOnlyTextArea callId={call.props.id} text={email} />
					</div>
				</>
			)}
		</div>
	)
}

type ReadOnlyTextAreaProps = {
	callId: string
	text: string
}

const ReadOnlyTextArea: React.FC<ReadOnlyTextAreaProps> = ({ text, callId }) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const { t } = useLanguage()
	const handleCopy = () => {
		if (textareaRef.current) {
			textareaRef.current.select()
			document.execCommand("copy")
		}
	}

	return (
		<div className="flex flex-col space-y-2">
			<textarea
				ref={textareaRef}
				readOnly
				value={text}
				className="p-2 border border-gray-300 rounded-md w-full resize-none"
				rows={20}
			/>
			<TrackingButton
				eventName="NextBestAction email copied"
				eventProperties={{
					callId,
					textLength: text.length,
				}}
				onClick={handleCopy}
				className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
			>
				{t("Copy email")}
			</TrackingButton>
		</div>
	)
}
