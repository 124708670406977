import { InformationCircleIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

import { Tooltip } from "./Tooltip.component"

export type CardProps = {
	children: React.ReactNode
	title: React.ReactNode
	CTA?: React.ReactNode
	className?: string
	titleTooltip?: string
}

export function Card({ children, title, CTA, className, titleTooltip }: CardProps) {
	return (
		<div className={clsx("lg:min-w-0 lg:flex-1 border border-gray-200 rounded-xl bg-white", className)}>
			<div className="border-b border-gray-200 sm:px-6 px-2 py-4">
				<div className="flex items-center justify-between">
					<h1 className="flex-1 sm:text-xl font-medium flex gap-1 items-center">
						{title}
						{titleTooltip && (
							<Tooltip content={titleTooltip}>
								<InformationCircleIcon className="h-5 w-5 ml-1 text-gray-400" />
							</Tooltip>
						)}
					</h1>
					{CTA}
				</div>
			</div>
			{children}
		</div>
	)
}
