import React from "react"

import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity"
import { Alert } from "../../../../components/design-system/Alert.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { CalendarSyncButtons } from "../../../../components/shared/CalendarSyncButtons.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useCalendarContext } from "../../../../contexts/calendar.context"
import { useLanguage } from "../../../../contexts/language.context"
import { getCalendarPlatformDisplayName } from "../../../../utils/calendarName"
import { makeDemoCalendarEvent } from "../../../../utils/onboardingDemo/demoCalendarEvent"
import { DateProvider } from "../../../../utils/time"
import { _CardWrapper, _PaddedCardWrapper } from "./_CardWrapper.component"
import { DemoEventList } from "./DemoEventList.component"
import { EmptyProspectingSchedule } from "./EmptyProspectingSchedule.component"
import { EventList } from "./EventList.component"

type IUpcomingDaysProspectingScheduleCardProps = {
	hasRecentCalls: boolean
	areCallsLoading: boolean
	renderCard: 1 | 2 | "both"
}

export function UpcomingDaysProspectingScheduleCard({
	hasRecentCalls,
	areCallsLoading,
	renderCard,
}: IUpcomingDaysProspectingScheduleCardProps) {
	const { t } = useLanguage()
	const { calendarAndEvents } = useCalendarContext()

	const todaysProspectingScheduleTitle = t("Today's prospecting schedule")
	const todaysProspectingScheduleTitleTooltip = t(
		"Your upcoming meetings for today will be displayed here only when they have a video call URL and at least one attendee from outside your organisation.",
	)

	if (calendarAndEvents.error) {
		if (renderCard === 2) return
		return (
			<_CardWrapper title={todaysProspectingScheduleTitle} titleTooltip={todaysProspectingScheduleTitleTooltip}>
				<Alert
					title={t("Something went wrong")}
					description={t("Couldn't load your calendar events. Please try again later.")}
					className="m-4"
				/>
			</_CardWrapper>
		)
	}

	const isCalendarBeingSynchronized = Boolean(
		!calendarAndEvents.data ||
			(calendarAndEvents.data.calendar.status === "connected" &&
				!calendarAndEvents.data.calendar.wasEverSynced) ||
			calendarAndEvents.data.calendar.status === "connecting",
	)

	if (!calendarAndEvents.data || isCalendarBeingSynchronized) {
		if (renderCard === 2) return
		return (
			<_PaddedCardWrapper
				title={todaysProspectingScheduleTitle}
				titleTooltip={todaysProspectingScheduleTitleTooltip}
			>
				<SpinnerIcon className="h-8 w-8 mx-auto" />
				<p className="font-medium mt-2 text-gray-500">
					{t("Syncing your calendar, your upcoming meetings will be displayed here momentarily…")}
				</p>
			</_PaddedCardWrapper>
		)
	}

	const { calendar, calendarEvents } = calendarAndEvents.data

	if (calendarEvents.length) {
		/**
		 * If there are events to show, show them (as soon as the list is finite -not actively syncing- otherwise user might wonder where are the missing events)
		 */
		// TODO: bad condition: DisplayEvents does filter on the event time range, it should not
		return (
			<DisplayEvents
				calendarEvents={calendarEvents}
				hasRecentCalls={hasRecentCalls}
				areCallsLoading={areCallsLoading}
				renderCard={renderCard}
			/>
		)
	}

	if (calendar.status === "disconnected") {
		const description = t("Your {{calendarPlatform}} integration was disconnected, please reconnect it below.", {
			calendarPlatform: getCalendarPlatformDisplayName(calendar.platform),
		})
		if (renderCard === 2) return
		return (
			<_PaddedCardWrapper
				title={todaysProspectingScheduleTitle}
				titleTooltip={todaysProspectingScheduleTitleTooltip}
			>
				<Alert description={description} className="m-4" />
				<CalendarSyncButtons
					calendar={calendar}
					reconnect={calendar.status === "disconnected" ? calendar.platform : undefined}
				/>
			</_PaddedCardWrapper>
		)
	}

	// When the calendar is not connected, we show the empty state.
	// If the user has recent calls (or if the calls are loading), we do not display the demo event.
	if (hasRecentCalls || areCallsLoading) {
		if (renderCard === 2) return
		return (
			<_PaddedCardWrapper
				title={todaysProspectingScheduleTitle}
				titleTooltip={todaysProspectingScheduleTitleTooltip}
			>
				<EmptyProspectingSchedule dateRange="today" />
			</_PaddedCardWrapper>
		)
	}

	// No calendar connected and no recent calls so we show the demo event
	if (renderCard === 2) return
	return (
		<_CardWrapper
			title={todaysProspectingScheduleTitle}
			titleTooltip={todaysProspectingScheduleTitleTooltip}
			className="flex flex-col"
		>
			<DemoEventList />
		</_CardWrapper>
	)
}

function DisplayEvents({
	calendarEvents,
	hasRecentCalls,
	areCallsLoading,
	renderCard,
}: {
	calendarEvents: CalendarEvent[]
	hasRecentCalls: boolean
	areCallsLoading: boolean
	renderCard: 1 | 2 | "both"
}) {
	const { t, language } = useLanguage()
	const todaysProspectingScheduleTitle = t("Today's prospecting schedule")
	const todaysProspectingScheduleTitleTooltip = t(
		"Your upcoming meetings for today will be displayed here only when they have a video call URL and at least one attendee from outside your organisation.",
	)
	const { user } = useAuthenticatedSession()
	const dateProvider = new DateProvider()
	const demoCalendarEvents = React.useMemo(
		() => [makeDemoCalendarEvent(user, new DateProvider(), t, language)],
		[language, t, user],
	)

	// todo: filtering should happen on back-end
	const ongoingOrFutureEvents = calendarEvents.filter((event) => event.endTime > dateProvider.now())
	const todaysEvents = ongoingOrFutureEvents.filter((event) => dateProvider.isToday(event.startTime))
	const twoWorkingDaysFromNow = dateProvider.addWorkingDays(dateProvider.now(), 2)
	const upcomingDaysEvents = ongoingOrFutureEvents.filter(
		(event) =>
			!dateProvider.isToday(event.startTime) && event.startTime.getTime() < twoWorkingDaysFromNow.getTime(),
	)
	const shouldDisplayDemoEvent = !hasRecentCalls && !areCallsLoading

	if (!todaysEvents.length && shouldDisplayDemoEvent) {
		if (renderCard === 2) return
		return (
			<_CardWrapper
				title={todaysProspectingScheduleTitle}
				titleTooltip={todaysProspectingScheduleTitleTooltip}
				className="flex flex-col"
			>
				<DemoEventList />
			</_CardWrapper>
		)
	}

	const upcomingDaysTooltip = t(
		"Your meetings for the next 2 working days will be displayed here only when they have a video call URL and at least one attendee from outside your organisation.",
	)
	if (!todaysEvents.length && !upcomingDaysEvents.length) {
		if (renderCard === 2) return
		return (
			<_PaddedCardWrapper title={t("Upcoming days")} titleTooltip={upcomingDaysTooltip}>
				<EmptyProspectingSchedule dateRange="upcoming-days" />
			</_PaddedCardWrapper>
		)
	}

	return (
		<>
			{renderCard === 2 ? undefined : todaysEvents.length ? (
				<_CardWrapper
					title={todaysProspectingScheduleTitle}
					titleTooltip={todaysProspectingScheduleTitleTooltip}
				>
					<EventList
						events={shouldDisplayDemoEvent ? [...demoCalendarEvents, ...todaysEvents] : todaysEvents}
					/>
				</_CardWrapper>
			) : (
				<_PaddedCardWrapper
					title={todaysProspectingScheduleTitle}
					titleTooltip={todaysProspectingScheduleTitleTooltip}
				>
					<EmptyProspectingSchedule dateRange="today" />
				</_PaddedCardWrapper>
			)}
			{(renderCard === 2 || renderCard === "both") && upcomingDaysEvents.length > 0 && (
				<_CardWrapper title={t("Upcoming days")} titleTooltip={upcomingDaysTooltip}>
					<EventList events={upcomingDaysEvents} />
				</_CardWrapper>
			)}
		</>
	)
}
