import { pdfjs } from "react-pdf"
import { BrowserRouter } from "react-router-dom"

import { AnalyticsProvider } from "./contexts/analytics.context"
import { AuthenticationProvider } from "./contexts/authentication.context"
import { DependenciesProvider } from "./contexts/dependencies.context"
import { LanguageProvider } from "./contexts/language.context"
import { RelationshipsProvider } from "./contexts/relationships.context"
import { WorkspaceUsersProvider } from "./contexts/workspace-users.context"
import { Router } from "./router/Router"
import { SupportWidget } from "./SupportWidget"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export function App() {
	return (
		<DependenciesProvider>
			<AnalyticsProvider>
				<BrowserRouter>
					<AuthenticationProvider>
						<LanguageProvider>
							<WorkspaceUsersProvider>
								<RelationshipsProvider>
									<Router />
									<SupportWidget />
								</RelationshipsProvider>
							</WorkspaceUsersProvider>
						</LanguageProvider>
					</AuthenticationProvider>
				</BrowserRouter>
			</AnalyticsProvider>
		</DependenciesProvider>
	)
}
