import clsx from "clsx"
import React from "react"

import type { ListAllDealsResponse } from "../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { InsightCard } from "../../../components/shared/InsightCard.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { ConnectYourCrmOverlay } from "../components/ConnectYourCrmOverlay.component"
import { CrmUpgradePlanOverlay } from "../components/CrmUpgradePlanOverlay.component"
import { DealCard } from "../components/DealCard.component"
import { DealFilters } from "../components/DealFilters.component"
import type { DealsDemoState } from "../Deals.page"
import { makeDemoData } from "../demo"
import { useDealFilters } from "../useDealFilters"
import { formatCurrency } from "../utils"

type IDealsBoardViewProps = {
	demoState?: DealsDemoState
}

export function DealsBoardView({ demoState }: IDealsBoardViewProps) {
	const { t } = useLanguage()
	const { dealsGateway } = useDependencies()
	const [deals, setDeals] = React.useState<ListAllDealsResponse | null>(null)
	const [isFetching, setIsFetching] = React.useState(true)
	const { user } = useAuthenticatedSession()

	const {
		selectedClosingDate,
		selectedChanges,
		selectedDeals,
		selectedOwner,
		onSelectClosingDate,
		onSelectDeals,
		onSelectOwner,
		onSelectChanges,
		filter,
		dealsToShow,
		displayChangesInTheLastNDays,
	} = useDealFilters()

	React.useEffect(() => {
		async function fetchDeals() {
			if (demoState) {
				const shouldAddRandomWait = demoState === "fake-data"
				if (shouldAddRandomWait) {
					const randomWait = 500 + Math.random() * 3000
					await new Promise((resolve) => setTimeout(resolve, randomWait))
				}
				setDeals(makeDemoData(filter))
				return
			}
			const adjustedClosingDateEnd = filter.closingDateEnd
			const adjustedClosingDateStart = filter.closingDateStart
			if (adjustedClosingDateEnd) {
				adjustedClosingDateEnd.setHours(23, 59, 59, 999)
			}

			if (adjustedClosingDateStart) {
				adjustedClosingDateStart.setHours(0, 0, 0, 0)
			}

			const deals = await dealsGateway.listAllDeals({
				...filter,
				closingDateStart: adjustedClosingDateStart,
				closingDateEnd: adjustedClosingDateEnd,
			})
			setDeals(deals)
		}

		setIsFetching(true)
		fetchDeals().finally(() => setIsFetching(false))
	}, [dealsGateway, demoState, filter])

	if (!deals) {
		return (
			<div className="flex items-center justify-center flex-1">
				<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
			</div>
		)
	}

	const totalAmountOpenDeals = deals.stages
		.filter((stage) => !stage.isClosed)
		.reduce((acc, stage) => acc + stage.totalAmount, 0)

	const totalOpenDealsCount = deals.stages
		.filter((stage) => !stage.isClosed)
		.reduce((acc, stage) => acc + stage.dealCount, 0)

	const averageDealSize = totalAmountOpenDeals / totalOpenDealsCount

	const wonStages = deals.stages.filter((stage) => stage.isWon)
	const totalAmountWonDeals = wonStages.reduce((acc, stage) => acc + stage.totalAmount, 0)
	const totalWonDealsCount = wonStages.reduce((acc, stage) => acc + stage.dealCount, 0)
	const averageWonDealSize = totalWonDealsCount ? totalAmountWonDeals / totalWonDealsCount : 0

	const lostStages = deals.stages.filter((stage) => stage.isLost)
	const totalAmountLostDeals = lostStages.reduce((acc, stage) => acc + stage.totalAmount, 0)
	const totalLostDealsCount = lostStages.reduce((acc, stage) => acc + stage.dealCount, 0)
	const averageLostDealSize = totalLostDealsCount ? totalAmountLostDeals / totalLostDealsCount : 0

	const dealsAtRisk = deals.deals.filter((deal) => deal.risks?.length)
	const totalAmountDealsAtRisk = deals.stages.reduce((acc, stage) => acc + stage.atRiskAmount, 0)
	const dealsToDisplay = dealsToShow === "all" ? deals.deals : dealsAtRisk

	return (
		<>
			<div className="relative">
				{isFetching && (
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
					</div>
				)}

				{demoState === "upgrade-required" && <CrmUpgradePlanOverlay />}
				{demoState === "no-crm" && <ConnectYourCrmOverlay />}

				<div className={clsx(isFetching && "opacity-50")}>
					<div className="flex justify-between items-baseline xl:flex-row flex-col">
						<div className="mt-6 flex gap-3 flex-wrap sm:flex-nowrap justify-center mb-6 sm:mb-0">
							<InsightCard
								title={t("Active deals")}
								subtitle={
									totalAmountDealsAtRisk ? `${formatCurrency(totalAmountDealsAtRisk)} at risk` : ""
								}
								subtitleClassName="text-red-500 font-medium"
								values={[
									{
										value: `${formatCurrency(totalAmountOpenDeals)} (${totalOpenDealsCount})`,
										label: `${formatCurrency(averageDealSize)} ${t("avg")}`,
										key: "active-deals",
									},
								]}
							/>

							<InsightCard
								title={t("Won deals")}
								subtitle={t("in the last {{count}} days", { count: displayChangesInTheLastNDays })}
								subtitleClassName="text-indigo-500 font-medium"
								values={[
									{
										value: `${formatCurrency(totalAmountWonDeals)} (${totalWonDealsCount})`,
										label: `${formatCurrency(averageWonDealSize)} avg`,
										key: "won-deals",
									},
								]}
							/>

							<InsightCard
								title={t("Lost deals")}
								subtitle={t("in the last {{count}} days", { count: displayChangesInTheLastNDays })}
								subtitleClassName="text-indigo-500 font-medium"
								values={[
									{
										value: `${formatCurrency(totalAmountLostDeals)} (${totalLostDealsCount})`,
										label: `${formatCurrency(averageLostDealSize)} ${t("avg")}`,
										key: "lost-deals",
									},
								]}
							/>
						</div>

						<DealFilters
							dealOwners={deals.owners.map((owner) => ({
								id: owner.id,
								name: owner.fullName ?? owner.username,
							}))}
							selectedDeals={selectedDeals}
							onSelectDeals={onSelectDeals}
							selectedOwner={selectedOwner}
							onSelectOwner={onSelectOwner}
							selectedClosingDate={selectedClosingDate}
							onSelectClosingDate={onSelectClosingDate}
							selectedChanges={selectedChanges}
							onSelectChanges={onSelectChanges}
							disabled={isFetching}
							canSelectOwner={user.isWorkspaceManager()}
						/>
					</div>
					<div className="flex gap-4 overflow-x-auto mt-6">
						{deals.stages.map(
							({
								stage,
								isLost,
								isClosed,
								isWon,
								percentageAmountRelativeToAllOpenDeals,
								totalAmount: totalAmountInStage,
								dealCount: totalDealCountInStage,
							}) => {
								const dealsInStage = dealsToDisplay.filter((deal) => deal.stageName === stage)

								return (
									<div className="min-w-[180px] flex flex-col" key={stage}>
										<div
											className={clsx(
												"flex flex-col rounded-md text-white font-medium p-4 text-sm w-full h-[72px] items-center justify-center",
												isClosed ? "bg-indigo-500" : "bg-navy-700",
											)}
										>
											<h2>
												{stage} ({totalDealCountInStage})
											</h2>
											{dealsInStage.length !== totalDealCountInStage && (
												<span className="text-xs italic text-gray-400">
													{" "}
													({t("{{count}} shown", { count: dealsInStage.length })})
												</span>
											)}
										</div>
										<span
											className={clsx(
												"flex justify-center items-center text-sm text-gray-600 italic mt-2",
												isWon && "text-green-700 font-medium",
												isLost && "text-red-700 font-medium",
											)}
										>
											{isClosed
												? `${formatCurrency(totalAmountInStage)}`
												: `${formatCurrency(
														totalAmountInStage,
												  )} (${percentageAmountRelativeToAllOpenDeals}%)`}
										</span>
										<div className="bg-white flex flex-col gap-2 w-full mt-4 max-h-[800px] overflow-y-auto">
											{dealsInStage.map((deal) => (
												<DealCard
													key={deal.id}
													deal={deal}
													instanceUrl={deals.instanceUrl}
													highlightChangesInTheLastNDays={displayChangesInTheLastNDays}
												/>
											))}
										</div>
									</div>
								)
							},
						)}
					</div>
				</div>
			</div>
		</>
	)
}
