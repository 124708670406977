import { Dialog } from "@headlessui/react"
import React from "react"
import { useSearchParams } from "react-router-dom"

import { Modal } from "../../../../components/design-system/Modal.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

const calendarConnectedQueryParamName = "calendarConnected"

export function CalendarSyncedModal() {
	const { t } = useLanguage()
	const [searchParams, setSearchParams] = useSearchParams()
	const isModalOpen = searchParams.get(calendarConnectedQueryParamName) === "true"

	const handleCloseModal = React.useCallback(() => {
		const url = new URL(window.location.toString())
		url.searchParams.delete(calendarConnectedQueryParamName)
		setSearchParams(url.searchParams)
	}, [setSearchParams])

	return (
		<Modal open={isModalOpen} showCloseButton={false}>
			<div className="px-4">
				<Dialog.Title as="h3" className="text-xl text-center font-bold leading-6 text-gray-700 mb-4">
					{t("You’re ready to go!")}
				</Dialog.Title>
				<p>{t("Rippletide is now set to record your upcoming prospecting calls.")}</p>
				<p className="my-2">{t("All you need to do is:")}</p>

				<div className="text-gray-700">
					<ul className="text-sm list-decimal ml-6">
						<li className="py-1">
							<p className="font-semibold">{t("See your upcoming calls scheduled on your dashboard")}</p>
						</li>
						<li className="py-1">
							<p className="font-semibold">{t("Connect to the call")}</p>
						</li>
						<li className="py-1">
							<p className="font-semibold">{t("Accept Rippletide’s request to join the meeting")}</p>
						</li>
						<li className="py-1">
							<p className="font-semibold">
								{t("Navigate your call with confidence and let us do the rest!")}
							</p>
						</li>
					</ul>

					<p className="mt-2">{t("We can't wait to transform how you navigate the sales waves together.")}</p>

					<div className="flex justify-center items-center mt-4">
						<TrackingButton
							onClick={handleCloseModal}
							eventName="Calendar synced modal: get started clicked"
							className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							{t("Get started")}
						</TrackingButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}
