import clsx from "clsx"
import React from "react"

type ITooltipProps = React.PropsWithChildren<{
	content: React.ReactNode
	tooltipClassName?: string
	wrapperClassName?: string
	wrapperType?: "div" | "span"
}>

export function Tooltip({
	content,
	children,
	tooltipClassName,
	wrapperClassName,
	wrapperType: WrapperType = "div",
}: ITooltipProps) {
	return (
		<WrapperType className={clsx("group/tooltip relative flex", wrapperClassName)}>
			{children}
			{content && (
				<span
					className={clsx(
						"absolute top-6 min-w-[150px] sm:min-w-[300px] z-20 scale-0 rounded bg-opacity-70 bg-black p-2 text-xs text-white group-hover/tooltip:scale-100",
						tooltipClassName,
					)}
				>
					{content}
				</span>
			)}
		</WrapperType>
	)
}
