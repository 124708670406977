import assertNever from "assert-never"
import { z } from "zod"

import type { TranslationKey } from "../../app/contexts/language.context"
import { enrichedAccountSchema, enrichedContactSchema } from "../application/gateways/platform-admin.gateway"

export const calendarEventBriefSchema = z.object({
	version: z.number(),
	result: z.string(),
})

export type CalendarEventBrief = z.infer<typeof calendarEventBriefSchema>

export const CalendarEventEnrichmentSchema = z.object({
	attendees: z
		.array(
			z
				.object({
					email: z.string(),
				})
				.merge(enrichedContactSchema),
		)
		.optional(),
	accounts: z
		.array(
			z
				.object({
					companyDomain: z.string(),
				})
				.merge(enrichedAccountSchema),
		)
		.optional(),
})

export type CalendarEventEnrichment = z.infer<typeof CalendarEventEnrichmentSchema>

export type CalendarEventAttendeeEnrichment = NonNullable<CalendarEventEnrichment["attendees"]>[number]
export type CalendarEventAccountEnrichment = NonNullable<CalendarEventEnrichment["accounts"]>[number]

/**
 * @backend HttpPresentedCalendarEvent
 */
export type CalendarEvent = {
	id: string
	startTime: Date
	endTime: Date
	title: string
	meetingUrl: string
	botStatus: BotStatus
	briefing?: CalendarEventBrief
	isDemo?: boolean
}

/**
 * @backend HttpPresentedBotStatus
 */
export type BotStatus = {
	code: RecordingStatus
	canSendBotToMeetingNow: boolean
	botWillJoinAt?: Date | undefined
}

export enum RecordingStatus {
	NoBot = "NoBot", // end user message depends on event.endTime
	ScheduledBot = "ScheduledBot", // end user message depends on event.willJoinAt
	Connecting = "Connecting",
	WaitingRoom = "WaitingRoom",
	WillRecord = "WillRecord",
	Recording = "Recording",
	InternalError_BotFailure = "InternalError_BotFailure",
	InternalError_StatusUnknown = "InternalError_StatusUnknown",
	CallEnded_KickedFromCall = "CallEnded_KickedFromCall",
	CallEnded_KickedFromWaitingRoom = "CallEnded_KickedFromWaitingRoom",
	CallEnded_BotReceivedLeaveCall = "CallEnded_BotReceivedLeaveCall",
	CallEnded_ByHost = "CallEnded_ByHost",
	CallEnded_Timeout_WaitingRoom = "CallEnded_Timeout_WaitingRoom",
	CallEnded_Timeout_Inactivity = "CallEnded_Timeout_Inactivity",
	CallEnded_Unknown = "CallEnded_Unknown",
	Done_WithoutVideo = "Done_WithoutVideo",
	Done_RecordingAvailable = "Done_RecordingAvailable",
	Done_ProcessingRecording = "Done_ProcessingRecording",
	Done_VideoProcessingFailed = "Done_VideoProcessingFailed",
}

/**
 * Every message should have a matching i18n translation
 */
export const UIMessages = [
	"Event was not recorded",
	"Event will not be recorded",
	"Internal error",
	"Rippletide will request access to the meeting soon",
	"Rippletide is scheduled to join the meeting",
	"Rippletide is connecting to the meeting…",
	"Rippletide is waiting to be accepted into the meeting…",
	"Rippletide will start recording soon…",
	"Rippletide is recording",
	"Rippletide was removed from the call",
	"Rippletide’s request to join the meeting was rejected",
	"Rippletide was not accepted into the meeting in time",
	"Rippletide is processing the meeting…",
	"No recording available",
	"Recording available",
	"Processing recording…",
	"Video processing failed",
] as const

type UIMessage = (typeof UIMessages)[number]

/** Ensure all messages are defined in translation.json */
type _StaticAssert = AssertAssignable<TranslationKey, UIMessage>

type RecordingStatusDetail = {
	/**
	 * Message displayed in the UI
	 */
	message: UIMessage
	/**
	 * Whether the status will not change anymore
	 */
	isDefinitive?: true
	/**
	 * Whether this status represents an error state
	 */
	isError?: true
	/**
	 * Whether the status is a success state
	 */
	isSuccess?: true
	/**
	 * Error description to be logged to console
	 */
	errorDescription?: string
	/**
	 * Whether a status change is imminent and the event should be refreshed faster than the 30-seconds autorefresh
	 */
	fastRefresh?: boolean
}

type GetRecordingStatusParams = {
	now: Date
	botWillJoinAt: Date | undefined
	eventHasEnded: boolean
	eventStartTime: Date
}

/**
 * Recording status is decided by backend's BotStatusInterpreter
 */
export function getRecordingStatusDetails(
	status: RecordingStatus,
	{ eventHasEnded, botWillJoinAt, now, eventStartTime }: GetRecordingStatusParams,
): RecordingStatusDetail {
	switch (status) {
		case RecordingStatus.NoBot:
			if (eventHasEnded) {
				return {
					message: "Event was not recorded",
					isDefinitive: true,
				}
			} else {
				return {
					message: "Event will not be recorded",
					isDefinitive: true, // not exactly definitive, but close enough to show it as such in the UI
				}
			}

		case RecordingStatus.ScheduledBot:
			if (!botWillJoinAt) {
				return {
					message: "Internal error",
					isDefinitive: true,
					isError: true,
					errorDescription: "Scheduled bot will not join",
				}
			}
			if (botWillJoinAt.getTime() !== eventStartTime.getTime()) {
				return {
					message: "Internal error",
					isError: true,
					errorDescription: `Bot will join at ${botWillJoinAt} but events starts at ${eventStartTime}`,
				}
			}
			if (isDateWithinNextMinute(botWillJoinAt, now)) {
				return { message: "Rippletide is scheduled to join the meeting", fastRefresh: true }
			}
			if (isDateInFuture(botWillJoinAt, now)) {
				return { message: "Rippletide is scheduled to join the meeting" }
			}
			if (isDateInPastTwoMinutes(botWillJoinAt, now)) {
				return { message: "Rippletide will request access to the meeting soon", fastRefresh: true }
			}
			return {
				message: "Internal error",
				isDefinitive: true,
				isError: true,
				errorDescription: `Bot was supposed to join at ${botWillJoinAt}`,
			}

		case RecordingStatus.Connecting:
			return { message: "Rippletide is connecting to the meeting…", fastRefresh: true }

		case RecordingStatus.WaitingRoom:
			return { message: "Rippletide is waiting to be accepted into the meeting…", fastRefresh: true }

		case RecordingStatus.WillRecord:
			return { message: "Rippletide will start recording soon…", fastRefresh: true }

		case RecordingStatus.Recording:
			return { message: "Rippletide is recording" }

		case RecordingStatus.InternalError_BotFailure:
			return { message: "Internal error", isDefinitive: true, isError: true, errorDescription: "Bot failure" }

		case RecordingStatus.InternalError_StatusUnknown:
			return { message: "Internal error", isDefinitive: true, isError: true, errorDescription: "Unknown status" }

		case RecordingStatus.CallEnded_KickedFromCall:
			return { message: "Rippletide was removed from the call", fastRefresh: true }

		case RecordingStatus.CallEnded_KickedFromWaitingRoom:
			return { message: "Rippletide’s request to join the meeting was rejected", isDefinitive: true }

		case RecordingStatus.CallEnded_BotReceivedLeaveCall:
			return {
				message: "Internal error",
				isDefinitive: true,
				isError: true,
				errorDescription: "Bot was instructed to leave by API",
			}

		case RecordingStatus.CallEnded_ByHost:
			return { message: "Rippletide is processing the meeting…", fastRefresh: true }

		case RecordingStatus.CallEnded_Timeout_WaitingRoom:
			return { message: "Rippletide was not accepted into the meeting in time", isDefinitive: true }

		case RecordingStatus.CallEnded_Timeout_Inactivity:
			return { message: "Rippletide is processing the meeting…", fastRefresh: true }

		case RecordingStatus.CallEnded_Unknown:
			return { message: "Rippletide is processing the meeting…", fastRefresh: true }

		case RecordingStatus.Done_WithoutVideo:
			return { message: "No recording available", isDefinitive: true }

		case RecordingStatus.Done_RecordingAvailable:
			return { message: "Recording available", isSuccess: true }

		case RecordingStatus.Done_ProcessingRecording:
			return { message: "Rippletide is processing the meeting…", fastRefresh: true }

		case RecordingStatus.Done_VideoProcessingFailed:
			return { message: "Video processing failed", isDefinitive: true, isError: true, errorDescription: "n/a" }

		default:
			assertNever(status, true) as unknown
			return {
				message: "Internal error",
				isDefinitive: true,
				isError: true,
				errorDescription: "Corrupt backend response",
			}
	}
}

function isDateInFuture(date: Date, now = new Date()): boolean {
	return date > now
}

function isDateWithinNextMinute(date: Date, now = new Date()): boolean {
	const oneMinuteLater = new Date(now.getTime() + 60 * 1000)
	return date > now && date <= oneMinuteLater
}

function isDateInPastTwoMinutes(date: Date, now = new Date()): boolean {
	const twoMinutesAgo = new Date(now.getTime() - 2 * 60 * 1000) // two minutes in milliseconds
	return date >= twoMinutesAgo && date <= now
}
