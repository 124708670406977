import React from "react"

import type { Relationship } from "../../core/application/gateways/relationships.gateway"
import { useQueryParamsState } from "../hooks/useQueryParamsState"
import { useDependencies } from "./dependencies.context"

type IRelationshipsContext = {
	showRelationshipDetailsByOpportunityId: (
		opportunityId: string,
		tabToOpen?: "risks" | "call-activity" | null,
	) => void
	hideRelationshipDetails: () => void
	currentRelationship: Relationship | null
	isLoading: boolean
	defaultTabToOpen: "risks" | "call-activity" | null
}

const RelationshipsContext = React.createContext<IRelationshipsContext | undefined>(undefined)

export function RelationshipsProvider({ children }: { children: React.ReactNode }) {
	const [currentRelationship, setCurrentRelationship] = React.useState<Relationship | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const [opportunityId, setOpportunityId] = useQueryParamsState<string | null>("crmOpportunityId", null)
	const [defaultTabToOpen, setDefaultTabToOpen] = React.useState<"risks" | "call-activity" | null>(null)

	const { relationshipsGateway } = useDependencies()

	React.useEffect(() => {
		if (!opportunityId) {
			return
		}

		setIsLoading(true)
		relationshipsGateway
			.getRelationshipByOpportunityId(opportunityId)
			.then((r) => setCurrentRelationship(r))
			.finally(() => setIsLoading(false))
	}, [opportunityId, relationshipsGateway])

	const hideRelationshipDetails = React.useCallback(() => {
		setIsLoading(false)
		setCurrentRelationship(null)
		setOpportunityId(null)
	}, [setOpportunityId])

	const showRelationshipDetailsByOpportunityId = React.useCallback(
		(newOpportunityId: string, tabToOpen: "risks" | "call-activity" | null = null) => {
			setOpportunityId(newOpportunityId)
			setDefaultTabToOpen(tabToOpen)
		},
		[setOpportunityId],
	)

	return (
		<RelationshipsContext.Provider
			value={{
				showRelationshipDetailsByOpportunityId,
				currentRelationship,
				isLoading,
				hideRelationshipDetails,
				defaultTabToOpen,
			}}
		>
			{children}
		</RelationshipsContext.Provider>
	)
}

export function useRelationshipsContext() {
	const context = React.useContext(RelationshipsContext)
	if (!context) {
		throw new Error("useRelationshipsContext must be used within a RelationshipsProvider")
	}
	return context
}
