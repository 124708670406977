import type { LeadKnowledgeV2, LeadKnowledgeV2Contact } from "../../../../../../../core/domain/Call.entity"
import { DecisionMakingAuthorityBadge } from "../../../../../../components/shared/DecisionMakingAuthorityBadge"
import { useLanguage } from "../../../../../../contexts/language.context"

export type IContactsSectionProps = {
	contacts: NonNullable<LeadKnowledgeV2["contacts"]>
}

function PersonalityProfileSection({
	personalityProfile,
}: {
	personalityProfile: LeadKnowledgeV2Contact["personalityProfile"]
}) {
	if (!personalityProfile) {
		return null
	}
	return (
		<div className="flex flex-col">
			<span>{personalityProfile.summary}</span>

			{/* <div className="w-full h-[350px]">
				<ResponsiveContainer width="100%" height="100%">
					<RadarChart outerRadius="80%" data={radarChartData}>
						<PolarGrid />
						<PolarAngleAxis dataKey="trait" />
						<Radar dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
					</RadarChart>
				</ResponsiveContainer>
			</div> */}
		</div>
	)
}

/**
 * TODO: UI for:
 * - preferredCommunicationChannel
 */
export function ContactsSection({ contacts }: IContactsSectionProps) {
	const { t } = useLanguage()

	return (
		<div>
			<h4 className="whitespace-nowrap mb-2 pl-4 pr-3 text-lg font-bold text-gray-900 sm:pl-0">
				{t("Contacts")}
			</h4>
			<ul role="list" className="">
				{contacts.map((contact, index) => {
					const fullName = [contact.firstName, contact.lastName].filter(Boolean).join(" ").trim()
					return (
						<li key={contact?.attendeeId + index} className="mt-4">
							<div className="flex-auto">
								<div className="flex gap-2 items-center">
									<h3 className="font-semibold leading-8 tracking-tight text-gray-900">{fullName}</h3>
									{contact.decisionMakingAuthority && (
										<DecisionMakingAuthorityBadge
											decisionMakingAuthority={contact.decisionMakingAuthority}
										/>
									)}
								</div>

								{contact.jobTitle && (
									<p className="text-base leading-7 text-gray-700 font-semibold italic">
										{contact.jobTitle}
									</p>
								)}
								<PersonalityProfileSection personalityProfile={contact.personalityProfile} />
							</div>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
