import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import clsx from "clsx"
import { Fragment } from "react"

import { TrackingButton } from "../../TrackingButton.component"

const availableSpeeds = [0.5, 1, 1.5, 2]

type ISpeedControlsProps = {
	currentSpeed: number
	onSpeedChange: (newSpeed: number) => void
}

export function SpeedControls({ currentSpeed, onSpeedChange }: ISpeedControlsProps) {
	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<MenuButton className="flex items-center justify-center rounded-full text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 w-8">
					<span>{currentSpeed}x</span>
				</MenuButton>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems className="absolute right-0 bottom-6 mb-2 w-30 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
					<div className="py-1">
						{availableSpeeds.map((speed) => (
							<MenuItem key={speed}>
								<TrackingButton
									eventName="Call video interaction"
									eventProperties={{ type: "playbackSpeed", speed }}
									onClick={() => onSpeedChange(speed)}
									className={clsx(
										currentSpeed === speed ? "bg-gray-100 text-gray-900" : "text-gray-700",
										"block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer w-full",
									)}
								>
									<span className="font-medium">{speed}x</span>
								</TrackingButton>
							</MenuItem>
						))}
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	)
}
