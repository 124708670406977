import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

import { HELP_CENTER_URLS } from "../../../../../config"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Card } from "../../../../components/design-system/Card.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { usePreferencesSettings } from "./usePreferencesSettings"

const AVAILABLE_LANGUAGES = ["en", "fr", "es"] as const
const languageTranslationKeyByLanguage: Record<(typeof AVAILABLE_LANGUAGES)[number], TranslationKey> = {
	en: "English",
	fr: "French",
	es: "Spanish",
}

export function PreferencesSettings() {
	const { t } = useLanguage()
	const { language, formStatus, handleLanguageChange, handleSubmit } = usePreferencesSettings()

	return (
		<Card title={t("Workspace preferences")} className="col-span-2 px-6 py-4">
			<form onSubmit={handleSubmit} className="mt-4">
				<dl className="px-6 py-4 space-y-6 divide-y divide-gray-100 text-sm leading-6">
					<div className="pt-6 sm:flex">
						<dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex items-center">
							{t("Language")}{" "}
							<TrackingLink
								to={HELP_CENTER_URLS.Settings.Language[language]}
								eventName="Settings: open language help center"
								target="_blank"
							>
								<QuestionMarkCircleIcon className="ml-2 h-5 w-5 text-gray-400" />
							</TrackingLink>
						</dt>
						<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto items-center">
							<select
								id="language"
								name="language"
								defaultValue={language}
								className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
								onChange={(event) => handleLanguageChange(event.target.value)}
							>
								{AVAILABLE_LANGUAGES.map((lang) => (
									<option key={lang} value={lang}>
										{t(languageTranslationKeyByLanguage[lang])}
									</option>
								))}
							</select>
						</dd>
					</div>
				</dl>
				<div className="mt-8 flex justify-end">
					<TrackingButton
						eventName="Settings.Workspace.Preferences form submitted"
						eventProperties={{ provider: "salesforce", credentialType: "password" }}
						type="submit"
						className={clsx(
							"flex justify-center rounded-full bg-navy-500 px-5 min-w-[124px] py-1.5 text-sm font-semibold leading-6 text-white shadow-sm",
							"bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out",
							"disabled:hover:bg-none disabled:cursor-not-allowed  disabled:text-gray-500 disabled:bg-gray-100 disabled:ring-gray-200",
						)}
						disabled={formStatus.status === "submitting"}
					>
						<span className="flex gap-2 items-center">
							{formStatus.status === "submitting" && <SpinnerIcon className="h-5 w-5 mx-auto" />}
							{formStatus.status === "submitting" ? t("Saving preferences…") : t("Save preferences")}
						</span>
					</TrackingButton>
				</div>
				{formStatus.status === "error" && (
					<div className="mt-4">
						<Alert
							title={t("An error occurred")}
							description={formStatus.error.message}
							variant="error"
							className="mt-4"
						/>
					</div>
				)}
				{formStatus.status === "success" && (
					<div className="mt-4">
						<Alert
							title={t("Your preferences were saved!")}
							description={undefined}
							variant="success"
							className="mt-4"
						/>
					</div>
				)}
			</form>
		</Card>
	)
}
