import type { ICalendarEventUpdateBotQuery } from "../../../../../core/application/gateways/calendar-events.gateway"
import { type CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity"
import { useCalendarContext } from "../../../../contexts/calendar.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { CalendarEventCard } from "./CalendarEventCard.component"

export type EventListProps = {
	events: CalendarEvent[]
}

export function EventList({ events }: EventListProps) {
	const { fetchEvents } = useCalendarContext()

	const { calendarEventsGateway } = useDependencies()

	const handleCalendarEventAction = async (
		calendarEventId: string,
		eventAction: ICalendarEventUpdateBotQuery["action"],
	): Promise<void> => {
		await calendarEventsGateway.updateBot(calendarEventId, { action: eventAction })
		await fetchEvents("did-update-bot")
	}

	return (
		<ul role="list" className="divide-y divide-gray-200 border-gray-200">
			{events.map((event) => (
				<CalendarEventCard event={event} handleCalendarEventAction={handleCalendarEventAction} />
			))}
		</ul>
	)
}
